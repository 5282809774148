<template>
    <div v-if="form && locations.length > 1">
        <multi-select-dropdown
            v-model="form.locations"
            :disabled="disabled"
            :label="trans('dashboard.filter.locations')"
            :options="locations"
            :show-searchbar="true"
            :show-select-all-button="false"
            @update:model-value="$emit('change')"
        >
            <template v-slot:label="{ option }">
                <div
                    class="d-flex justify-content-between align-items-center gap-3"
                >
                    <span>{{ option.label }}</span
                    ><small class="text-muted">{{ option.iataCode }}</small>
                </div>
            </template>
        </multi-select-dropdown>
    </div>
    <multi-select-dropdown
        v-if="form && specificLocations.length > 0 && showSpecificLocations"
        v-model="form.specificLocations"
        :disabled="specificLocations.length === 0"
        :label="trans('activities.overview.cell.specific_location')"
        :options="specificLocations"
        :show-searchbar="true"
        :show-select-all-button="false"
        @update:model-value="$emit('change')"
    />
</template>
<script>
import MultiSelectDropdown from '@/Components/MultiSelectDropdown.vue';

export default {
    components: { MultiSelectDropdown },
    emits: ['change'],
    props: {
        form: Object,
        isProcessing: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        showSpecificLocations: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        locations() {
            return (
                this.$page.props.locations?.data.map((loc) => ({
                    label: loc.name,
                    value: loc.iataCode,
                    iataCode: loc.iataCode,
                    searchableContent: `${loc.iataCode}|${loc.icaoCode}|${loc.name}`,
                })) || []
            );
        },
        specificLocations() {
            return (this.$page.props.specificLocations?.data || []).map(
                (loc) => ({
                    label: loc.name,
                    value: loc.name,
                }),
            );
        },
    },
};
</script>
