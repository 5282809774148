<template>
    <app-layout>
        <page-header :title="trans('dashboard.headline')">
            <dashboard-filterbar
                :form="activeFilters"
                :is-processing="activeFilters.processing"
                class="mt-3"
                @change="updateFilters"
            />
        </page-header>
        <row>
            <column>
                <card>
                    <template #header>
                        <div
                            class="d-flex justify-content-between align-items-center"
                        >
                            <updated-at :updated-at="lastUpdatedAt" />
                        </div>
                    </template>
                    <asset-management
                        :active-filters="activeFilters.usages"
                        :is-processing="activeFilters.processing"
                        @filter="handleAssetStatusFilterChange"
                        @selected="handleAssetTypeSelected"
                    />
                </card>
            </column>
        </row>
    </app-layout>
</template>
<script>
import AppLayout from '@/Layouts/AppLayout.vue';
import LocationFilter from '@/Pages/Dashboard/Components/LocationFilter.vue';
import UpdatedAt from '@/Pages/Dashboard/Components/UpdatedAt.vue';
import AssetManagement from '@/Pages/Dashboard/Components/AssetManagement.vue';

import Card from '@/Components/Card.vue';
import PageHeader from '@/Components/PageHeader.vue';
import CollapsableFilterBar from '@/Components/CollapsableFilterBar.vue';
import DashboardFilterbar from '@/Pages/Dashboard/Components/DashboardFilterbar.vue';

export default {
    components: {
        DashboardFilterbar,
        CollapsableFilterBar,
        PageHeader,
        Card,
        AssetManagement,
        UpdatedAt,
        LocationFilter,
        AppLayout,
    },

    data() {
        return {
            isProcessingLocations: false,
            partialReloadTargets: [],
            activeFilters: this.$inertia.form({
                locations: [],
                specificLocations: [],
                usages: ['active'],
            }),
        };
    },

    computed: {
        lastUpdatedAt() {
            return this.$page.props.metrics?.updatedAt;
        },
    },

    mounted() {
        this.readFiltersFromUrl();
    },

    methods: {
        loadData() {
            this.activeFilters.get(this.route('dashboard.index'), {
                preserveState: true,
                preserveScroll: true,
                only: this.partialReloadTargets,
                onFinish: () => {
                    this.isProcessingLocations = false;
                    this.partialReloadTargets = [];
                },
            });
        },
        updateFilters(filters) {
            this.activeFilters = { ...this.activeFilters, ...filters };
            this.loadData();
        },
        handleAssetStatusFilterChange(filter) {
            let idx = this.activeFilters.usages.indexOf(filter);
            if (idx === -1) {
                // Add filter if not present
                this.activeFilters.usages.push(filter);
            } else {
                if (this.activeFilters.usages.length === 1) {
                    // Ensure active filter is present if no filter is set
                    this.activeFilters.usages = ['active'];
                } else {
                    // Remove filter
                    this.activeFilters.usages.splice(idx, 1);
                }
            }

            this.loadData();
        },
        handleAssetTypeSelected(type) {
            this.$inertia.visit(
                this.route('assets.index', {
                    types: [type],
                    locations: this.activeFilters.locations,
                    specificLocations: this.activeFilters.specificLocations,
                }),
            );
        },
        readFiltersFromUrl() {
            let { locations, specificLocations, usages } = this.route().params;
            let allowedUsages = ['active', 'spare', 'decommissioned'];

            usages = (usages || []).filter((usage) =>
                allowedUsages.includes(usage),
            );

            this.activeFilters.locations = locations || [];
            this.activeFilters.specificLocations = specificLocations || [];
            this.activeFilters.usages = usages.length > 0 ? usages : ['active'];
        },
    },
};
</script>
