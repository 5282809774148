<template>
    <div :key="disabled" ref="button" class="position-relative">
        <span
            v-c-tooltip="{
                content: tooltip,
                trigger: disabled && tooltip ? ['hover', 'focus'] : [],
            }"
        >
            <jet-button
                :class="toggleClasses"
                :color="color"
                :disabled="disabled"
                type="button"
                @click.prevent="isOpen = !isOpen"
            >
                <slot name="title">{{ title }}</slot>
            </jet-button>
        </span>
        <div
            v-if="isOpen"
            class="btn-menu position-absolute end-0 mt-1"
            role="menu"
        >
            <slot :close="closeMenu" name="menu" />
        </div>
    </div>
</template>
<script setup>
import JetButton from '@/Jetstream/Button.vue';
import { ref } from 'vue';
import { onClickOutside } from '@vueuse/core';

const props = defineProps({
    title: String,
    color: {
        type: String,
        default: 'primary',
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    toggleClasses: {
        type: [String, Object, Array],
    },
    tooltip: String,
});

const button = ref(null);
const isOpen = ref(false);
const closeMenu = () => (isOpen.value = false);
const toggleMenu = () => (isOpen.value = !isOpen.value);
onClickOutside(button, () => (isOpen.value = false));
</script>
