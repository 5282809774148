<template>
    <div
        :key="id"
        class="d-flex align-items-center justify-content-between border-top p-3"
        role="menuitem"
    >
        <span class="d-flex align-items-center" style="gap: 5px">
            <slot />
        </span>
        <a
            :href="actualRoute"
            class="position-relative d-flex align-items-center justify-content-center"
            @click.prevent="exportItems"
        >
            <spinner v-if="isProcessing" class="position-absolute" />
            <span
                :style="{ visibility: isProcessing ? 'hidden' : 'visible' }"
                >{{ trans('general.action.generate') }}</span
            >
        </a>
    </div>
</template>
<script setup>
import { computed, ref, useId } from 'vue';
import { route } from 'ziggy-js';
import { trans } from '@/mixins/i18n.js';
import { $http } from '@/bootstrap.js';
import { eventBus, events } from '@/eventBus.js';

const id = useId();

const props = defineProps({
    type: String,
    route: String,
    filters: Object,
    nonQueryFilters: Object, // additional filters that are not part of the URL query params eg. asset id
    selection: Object,
});

const emits = defineEmits(['done', 'error']);

const isProcessing = ref(false);

const actualRoute = computed(() => {
    return route(props.route, {
        type: props.type,
        filters: { ...props.filters, ...props.nonQueryFilters },
        selection: props.selection,
    });
});

const exportItems = (event) => {
    if (isProcessing.value) {
        event.preventDefault();
        return;
    }

    isProcessing.value = true;

    $http
        .get(actualRoute.value, {})
        .then((resp) => {
            emits('done');
            eventBus.$emit(events.presentFlashMessage, {
                type: 'success',
                payload: resp.data,
            });
        })
        .catch((error) => {
            emits('error', { id, error: error.response.data || error });
            eventBus.$emit(events.presentFlashMessage, {
                type: 'error',
                payload: {
                    id,
                    error: error.response.data || error,
                },
            });
        })
        .finally(() => {
            isProcessing.value = false;
        });
};
</script>
