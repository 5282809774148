<template>
    <dialog-modal :show="show">
        <template #title>
            {{ trans('qr_code_scanner.modal.header') }}
        </template>
        <qr-code-scanner
            v-if="show"
            :model-value="value"
            @failure="onError"
            @success="onSuccess"
            @update:model-value="handleUpdate"
        />
        <template #footer>
            <div>
                <small v-if="!!qrCodeSuccess" class="text-success">
                    {{ qrCodeSuccess }}
                </small>
                <small v-if="!!qrCodeError" class="text-danger">
                    {{ qrCodeError }}
                </small>
            </div>
            <jet-button color="secondary" type="button" @click="closeModal"
                >{{ trans('general.action.cancel') }}
            </jet-button>
        </template>
    </dialog-modal>
</template>
<script>
import DialogModal from '@/Jetstream/DialogModal.vue';
import JetButton from '@/Jetstream/Button.vue';
import QrCodeScanner from '@/Components/QrCodeScanner.vue';

export default {
    props: ['value', 'show'],

    data() {
        return {
            qrCodeSuccess: null,
            qrCodeError: null,
        };
    },

    components: {
        DialogModal,
        JetButton,
        QrCodeScanner,
    },

    mounted() {
        this.$watch('show', (el) => {
            this.qrCodeError = null;
            this.qrCodeSuccess = null;
        });
    },

    methods: {
        closeModal() {
            this.$emit('close');
        },
        onSuccess(msg) {
            this.qrCodeError = null;
            this.qrCodeSuccess = this.trans(
                'qr_code_scanner.modal.success.message',
            );
        },
        onError(err) {
            this.qrCodeSuccess = null;
            this.qrCodeError = this.trans(
                'qr_code_scanner.modal.error.message',
            );
        },
        handleUpdate(data) {
            this.$emit('input', data.uuid || null);

            if (data) {
                setTimeout(() => {
                    this.$emit('close');
                }, 500);
            }
        },
    },
};
</script>
