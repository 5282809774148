<template>
    <app-layout>
        <div>
            <page-header :title="trans('assets.activation.headline')">
                <p class="mb-0">{{ trans('assets.activation.description') }}</p>
                <filter-bar
                    :query-params="activeQueryParams"
                    :search-placeholder="
                        trans('assets.activation.filter.placeholder')
                    "
                    class="mt-3"
                />
            </page-header>
            <single-column-table-view
                v-if="isMobile"
                :fields="fields"
                :items="transformedAssets"
                @row-clicked="showDetails"
            >
                <template #header-row="{ item }">
                    <div class="d-flex justify-content-end w-100">
                        <badge
                            color="primary"
                            style="width: 25px; height: 25px; line-height: 25px"
                            @click.stop="openQrCodeScanner(item)"
                        >
                            <icon
                                name="cil-qr-code"
                                style="color: #fff; shape-rendering: crispEdges"
                            />
                        </badge>
                    </div>
                </template>
            </single-column-table-view>
            <div v-else>
                <data-table
                    :actions="actions"
                    :columns="fields"
                    :items="transformedAssets"
                    :no-items-view="noItemsView"
                    :query-params="activeQueryParams"
                    @row-clicked="showDetails"
                >
                    <template #designation="{ item }">
                        <td>
                            <div>
                                {{ item.designation }}
                            </div>
                            <small class="text-nowrap text-dark"
                                >{{
                                    trans('assets.overview.cell.serial_number')
                                }}: {{ item.serialNumber }}</small
                            >
                        </td>
                    </template>

                    <template #type="{ item }">
                        <td>
                            {{ item.type }}
                        </td>
                    </template>

                    <template #location="{ item }">
                        <td>
                            <span>
                                {{ item.location.iataCode }}
                            </span>
                        </td>
                    </template>

                    <template #specific_location="{ item }">
                        <specific-location-overview-cell
                            :location="item.location"
                        />
                    </template>

                    <template #area="{ item }">
                        <area-location-overview-cell
                            :location="item.location"
                        />
                    </template>

                    <template #manufacturer="{ item }">
                        <td>
                            {{ item.manufacturer }}
                        </td>
                    </template>
                </data-table>
            </div>
        </div>
    </app-layout>
</template>
<script>
import AppLayout from '@/Layouts/AppLayout.vue';
import JetButton from '@/Jetstream/Button.vue';
import Pagination from '@/Components/Pagination.vue';
import PetitecIdScanner, {
    PetiteCIdScannerType,
} from '@/Components/PetitecIdScanner.vue';

import FilterBar from '@/Components/FilterBar.vue';
import IssueBadge from '@/Components/IssueBadge.vue';
import StatusBadge from '@/Components/StatusBadge.vue';
import StatusCheckDate from '@/Components/StatusCheckDate.vue';
import BatchJobs from '@/Pages/Assets/BatchJobs/BatchJobs.vue';
import OnboardedAssetIssue from '@/Pages/Assets/OnboardedAssetIssue.vue';
import WorkOrderBadge from '@/Components/WorkorderBadge.vue';
import DataTable from '@/Components/DataTable.vue';
import { eventBus, events } from '@/eventBus.js';
import PageTitle from '@/Components/PageTitle.vue';
import PageHeader from '@/Components/PageHeader.vue';
import LocationFilter from '@/Pages/Dashboard/Components/LocationFilter.vue';
import CollapsableFilterBar from '@/Components/CollapsableFilterBar.vue';
import SingleColumnTableView from '@/Pages/SingleColumnTableView.vue';
import Badge from '@/Components/Badge.vue';
import useQueryParams from '@/Utils/UseQueryParams';
import { isMobile } from '@/Utils/UseMedia';
import SpecificLocationOverviewCell from '@/Components/SpecificLocationOverviewCell.vue';
import AreaLocationOverviewCell from '@/Components/AreaLocationOverviewCell.vue';
import { when } from '@/Utils/when';

export default {
    components: {
        AreaLocationOverviewCell,
        SpecificLocationOverviewCell,
        Badge,
        SingleColumnTableView,
        CollapsableFilterBar,
        LocationFilter,
        PageHeader,
        PageTitle,
        DataTable,
        OnboardedAssetIssue,
        WorkOrderBadge,
        BatchJobs,
        AppLayout,
        JetButton,
        Pagination,
        PetitecIdScanner,
        IssueBadge,
        FilterBar,
        StatusBadge,
        StatusCheckDate,
    },

    setup() {
        const activeQueryParams = useQueryParams({
            paramsWithDefaults: {
                search: null,
                usages: [],
                operabilities: [],
                types: [],
                locations: [],
                specificLocations: [],
                sort: { column: 'date', asc: false },
            },
            route: route('assets.activation'),
            options: {
                only: ['assets'],
            },
        });

        return {
            activeQueryParams,
            isMobile,
        };
    },

    data() {
        return {
            noItemsView: {
                noItems: this.trans('assets.overview.results.empty'),
            },
            isSearching: false,
            isPetitecIdScannerVisible: false,
            selectedAsset: null,
            actions: [
                {
                    permissions: ['asset.update'],
                    label: this.trans('assets.overview.scanner.title'),
                    type: 'primary',
                    click: this.openQrCodeScanner,
                },
            ],
        };
    },

    computed: {
        fields() {
            return [
                {
                    key: 'type',
                    label: this.trans('assets.overview.cell.type'),
                    _style: 'width: 90px',
                },
                {
                    key: 'designation',
                    label: this.trans('assets.overview.cell.designation'),
                    _style: 'width: 20%',
                },
                when(this.$page.props.locations?.data?.length > 1, {
                    key: 'location',
                    label: this.trans('assets.overview.cell.airport'),
                    _style: 'width: 90px',
                }),
                {
                    key: 'specific_location',
                    label: this.trans('assets.overview.cell.location'),
                    _style: 'width: 15%;white-space: nowrap;',
                },
                when(this.assets?.meta.hasAreaLocation, {
                    key: 'area',
                    label: this.trans('locations.area.label'),
                    _style: 'width: 15%;white-space: nowrap;',
                }),
                {
                    key: 'manufacturer',
                    label: this.trans('assets.overview.cell.manufacturer'),
                },
            ].filter(Boolean);
        },
        assets() {
            return this.$page.props.assets;
        },
        hasCameraSupport() {
            return !!navigator.mediaDevices;
        },
        transformedAssets() {
            if (!this.assets) {
                return null;
            }
            const notAvailable = this.trans('general.not_available');
            const { meta, links, data } = this.assets;

            return {
                meta,
                links,
                data: data.map((asset) => ({
                    id: asset.id,
                    type: asset.type.value || notAvailable,
                    designation: asset.designation
                        ? asset.designation.label
                        : notAvailable,
                    manufacturer: asset.manufacturer
                        ? asset.manufacturer.name
                        : notAvailable,
                    petitec_identifier: asset.petitec_identifier,
                    serialNumber: asset.serialNumber || notAvailable,
                    location: asset.location,
                })),
            };
        },
    },

    methods: {
        showDetails(asset) {
            this.$inertia.visit(this.route('assets.show', asset.id));
        },
        openQrCodeScanner(item) {
            eventBus.$emit(events.openQrCodeScanner, {
                assetId: item.id,
                type: PetiteCIdScannerType.attachPetiteCId,
            });
        },
        startPetitecScannerIfNecessary() {
            const parts = window.location.hash.split('#assign-petitec-id=');

            if (parts.length === 2) {
                this.openQrCodeScanner({ id: parts[1] });
            }
        },
    },
    mounted() {
        window.addEventListener(
            'hashchange',
            this.startPetitecScannerIfNecessary,
        );
    },
    beforeDestroy() {
        window.removeEventListener(
            'hashchange',
            this.startPetitecScannerIfNecessary,
        );
    },
};
</script>
