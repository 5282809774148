<template>
    <div>
        {{ trans('password.hint.title') }}
        <ul class="m-0 p-0" style="list-style-type: none; line-height: 24px">
            <password-rule-validation-line
                v-for="rule in messages"
                :key="rule"
                :is-valid="isValid(rule)"
                :text="rule"
            />
        </ul>
    </div>
</template>
<script>
import PasswordRuleValidationLine from '@/Pages/Auth/PasswordRuleValidationLine.vue';

export default {
    components: { PasswordRuleValidationLine },
    props: {
        showStatus: {
            type: Boolean,
            default: false,
        },
        errors: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        messages() {
            return Object.values(
                this.$page.props.passwordValidationMessages || [],
            );
        },
        hasErrors() {
            return this.messages.some((key) => this.errors.includes(key));
        },

        passwordValidationMessages() {
            return this.$page.props.passwordValidationMessages || [];
        },
    },
    methods: {
        isValid(rule) {
            if (!this.showStatus) {
                return undefined;
            }

            return !this.errors?.includes(rule);
        },
        getLabelForMessage(key) {
            return this.$page.props.passwordValidationMessages[key];
        },
    },
};
</script>
