<template>
    <collapsable-filter-bar
        :active="queryParams.form.isDirty"
        :is-processing="queryParams.form.processing"
        @clear="clear"
    >
        <template #visible-filter="{ isOpen }">
            <search-filter
                :is-processing="queryParams.form.processing"
                :model-value="queryParams.form.search"
                :placeholder="trans('test_records.overview.filter.placeholder')"
                :show-spinner="!isOpen"
                @update:modelValue="updateValue('search', $event)"
            />
        </template>

        <location-filter :form="queryParams.form" @change="updateValue" />

        <date-range-filter
            :label="trans('activities.overview.cell.date')"
            :model-value="{
                start: queryParams.form.start,
                end: queryParams.form.end,
            }"
            @update:modelValue="updateValue('date', $event)"
        />
        <multi-select-dropdown
            v-if="types.length > 1"
            :label="trans('assets.overview.filter.type')"
            :model-value="queryParams.form.types"
            :options="types"
            :show-select-all-button="false"
            @update:modelValue="updateValue('types', $event)"
        >
            <template v-slot:label="{ option }">
                <div
                    class="d-flex justify-content-between align-items-center"
                    style="gap: 1rem"
                >
                    <span>{{ option.label }}</span
                    ><small class="text-muted">{{ option.value }}</small>
                </div>
            </template>
        </multi-select-dropdown>

        <multi-select-dropdown
            v-model="queryParams.form.qr"
            :label="trans('test_records.overview.cell.qr_code_scanned')"
            :model-value="queryParams.form.qr"
            :options="[
                {
                    label: trans(
                        'test_records.overview.tooltip.qr_code_scanned',
                    ),
                    value: 'true',
                },
                {
                    label: trans(
                        'test_records.overview.tooltip.qr_code_not_scanned',
                    ),
                    value: 'false',
                },
            ]"
            :show-select-all-button="false"
            @update:modelValue="updateValue('qr', $event)"
        >
            <template v-slot:label="{ option, show }">
                <div
                    class="d-flex justify-content-between align-items-center gap-3"
                >
                    <span>{{ option.label }}</span>
                    <icon
                        v-if="option.value === 'true'"
                        class="text-gray"
                        name="cil-check-alt"
                        size="lg"
                    />
                    <icon v-else name="cil-x" size="lg" />
                </div>
            </template>
        </multi-select-dropdown>

        <multi-select-dropdown
            v-model="queryParams.form.results"
            :label="trans('test_records.overview.cell.result')"
            :options="results"
            :show-select-all-button="false"
            @update:model-value="updateValue('results', $event)"
        >
            <template v-slot:label="{ option }">
                <div
                    class="d-flex justify-content-between align-items-center gap-3"
                >
                    <span>{{ option.label }}</span>
                    <icon
                        v-if="option.value === 'success'"
                        class="text-gray"
                        name="cil-check-alt"
                        size="lg"
                    />
                    <icon
                        v-else
                        class="text-danger"
                        name="cil-warning"
                        size="lg"
                    />
                </div>
            </template>
        </multi-select-dropdown>
        <user-filter
            v-model="queryParams.form.users"
            @update:model-value="updateValue('users', $event)"
        />
    </collapsable-filter-bar>
</template>
<script>
import MultiSelectDropdown from '@/Components/MultiSelectDropdown.vue';
import CollapsableFilterBar from '@/Components/CollapsableFilterBar.vue';
import LocationFilter from '@/Pages/Dashboard/Components/LocationFilter.vue';
import TruncatedLabel from '@/Pages/WorkOrders/TruncatedLabel.vue';
import Icon from '@/Components/Icon.vue';
import DateRangeFilter from '@/Components/DateRangeFilter.vue';
import SearchFilter from '@/Pages/WorkOrders/SearchFilter.vue';
import UserFilter from '@/Components/UserFilter.vue';

export default {
    components: {
        UserFilter,
        SearchFilter,
        Icon,
        TruncatedLabel,
        DateRangeFilter,
        LocationFilter,
        CollapsableFilterBar,
        MultiSelectDropdown,
    },
    props: {
        queryParams: Object,
        class: String,
    },
    emits: ['change'],
    computed: {
        results() {
            return this.$page.props.results?.data || [];
        },
        types() {
            return this.$page.props.types?.data || [];
        },
    },
    created() {
        window.addEventListener('keyup', this.keyboardListener);
    },

    destroyed() {
        window.removeEventListener('keyup', this.keyboardListener);
    },
    methods: {
        updateValue(key, value) {
            if (key === 'date') {
                this.queryParams.form.start = value.start;
                this.queryParams.form.end = value.end;
            } else if (Object.hasOwn(this.queryParams.form, key)) {
                this.queryParams.form[key] = value;
            }

            this.queryParams.change();
            this.$emit('change', key);
        },
        clear() {
            this.queryParams.clear();
            this.$emit('change');
        },
    },
};
</script>
