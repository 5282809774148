<template>
    <div :class="{ visible }" class="flash d-sm-inline-block">
        <c-alert
            :color="color"
            class="d-flex d-sm-inline-flex align-items-start align-items-sm-center justify-content-between mb-0"
            style="gap: 20px"
        >
            <dynamic-content :content="content" />
            <a
                v-if="isSticky"
                class="flash-close btn btn-icon text-success-emphasis"
                href="#"
                @click.prevent="visible = false"
            >
                <icon name="cil-x-circle" />
            </a>
        </c-alert>
    </div>
</template>
<script>
import { compile, h } from 'vue';
import { eventBus, events } from '@/eventBus.js';

// Component to render dynamic templates
const DynamicContent = {
    props: ['content'],
    render: function () {
        if (!this.content) return null;
        return h(compile(this.content));
    },
};

export default {
    components: {
        DynamicContent,
    },
    data() {
        return {
            visible: false,
            timeout: null,
            content: null,
            isSticky: false,
            type: 'success',
        };
    },
    computed: {
        color() {
            return this.type === 'success' ? 'success' : 'danger';
        },
        flash() {
            return this.$page.props.flash;
        },
    },
    watch: {
        flash: {
            handler: function (data) {
                this.showFlashMessage(data);
            },
            immediate: true,
            deep: true,
        },
    },
    mounted() {
        eventBus.$on(events.presentFlashMessage, (data) => {
            this.showFlashMessage({
                message: data.payload.message,
                error:
                    data.payload.status === 'error'
                        ? data.payload.message
                        : data.payload.error,
                isSticky: data.payload.isSticky,
            });
        });
    },
    methods: {
        showFlashMessage(data) {
            this.type = data.error ? 'danger' : 'success';
            const message = data.message || data.error;
            this.content = `<div>${message}</div>`;
            this.isSticky = data.isSticky || false;
            this.visible = message?.length > 0;

            clearTimeout(this.timeout);

            if (!this.isSticky) {
                this.timeout = setTimeout(() => {
                    this.visible = false;
                }, 5000);
            }
        },
    },
};
</script>
