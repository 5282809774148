<template>
    <div
        aria-label="Loading..."
        aria-live="polite"
        class="skeleton-loader"
        role="alert"
    >
        <div class="skeleton-loader__bone loading-ripple" />
    </div>
</template>
<script lang="ts" setup></script>
