<template>
    <div class="d-flex overflow-hidden">
        <div
            class="d-flex align-items-start justify-content-center flex-shrink-0"
            style="width: 40px"
        >
            <div v-if="failed">
                <exclamation-mark style="line-height: 22px" />
            </div>
            <div v-else>
                <icon name="cil-check-circle" />
            </div>
        </div>
        <div class="overflow-hidden">
            <div>
                <b
                    :class="{ 'text-danger': failed }"
                    class="text-truncate d-block"
                    >{{ title }}</b
                >
            </div>
            <div class="text-small text-muted text-wrap">{{ description }}</div>
        </div>
    </div>
</template>
<script>
import ExclamationMark from '../../../../icons/ExclamationMark.vue';

export default {
    name: 'default',
    components: { ExclamationMark },
    props: {
        notification: {},
        icon: {},
    },
    computed: {
        failed() {
            return this.notification.payload.status === 'failed';
        },
        title() {
            const { type, contentType, status } = this.notification.payload;
            return this.trans(
                `notification.export.${contentType}.${type}.${status}.title`,
            );
        },
        description() {
            const { status } = this.notification.payload;
            return this.trans(`notification.export.${status}.description`);
        },
    },
};
</script>
