<template>
    <div>
        <div v-html="description" />
        <div v-if="reason">
            <small
                >{{ trans('activity.asset.operability.changed.reason') }}
                <span class="text-muted">{{ reason }}</span></small
            >
        </div>
        <div v-if="notes">
            <small
                >{{
                    trans(
                        'activity.asset.operability.changed.additional_information',
                    )
                }}
                <span class="text-muted">{{ notes }}</span></small
            >
        </div>
    </div>
</template>
<script>
export default {
    name: 'AssetOperabilityChanged',
    props: {
        item: {},
    },
    computed: {
        description() {
            return this.item.description;
        },
        notes() {
            if (this.item.properties.new?.additional_information) {
                return this.item.properties.new.additional_information;
            }

            return null;
        },
        reason() {
            if (this.item.properties.new?.reason) {
                return this.trans(
                    `activity.asset.operability.changed.reason_for_change.${this.item.properties.new.reason.value}`,
                );
            }
            return null;
        },
    },
};
</script>
