<template>
    <dropdown :caret="false" :visible="show" as="div" class="dropdown mb-3">
        <template #toggler>
            <jet-input
                ref="input"
                v-model="form.search"
                :horizontal="false"
                :inline="true"
                :invalidFeedback="error"
                :label="label"
                :placeholder="placeholder"
                :required="required"
                @blur="closeModel"
                @focus="handleFocus"
                @keyup="search"
                @keyup.esc="blurInput"
                @click.native="handleClick"
            >
                <template #append>
                    <spinner v-if="form.processing" />
                    <search v-else />
                </template>
            </jet-input>
        </template>
        <div
            v-if="assets.data?.length > 0"
            class="overflow-auto"
            style="max-height: 153px"
        >
            <dropdown-item
                v-for="asset in assets.data"
                :key="asset.id"
                class="py-1"
                @click="select(asset)"
                >{{ getLabel(asset) }}
            </dropdown-item>
        </div>
        <dropdown-item
            v-else
            :disabled="true"
            class="py-1"
            v-text="trans('assets.overview.results.empty')"
        />

        <div
            v-if="assets.data?.length > 0"
            :class="{ 'add-shadow': assets.data?.length > 5 }"
            class="d-flex justify-content-end px-3"
        >
            <small class="text-muted"
                >{{ assets.meta?.total }}/{{ assets.meta?.all }}</small
            >
        </div>
    </dropdown>
</template>
<script>
import JetInput from '@/Jetstream/Input.vue';
import Search from '@/../icons/Search.vue';
import { debounce } from 'lodash';
import { $http } from '@/bootstrap.js';
import DropdownItem from '@/Components/DropdownItem.vue';
import Dropdown from '@/Components/Dropdown.vue';

export default {
    name: 'asset-selection-field',
    components: { Dropdown, DropdownItem, Search, JetInput },
    emits: ['selected'],
    props: {
        label: String,
        error: String,
        horizontal: Boolean,
        placeholder: String,
        required: {
            type: Boolean,
            default: false,
        },
        value: String,
        disabled: {
            type: Boolean,
            default: false,
        },
        searchUrl: String,
    },
    data() {
        return {
            show: false,
            assets: {},
            form: this.$inertia.form({ search: null }),
            abortController: null,
        };
    },
    methods: {
        search: debounce(function (event) {
            this.abortController?.abort();
            this.form.processing = false;

            // Handle escape
            if (event.keyCode === 27) {
                return;
            }

            // Escape keypress
            this.abortController = new AbortController();

            this.form.processing = true;

            $http
                .get(this.searchUrl, {
                    signal: this.abortController.signal,
                    params: { search: this.form.search },
                    headers: {
                        'X-Inertia-Partial-Data': 'searchResults',
                        'X-Inertia-Partial-Component':
                            'WorkOrders/OverviewPage',
                        'X-Inertia': true,
                        'X-Inertia-Version': this.$page.version,
                    },
                })
                .then((resp) => {
                    const { searchResults } = resp.data.props;

                    if (searchResults.meta.current_page === 1) {
                        this.assets = Object.assign(
                            {},
                            this.assets,
                            searchResults,
                        );
                    } else {
                        this.assets.links = searchResults.links;
                        this.assets.meta = searchResults.meta;
                        this.assets.data.push(...searchResults.data);
                    }
                })
                .catch((e) => {})
                .finally(() => {
                    this.form.processing = false;
                    this.show = true;
                });
        }, 300),
        getLabel(asset) {
            return [
                asset.type.value,
                asset.serialNumber,
                asset.location.airport,
                asset.location.name,
                asset.manufacturer.name,
            ]
                .filter((el) => !!el)
                .join('/');
        },
        select(asset) {
            this.form.search = this.getLabel(asset);
            this.show = false;
            this.$emit('selected', asset);
        },
        handleClick(event) {
            event.preventDefault();
            event.stopImmediatePropagation();
        },
        handleFocus(event) {
            this.show = true;
            this.form.reset();
            this.form.clearErrors();
        },
        closeModel: debounce(function () {
            this.show = false;
        }, 150),
        blurInput(event) {
            this.$refs.input.$el.getElementsByTagName('input')[0].blur();
        },
    },
};
</script>
<style scoped>
:deep(.dropdown-menu) {
    width: calc(100% - 34px);
}

.dropdown.btn-group {
    display: block;
}
</style>
