<template>
    <div v-html="description" />
</template>
<script setup>
import { trans } from '@/mixins/i18n.js';
import { computed } from 'vue';

const props = defineProps({
    item: Object,
});

const description = computed(() => {
    const type = props.item.properties.exportType.toUpperCase();
    return trans(
        `activities.events.${props.item.properties.exportContentType}.export.created`,
        { file_type: type },
    );
});
</script>
