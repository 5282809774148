import { DateTime } from 'luxon';

export default {
    methods: {
        formatAsApiDate(date) {
            if (date instanceof Date) {
                return DateTime.fromJSDate(date).toFormat('yyyy-MM-dd');
            }

            if (date instanceof DateTime) {
                return date.toFormat('yyyy-MM-dd');
            }

            return null;
        },
        parseApiDate(dateString) {
            if (!dateString) {
                return '';
            }

            return DateTime.fromISO(dateString);
        },
    },
};
