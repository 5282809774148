<template>
    <div>
        <dl>
            <div class="row">
                <dt class="col-3 font-weight-normal text-truncate">
                    {{ trans('workorder.details.workorder.type.label') }}
                </dt>
                <dd class="col-9 text-muted">
                    <span>{{ workOrder?.type?.label ?? 'None' }}</span>
                </dd>
            </div>
            <div class="row">
                <dt class="col-3 font-weight-normal text-truncate">
                    {{ trans('workorder.details.workorder.status.label') }}
                </dt>
                <dd class="col-9 text-muted">
                    <jet-select
                        v-model="form.status"
                        :disabled="statuses.length < 2"
                        :horizontal="false"
                        :inline="true"
                        :invalid-feedback="form.errors.status"
                        :options="statuses"
                        :readonly="!isEditable || statuses.length < 2"
                        :required="true"
                        class="mb-0 h-auto"
                    >
                        <template #toggler-content="item">
                            <div
                                v-if="item.disabled"
                                style="
                                    position: absolute;
                                    left: 0;
                                    right: 0;
                                    top: 0;
                                    bottom: 0;
                                    z-index: 1;
                                "
                                @click.stop=""
                            />
                            <div v-html="item.props?.label" />
                        </template>
                        <template #readonly="item">
                            {{
                                workOrder?.status?.label ||
                                trans('general.none')
                            }}
                        </template>
                    </jet-select>
                </dd>
            </div>
            <div class="row">
                <dt class="col-3 font-weight-normal text-truncate">
                    {{ trans('workorder.details.workorder.priority.label') }}
                </dt>
                <dd class="col-9 text-muted">
                    <jet-select
                        v-model="form.priority"
                        :disabled="priorities.length === 0"
                        :horizontal="false"
                        :inline="true"
                        :invalid-feedback="form.errors.priority"
                        :options="priorities"
                        :readonly="!isEditable || priorities.length === 0"
                        :required="true"
                        class="mb-0 h-auto"
                    >
                        <template #toggler-content="item">
                            <div
                                v-if="item.disabled"
                                style="
                                    position: absolute;
                                    left: 0;
                                    right: 0;
                                    top: 0;
                                    bottom: 0;
                                    z-index: 1;
                                "
                                @click.stop=""
                            />
                            <work-order-priority
                                :priority="{
                                    value: item.props?.value,
                                    label: item.props.label,
                                }"
                            />
                        </template>
                        <template #readonly>
                            <work-order-priority
                                v-if="workOrder"
                                :priority="{
                                    value: workOrder?.priority.value,
                                    label: workOrder?.priority.label,
                                }"
                            />
                            <span v-else>{{ trans('general.none') }}</span>
                        </template>
                    </jet-select>
                </dd>
            </div>
            <div class="row">
                <dt class="col-3 font-weight-normal text-truncate">
                    {{ trans('workorder.details.workorder.assignee.label') }}
                </dt>
                <dd class="col-9 text-muted">
                    <jet-select
                        v-model="form.assignee"
                        :disabled="assignees.length === 0"
                        :enable-search="true"
                        :horizontal="false"
                        :inline="true"
                        :invalid-feedback="form.errors.assignee"
                        :options="assignees"
                        :readonly="!isEditable || assignees.length === 0"
                        :resettable="false"
                        class="mb-0 h-auto"
                    >
                        <template #toggler-content="item">
                            <div
                                v-if="item.disabled"
                                style="
                                    position: absolute;
                                    left: 0;
                                    right: 0;
                                    top: 0;
                                    bottom: 0;
                                    z-index: 1;
                                "
                                @click.stop=""
                            />
                            <div
                                v-html="item.props?.label ?? item.placeholder"
                            />
                        </template>
                        <template #readonly>
                            {{
                                workOrder?.assignee?.name ||
                                trans('general.none')
                            }}
                        </template>
                    </jet-select>
                </dd>
            </div>
            <div class="row">
                <dt class="col-3 font-weight-normal text-truncate">
                    {{ trans('workorder.details.workorder.reporter.label') }}
                </dt>
                <dd class="col-9 text-muted">
                    <span v-if="workOrder?.reportedBy">{{
                        workOrder?.reportedBy.name
                    }}</span>
                    <span v-else>{{ trans('general.none') }}</span>
                </dd>
            </div>
            <div class="row">
                <dt class="col-3 font-weight-normal text-truncate">
                    {{ trans('workorder.details.workorder.reported_at.label') }}
                </dt>
                <dd class="col-9 text-muted">
                    <span v-if="workOrder?.reportedAt">{{
                        $filters.dateTimeMedium(
                            workOrder?.reportedAt,
                            currentLocale,
                        )
                    }}</span>
                    <span v-else>{{ trans('general.none') }}</span>
                </dd>
            </div>
            <div class="row">
                <dt class="col-3 font-weight-normal text-truncate">
                    {{ trans('workorder.details.workorder.start_date.label') }}
                </dt>
                <dd class="col-9 text-muted">
                    <jet-inline-datepicker
                        v-model="form.startDate"
                        :deletable="true"
                        :horizontal="false"
                        :invalid-feedback="form?.errors?.startDate"
                        :placeholder="trans('general.none')"
                        :readonly="!isEditable"
                        class="mb-0 d-flex"
                    />
                </dd>
            </div>
            <div class="row">
                <dt
                    :class="{ required: isDueDateRequired && isEditable }"
                    class="col-3 font-weight-normal text-truncate"
                >
                    {{ trans('workorder.details.workorder.due_date.label') }}
                </dt>
                <dd class="col-9 text-muted">
                    <jet-inline-datepicker
                        v-model="form.dueDate"
                        :deletable="true"
                        :horizontal="false"
                        :invalid-feedback="form?.errors?.dueDate"
                        :min-date="form.startDate"
                        :placeholder="trans('general.none')"
                        :readonly="!isEditable"
                        :required="isDueDateRequired"
                        class="mb-0 d-flex"
                    />
                </dd>
            </div>
            <div v-if="showResolvedDate || showClosedDate" class="row">
                <dt class="col-3 font-weight-normal text-truncate">
                    <span v-if="showResolvedDate">{{
                        trans('workorder.details.workorder.resolved_at.label')
                    }}</span>
                    <span v-if="showClosedDate">{{
                        trans('workorder.details.workorder.closed_at.label')
                    }}</span>
                </dt>
                <dd class="col-9 text-muted">
                    {{
                        $filters.dateTimeMedium(
                            workOrder.statusChangedAt,
                            currentLocale,
                        )
                    }}
                </dd>
            </div>
        </dl>
    </div>
</template>
<script>
import JetInlineDatepicker from '@/Jetstream/InlineDatepicker.vue';
import JetSelect from '@/Jetstream/Select.vue';
import WorkOrderPriority from '@/Pages/WorkOrders/Modals/WorkOrderPriority.vue';

export default {
    name: 'work-order-info',
    components: {
        WorkOrderPriority,
        JetSelect,
        JetInlineDatepicker,
    },
    props: {
        form: {
            type: [Object, null],
        },
        workOrder: {
            type: [Object, null],
        },
        statuses: {
            type: Array,
            default: () => [],
        },
        priorities: {
            type: Array,
            default: () => [],
        },
        assignees: {
            type: Array,
            default: () => [],
        },
        isEditable: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        showResolvedDate() {
            return this.workOrder?.status.value === 'resolved';
        },
        showClosedDate() {
            return this.workOrder?.status.value === 'closed';
        },
        isDueDateRequired() {
            return this.workOrder?.type.value === 'preventative_maintenance';
        },
    },
};
</script>
