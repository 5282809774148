<template>
    <div>
        <data-table
            :columns="fields"
            :items="inoperableAssets"
            :no-items-view="noItemsView"
            :query-params="queryParams"
            class="mt-3"
            @row-clicked="showAsset"
        >
            <template #specificLocation-header>
                <div>Specific&nbsp;Location</div>
            </template>

            <template #location="{ item }">
                <td>
                    {{ `${item.location.name} (${item.location.iataCode})` }}
                </td>
            </template>

            <template #specificLocation="{ item }">
                <specific-location-overview-cell :location="item.location" />
            </template>

            <template #area="{ item }">
                <area-location-overview-cell :location="item.location" />
            </template>

            <template #type="{ item }">
                <td>{{ item.type }}</td>
            </template>

            <template #designation="{ item }">
                <td class="align-top">
                    <div>{{ item.designation }}</div>
                    <div class="text-muted">
                        <small class="text-nowrap text-dark"
                            >{{ trans('assets.overview.cell.serial_number') }}:
                            {{ item.serialNumber }}</small
                        >
                    </div>
                </td>
            </template>

            <template #manufacturer="{ item }">
                <td>{{ item.manufacturer }}</td>
            </template>

            <template #status="{ item }">
                <daily-report-status-cell :item="item" />
            </template>
        </data-table>
    </div>
</template>
<script>
import Pagination from '@/Components/Pagination.vue';
import DailyReportStatusCell from '@/Pages/ReportingLight/DailyReportStatusCell.vue';
import SortIcon from '@/Pages/Assets/SortIcon.vue';
import DataTable from '@/Components/DataTable.vue';
import SpecificLocationOverviewCell from '@/Components/SpecificLocationOverviewCell.vue';
import AreaLocationOverviewCell from '@/Components/AreaLocationOverviewCell.vue';
import { when } from '@/Utils/when';
import { trans } from '@/mixins/i18n.js';

export default {
    name: 'daily-light-report-table',
    components: {
        AreaLocationOverviewCell,
        SpecificLocationOverviewCell,
        DataTable,
        SortIcon,
        DailyReportStatusCell,
        Pagination,
    },
    props: {
        queryParams: Object,
    },
    data() {
        return {
            visibleWorkOrderDetails: [],
            noItemsView: {
                noItems: trans('inoperable_assets.overview.empty'),
            },
        };
    },
    computed: {
        fields() {
            return [
                when(this.locations?.length > 1, {
                    key: 'location',
                    label: trans('assets.overview.cell.airport'),
                }),
                {
                    key: 'specificLocation',
                    label: trans('locations.specific_location.label'),
                },
                when(
                    this.$page.props.report.inoperableAssets.meta
                        .hasAreaLocation,
                    {
                        key: 'area',
                        label: trans('locations.area.label'),
                    },
                ),
                {
                    key: 'type',
                    label: trans('models.field.type.label'),
                },
                {
                    key: 'designation',
                    label: trans('models.field.designation.label'),
                },
                {
                    key: 'manufacturer',
                    label: trans('models.field.manufacturer.label'),
                },
                {
                    key: 'status',
                    label: trans('assets.overview.cell.status'),
                },
            ].filter(Boolean);
        },
        inoperableAssets() {
            const notAvailable = this.trans('general.not_available');
            const { meta, links, data } =
                this.$page.props.report.inoperableAssets;

            return {
                meta,
                links,
                data: data.map((report) => ({
                    id: report.id,
                    location: report.location,
                    specificLocation: report.location.specificLocationName,
                    type: report.type,
                    designation: report.designation || notAvailable,
                    serialNumber: report.serialNumber || notAvailable,
                    manufacturer: report.manufacturer?.name || notAvailable,
                    status: {
                        usageCategory: report.status.usageCategory?.value,
                        operability: report.status.operability?.value,
                        inoperableSince: report.status.inoperableSince,
                        operabilityChangeReason:
                            report.status.operabilityChangeReason?.label,
                        additionalInformation:
                            report.status.additionalInformation,
                        downtimeInSeconds: report.status.downtimeInSeconds,
                        operabilityLastCheckedAt:
                            report.status.operabilityLastCheckedAt,
                        workOrders: report.status.workOrders,
                    },
                })),
            };
        },
        locations() {
            return this.$page.props.locations?.data || [];
        },
    },
    methods: {
        workOrderDetailsVisible(workOrder) {
            return this.visibleWorkOrderDetails.includes(workOrder.id);
        },
        toggleWorkOrderDetails(workOrder) {
            let idx = this.visibleWorkOrderDetails.indexOf(workOrder.id);
            if (idx > -1) {
                this.visibleWorkOrderDetails.splice(idx, 1);
            } else {
                this.visibleWorkOrderDetails.push(workOrder.id);
            }
        },
        showAsset(item) {
            this.$inertia.visit(this.route('assets.show', item.id));
        },
    },
};
</script>
