<template>
    <div style="padding: 5px 0">
        <header class="d-flex justify-content-between align-items-center mb-2">
            <div>
                {{
                    trans(
                        'dashboard.asset_management.asset_type_distribution.title',
                    )
                }}
            </div>
            <info-icon
                :tooltip="
                    trans(
                        'dashboard.asset_management.asset_type_distribution.tooltip',
                    )
                "
            />
        </header>
        <div class="d-flex align-items-center overflow-auto pb-3">
            <div v-if="assetsByType" class="d-flex gap-1">
                <div
                    v-for="type in assetsByType"
                    :key="type.type?.value"
                    class="db-card-sm text-center py-2 px-2 clickable"
                    style="
                        min-width: 75px;
                        min-height: 84px;
                        flex-grow: 0;
                        flex-shrink: 0;
                        cursor: pointer;
                    "
                    tabindex="0"
                    @click.stop="$emit('selected', type.type?.value)"
                >
                    <div style="font-size: 30px; white-space: nowrap">
                        {{ type.count }}
                    </div>
                    <div>
                        {{ type.type?.value || trans('general.not_available') }}
                    </div>
                </div>
            </div>
            <div
                v-else
                class="db-card-sm loading-ripple"
                style="width: 75px; height: 84px"
            ></div>
        </div>
    </div>
</template>
<script>
import InfoIcon from '@/Components/InfoIcon.vue';
import { trans } from '../../../mixins/i18n.js';

export default {
    name: 'AssetTypeDistribution',
    methods: { trans },
    components: { InfoIcon },
    props: {
        assetsByType: {},
    },
};
</script>
