<template>
    <dialog-modal :max-width="maxWidth" :show="show" type="danger">
        <template #title>
            <slot name="title" />
        </template>
        <slot name="content" />
        <template #footer>
            <jet-button color="secondary" @click="$emit('close')">
                {{ trans('general.action.cancel') }}
            </jet-button>
            <slot name="footer" />
        </template>
    </dialog-modal>
</template>

<script>
import JetButton from '@/Jetstream/Button.vue';
import DialogModal from '@/Jetstream/DialogModal.vue';

export default {
    name: 'JetConfirmationModal',

    emits: ['close'],

    components: {
        DialogModal,
        JetButton,
    },

    props: {
        type: {
            default: '',
        },
        show: {
            default: false,
        },
        maxWidth: {
            default: undefined,
        },
        closeable: {
            default: true,
        },
    },

    methods: {
        close() {
            this.$emit('close');
        },
    },
};
</script>
