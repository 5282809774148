<template>
    <div>
        <div
            v-if="canCreate"
            :class="{
                'mb-2': canCreate && attachments?.length > 0,
            }"
        >
            <file-upload
                :key="componentKey"
                :invalid-feedback="form.errors.attachments"
                @uploadCompleted="onUploadCompleted"
            />
        </div>
        <file-list
            v-if="attachments && attachments?.length"
            :attachments="attachments"
            :can-delete="canDelete"
            :can-edit="canEdit"
            :can-read="canRead"
            @deleted="emits('deletedAttachment', $event)"
            @updated="emits('updatedAttachment', $event)"
        />
    </div>
</template>
<script setup>
import FileList from '@/Components/FileList.vue';
import FileUpload from '@/Components/FileUpload.vue';
import { ref } from 'vue';
import { useForm } from '@inertiajs/vue3';

const componentKey = ref(0);

const props = defineProps({
    attachments: Array,
    storeRoute: String,
    form: Object,
    canCreate: Boolean,
    canDelete: Boolean,
    canRead: Boolean,
    canEdit: Boolean,
    showEmptyState: Boolean,
});

const emits = defineEmits([
    'success',
    'updatedAttachment',
    'deletedAttachment',
]);
const form = props.form || useForm({ attachments: [] });

const onUploadCompleted = (fileIds) => {
    form.attachments = fileIds;

    if (!!props.form || !props.storeRoute) {
        return;
    }

    form.post(props.storeRoute, {
        preserveScroll: true,
        onSuccess: () => {
            form.reset();
            componentKey.value++;
            emits('success');
        },
    });
};
</script>
