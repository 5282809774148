<template>
    <div :class="{ 'loading-ripple': total === undefined }" class="db-card-sm">
        <header class="d-flex justify-content-between align-items-start">
            <div>
                {{ trans('dashboard.asset_management.total.title') }}
            </div>
            <info-icon
                :tooltip="trans('dashboard.asset_management.total.tooltip')"
                style="margin-top: 4px"
            />
        </header>
        <div class="d-flex align-items-center mt-3" style="gap: 10px">
            <icon icon="cil-layers" style="height: 33px; width: 33px" />
            <div
                class="d-flex align-items-center"
                style="font-size: 40px; min-height: 60px"
            >
                <span v-if="total">{{ total }}</span>
                <skeleton-loader v-else />
            </div>
        </div>
    </div>
</template>
<script>
import InfoIcon from '@/Components/InfoIcon.vue';
import SkeletonLoader from '@/Components/SkeletonLoader.vue';
import { trans } from '@/mixins/i18n.js';

export default {
    name: 'AssetTotal',
    methods: { trans },
    components: { SkeletonLoader, InfoIcon },
    props: {
        total: {
            type: Number,
        },
    },
};
</script>
