<template>
    <div v-if="modelValue">
        <jet-select
            v-model="modelValue.type"
            :horizontal="false"
            :invalid-feedback="modelValue.errors.type"
            :label="trans('maintainers.overview.sla.asset_type.title')"
            :options="assetTypes"
            :required="true"
        >
            <template #selectedValue="{ selectedItem, placeholder }">
                <div
                    class="d-flex justify-content-between align-items-center gap-3"
                >
                    <span>{{ selectedItem?.label || placeholder }}</span
                    ><small class="text-muted">{{ selectedItem?.value }}</small>
                </div>
            </template>
            <template #item-label="{ option }">
                <div
                    class="d-flex justify-content-between align-items-center gap-3"
                >
                    <span>{{ option.label }}</span
                    ><small class="text-muted">{{ option.value }}</small>
                </div>
            </template>
        </jet-select>
        <jet-select
            v-model="modelValue.contractingAuthorityId"
            :horizontal="false"
            :invalid-feedback="modelValue.errors.contractingAuthorityId"
            :label="trans('maintainers.sla.contracting_authority.title')"
            :options="contractingAuthorities"
        />
        <table class="table table-unstyled">
            <thead>
                <tr>
                    <th>{{ trans('maintainers.sla.priority.title') }}</th>
                    <th>
                        <div
                            class="d-flex justify-content-between align-items-center"
                        >
                            <div>
                                {{
                                    trans(
                                        'maintainers.sla.time_to_respond.title',
                                    )
                                }}
                            </div>
                            <info-icon
                                :tooltip="
                                    trans(
                                        'maintainers.sla.time_to_respond.tooltip',
                                    )
                                "
                            />
                        </div>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="align-middle">
                        <work-order-priority
                            :priority="getPriorityLabel('high')"
                        />
                    </td>
                    <td>
                        <duration-selector
                            v-model="modelValue.timeToRespond.high"
                        />
                    </td>
                </tr>
                <tr>
                    <td class="align-middle">
                        <work-order-priority
                            :priority="getPriorityLabel('medium')"
                        />
                    </td>
                    <td>
                        <duration-selector
                            v-model="modelValue.timeToRespond.medium"
                            class="py-1"
                        />
                    </td>
                </tr>
                <tr>
                    <td class="align-middle">
                        <work-order-priority
                            :priority="getPriorityLabel('low')"
                        />
                    </td>
                    <td>
                        <duration-selector
                            v-model="modelValue.timeToRespond.low"
                        />
                    </td>
                </tr>
                <tr>
                    <td></td>
                    <td>
                        <div
                            class="d-flex justify-content-between align-items-center py-1"
                        >
                            <div>
                                {{
                                    trans(
                                        'maintainers.sla.time_to_resolve.title',
                                    )
                                }}
                            </div>
                            <info-icon
                                :tooltip="
                                    trans(
                                        'maintainers.sla.time_to_resolve.tooltip',
                                    )
                                "
                            />
                        </div>
                    </td>
                </tr>
                <tr>
                    <td class="align-middle">
                        <work-order-priority
                            :priority="getPriorityLabel('high')"
                        />
                    </td>
                    <td>
                        <duration-selector
                            v-model="modelValue.timeToResolve.high"
                        />
                    </td>
                </tr>
                <tr>
                    <td class="align-middle">
                        <work-order-priority
                            :priority="getPriorityLabel('medium')"
                        />
                    </td>
                    <td>
                        <duration-selector
                            v-model="modelValue.timeToResolve.medium"
                            class="py-1"
                        />
                    </td>
                </tr>
                <tr>
                    <td class="align-middle">
                        <work-order-priority
                            :priority="getPriorityLabel('low')"
                        />
                    </td>
                    <td>
                        <duration-selector
                            v-model="modelValue.timeToResolve.low"
                        />
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="mt-3">
            <label>{{ trans('maintainers.sla.agreement_term.title') }}</label>
            <jet-datepicker
                :horizontal="false"
                :invalid-feedback="modelValue.errors.startDate"
                :label="trans('maintainers.sla.agreement_term.from.title')"
                :model-value="modelValue.startDate"
                :required="true"
                class="mb-2"
                @update:model-value="setStartDate($event)"
            />
            <jet-datepicker
                :horizontal="false"
                :invalid-feedback="modelValue.errors.endDate"
                :label="trans('maintainers.sla.agreement_term.to.title')"
                :min-date="modelValue.startDate"
                :model-value="modelValue.endDate"
                :required="true"
                class="mb-0"
                @update:model-value="setEndDate($event)"
            />
        </div>
    </div>
    <div>
        <label class="form-label">{{
            trans('general.attachments.label')
        }}</label>
        <attachments
            :attachments="attachments"
            :can-create="true"
            :can-delete="true"
            :can-edit="true"
            :can-read="true"
            :form="modelValue"
            @updated-attachment="$emit('updatedAttachment', $event)"
            @deleted-attachment="$emit('deletedAttachment', $event)"
        />
    </div>
</template>
<script>
import WorkOrderPriority from '@/Pages/WorkOrders/Modals/WorkOrderPriority.vue';
import InfoIcon from '@/Components/InfoIcon.vue';
import DurationSelector from '@/Pages/Stakeholders/Maintainers/DurationSelector.vue';
import JetDatepicker from '@/Jetstream/Datepicker.vue';
import JetRadioGroup from '@/Jetstream/RadioGroup.vue';
import JetCheckbox from '@/Jetstream/Checkbox.vue';
import JetSelect from '@/Jetstream/Select.vue';
import WorkingHoursSelector from '@/Pages/Stakeholders/Maintainers/WorkingHoursSelector.vue';
import JetInput from '@/Jetstream/Input.vue';
import Attachments from '@/Components/Attachments.vue';
import FileUpload from '@/Components/FileUpload.vue';
import FileList from '@/Components/FileList.vue';
import { trans } from '@/mixins/i18n.js';

export default {
    name: 'ServiceLevelAgreementForm',
    components: {
        FileList,
        FileUpload,
        Attachments,
        JetInput,
        WorkingHoursSelector,
        JetCheckbox,
        JetRadioGroup,
        JetDatepicker,
        DurationSelector,
        JetSelect,
        InfoIcon,
        WorkOrderPriority,
    },
    props: {
        modelValue: {},
        attachments: Array,
        assetTypes: {
            type: Array,
            default: () => [],
        },
        contractingAuthorities: {
            type: Array,
            default: () => [],
        },
        errors: {},
    },
    emits: ['updatedAttachment', 'deletedAttachment'],
    methods: {
        trans,
        getPriorityLabel(priority) {
            return (
                this.$page.props.priorities?.data.find(
                    (el) => el.value === priority,
                ) || {
                    value: priority,
                    label: priority,
                }
            );
        },
        setStartDate(date) {
            this.modelValue.startDate = date?.toFormat('y-MM-dd');
        },
        setEndDate(date) {
            this.modelValue.endDate = date?.toFormat('y-MM-dd');
        },
        getError(field) {
            return this.errors?.[field];
        },
    },
};
</script>
