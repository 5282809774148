<template>
    <row>
        <column class="d-flex justify-content-md-end justify-content-center">
            <jet-button
                :class="{ 'btn-icon': !buttonTitle }"
                class="mx-1"
                color="danger"
                @click.stop="showModal = true"
            >
                <span v-if="buttonTitle">{{ buttonTitle }}</span>
                <icon v-else icon="cil-trash" />
            </jet-button>
            <jet-confirmation-modal
                :show="showModal"
                type="danger"
                @close="close"
            >
                <template #title>
                    <slot name="title" />
                </template>

                <template #content>
                    <slot name="content" />
                </template>

                <template #footer>
                    <slot name="footer">
                        <jet-button
                            :disabled="isButtonDisabled"
                            :isProcessing="isProcessing"
                            color="danger"
                            @click.stop="$emit('onDelete')"
                        >
                            {{ trans('general.action.delete') }}
                        </jet-button>
                    </slot>
                </template>
            </jet-confirmation-modal>
        </column>
    </row>
</template>
<script>
import JetButton from '@/Jetstream/Button.vue';
import JetConfirmationModal from '@/Jetstream/ConfirmationModal.vue';

export default {
    name: 'ConfirmedDelete',

    props: {
        buttonTitle: {
            type: String,
            default: '',
        },
        isProcessing: {
            type: Boolean,
            default: false,
        },
        isButtonDisabled: {
            type: Boolean,
            default: false,
        },
    },

    components: {
        JetButton,
        JetConfirmationModal,
    },

    data() {
        return {
            showModal: false,
        };
    },

    methods: {
        close() {
            this.$emit('onCancel');
            this.showModal = false;
        },
    },
};
</script>
