<template>
    <div @click.stop="prevent">
        <div v-html="description" />
        <div v-if="reason">
            <small
                >{{ trans('activity.asset.deleted.reason') }}
                <span class="text-muted">{{ reason }}</span></small
            >
        </div>
    </div>
</template>
<script>
export default {
    name: 'AssetOperabilityChanged',
    props: {
        item: {},
    },
    computed: {
        description() {
            return this.item.description;
        },
        reason() {
            return this.item.properties?.reason;
        },
    },
    methods: {
        prevent(event) {
            event.preventDefault();
            event.stopImmediatePropagation();
        },
    },
};
</script>
