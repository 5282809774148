import { router } from '@inertiajs/vue3';
import { onUnmounted, ref } from 'vue'; // const openUnsavedChangesModal = (event: Object) => {
import { eventBus, events } from '../eventBus';

type Config = {
    hasChanges: () => boolean;
    handleSubmit: (event) => Promise<boolean>;
};

export const useUnsavedChangesWarning = (config: Config) => {
    const forceLeavePage = ref(false);

    const openUnsavedChangesModal = (event: Object) => {
        eventBus.$emit(events.openUnsavedChangesModal, {
            onSaveAndExitPressed: (closeModal, isProcessing) => {
                isProcessing.value = true;

                config
                    .handleSubmit({ redirectUrl: event.detail.visit.url })
                    .finally(() => {
                        isProcessing.value = false;
                        closeModal();
                    });
            },
            onExitWithoutSavingPressed: (closeModal) => {
                forceLeavePage.value = true;
                router.visit(event.detail.visit.url);
            },
        });
    };

    const shouldShowUnsavedChangesModal = (event: Object) => {
        return (
            config.hasChanges() &&
            event.detail.visit.method === 'get' &&
            !forceLeavePage.value &&
            !event.detail.visit.url.searchParams.has('force')
        );
    };

    let removeEventListener = router.on('before', (event) => {
        if (shouldShowUnsavedChangesModal(event)) {
            openUnsavedChangesModal(event);
            return false;
        }

        return true;
    });

    onUnmounted(() => {
        removeEventListener && removeEventListener();
    });
};
