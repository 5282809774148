<template>
    <div>
        <div>{{ trans('activity.asset.stakeholders.changed.title') }}</div>
        <table
            class="table-unstyled mt-2"
            style="font-size: 0.75rem; line-height: 0.875rem"
        >
            <tbody>
                <stakeholder-changes-row
                    :stakeholders="owners"
                    :title="trans('activity.asset.stakeholders.changed.owners')"
                />
                <stakeholder-changes-row
                    :stakeholders="operators"
                    :title="
                        trans('activity.asset.stakeholders.changed.operators')
                    "
                />
                <stakeholder-changes-row
                    :stakeholders="maintainers"
                    :title="
                        trans('activity.asset.stakeholders.changed.maintainers')
                    "
                />
            </tbody>
        </table>
    </div>
</template>
<script>
import StakeholderChangesRow from '@/Components/ActivityLog/ActivityTypes/StakeholderChangesRow.vue';
import main from '@/Pages/Dashboard/Components/AssetStatus/Main.vue';

export default {
    name: 'AssetStakeholdersChanged',
    components: { StakeholderChangesRow },
    props: {
        item: {},
    },
    computed: {
        main() {
            return main;
        },
        owners() {
            return this.item.properties?.owners;
        },
        operators() {
            return this.item.properties?.operators;
        },
        maintainers() {
            return this.item.properties?.maintainers;
        },
    },
};
</script>
