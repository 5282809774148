<template>
    <div>
        <badge
            :class="{ 'clickable-badge': issue.url }"
            :color="color"
            style="
                line-height: 1.25rem;
                height: 1.25rem;
                padding: 0 4px;
                font-size: 0.875rem;
            "
            @click.stop="openIssue"
            >{{ label.toUpperCase() }}
        </badge>
    </div>
</template>
<script>
import Badge from '@/Components/Badge.vue';

export default {
    components: { Badge },
    props: ['issue'],
    computed: {
        color() {
            switch (this.issue.priority) {
                case 'high':
                    return 'danger';
                case 'medium':
                    return 'warning';
                case 'low':
                    return 'info';
                default:
                    return 'secondary';
            }
        },
        label() {
            return this.trans('issue.' + this.issue.type);
        },
    },
    methods: {
        openIssue(event) {
            event.preventDefault();

            if (this.issue.url) {
                this.$inertia.visit(this.issue.url);
            }
        },
    },
};
</script>
<style scoped>
.clickable-badge {
    cursor: pointer;
}
</style>
