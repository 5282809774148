<template>
    <span
        v-if="number"
        class="d-inline-flex align-items-center gap-1"
        style="line-height: 22px"
    >
        <icon class="text-primary" icon="cil-phone" />
        <a :href="link" target="_blank">{{ number }}</a>
    </span>
</template>
<script>
export default {
    name: 'PhoneLink',
    props: {
        number: String,
    },
    computed: {
        link() {
            return 'tel:' + this.number;
        },
    },
};
</script>
