<template>
    <div ref="target" class="position-relative d-inline-block">
        <slot :on="handlers" name="toggler" />
        <div
            v-if="visible"
            :class="{ show: visible }"
            :data-popper-placement="placement"
            :style="getStyle"
            class="popover bs-popover-auto fade"
            role="tooltip"
            style="position: absolute; margin: 0px"
        >
            <div
                :style="getArrowStyle"
                class="popover-arrow"
                style="position: absolute"
            />
            <!---->
            <div class="popover-body p-2">
                <slot />
            </div>
        </div>
    </div>
</template>
<script setup>
import { computed, ref } from 'vue';
import { onClickOutside } from '@vueuse/core';

const target = ref(null);
const visible = ref(false);
let scrollListener = null;

const props = defineProps({
    placement: {
        type: String,
        validator(value, props) {
            return ['bottom', 'left'].includes(value);
        },
        default: 'bottom',
    },
});

const togglePopover = () => {
    visible.value = !visible.value;

    if (visible.value) {
        scrollListener = attachScrollListener();
    }
};

const hideElement = () => {
    visible.value = false;
    scrollListener && scrollListener();
};

onClickOutside(target, hideElement);

const attachScrollListener = () => {
    document.addEventListener('scroll', hideElement);
    return () => {
        document.removeEventListener('scroll', hideElement);
    };
};

const getStyle = computed(() => {
    switch (props.placement) {
        case 'bottom':
            return { left: '-8px' };
        case 'left':
            return { top: 0, right: '100%' };
    }
});

const getArrowStyle = computed(() => {
    switch (props.placement) {
        case 'bottom':
            return { left: 0, transform: 'translate3d(8px, 0, 0px)' };
        case 'left':
            return { right: '-8px', top: '4px' };
    }
});

const handlers = {
    mousedown: togglePopover,
};
</script>
