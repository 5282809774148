<template>
    <div v-if="hasAnyPermission('asset.pid')">
        <div>
            <small class="text-muted text-truncate">{{
                trans('assets.overview.issue.onboarding_incomplete')
            }}</small>
        </div>
        <a class="clickable-badge" @click.stop="$emit('open-scanner')">
            <badge color="primary"
                >{{ trans('assets.overview.scanner.title') }}
            </badge>
        </a>
    </div>
</template>
<script>
export default {
    name: 'onboarded-asset-issue',
    emits: 'open-scanner',
};
</script>
