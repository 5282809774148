<template>
    <td>
        <div>
            <div class="d-flex">
                <status-badge
                    :operability="item.status.operability"
                    :usage="item.status.usageCategory"
                />
            </div>
            <status-check-date
                v-if="
                    item.status.hasOwnProperty('lastCheckedAt') ||
                    item.status.hasOwnProperty('operabilityLastCheckedAt')
                "
                :status="item.status"
                class="text-nowrap text-dark text-small"
            />
            <div class="mt-2">
                <table class="table table-sm table-unstyled">
                    <tbody>
                        <tr>
                            <th class="text-nowrap">Inoperable since:</th>
                            <td
                                class="text-nowrap px-3 px-xl-5"
                                style="width: 100%"
                            >
                                {{
                                    $filters.dateTimeMedium(
                                        item.status.inoperableSince,
                                        currentLocale,
                                    )
                                }}
                            </td>
                        </tr>
                        <tr>
                            <th class="text-nowrap">Downtime:</th>
                            <td
                                class="text-nowrap px-3 px-xl-5"
                                style="width: 100%"
                            >
                                {{
                                    $filters.duration(
                                        item.status.downtimeInSeconds,
                                        currentLocale,
                                        {
                                            style: 'narrow',
                                        },
                                    )
                                }}
                            </td>
                        </tr>
                        <tr>
                            <th class="text-nowrap">Reason:</th>
                            <td
                                class="text-nowrap px-3 px-xl-5"
                                style="width: 100%"
                            >
                                <additional-status-information
                                    :additional-information="
                                        item.status.additionalInformation
                                    "
                                    :reason="
                                        item.status.operabilityChangeReason ||
                                        trans('general.not_available')
                                    "
                                />
                            </td>
                        </tr>
                        <tr>
                            <th class="text-nowrap">Work Order(s):</th>
                            <td
                                v-if="item.status.workOrders.length"
                                class="text-nowrap px-3 px-xl-5"
                            >
                                <ul class="list-unstyled">
                                    <li
                                        v-for="workOrder in item.status
                                            .workOrders"
                                        :key="workOrder.id"
                                        style="
                                            user-select: none;
                                            cursor: pointer;
                                        "
                                        @click.stop="
                                            toggleWorkOrderDetails(workOrder)
                                        "
                                    >
                                        <div
                                            class="d-flex justify-content-between align-items-center"
                                        >
                                            <work-order-type-icon
                                                :text="workOrder.code"
                                                :work-order="workOrder"
                                            />
                                            <em
                                                :class="{
                                                    down: !workOrderDetailsVisible(
                                                        workOrder,
                                                    ),
                                                    up: workOrderDetailsVisible(
                                                        workOrder,
                                                    ),
                                                }"
                                                class="icon-caret"
                                            />
                                        </div>
                                        <collapse
                                            :show="
                                                workOrderDetailsVisible(
                                                    workOrder,
                                                )
                                            "
                                        >
                                            <table
                                                class="table table-sm table-unstyled"
                                            >
                                                <tbody>
                                                    <tr>
                                                        <th>Status:</th>
                                                        <td>
                                                            {{
                                                                workOrder.status
                                                                    .label
                                                            }}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>Priority:</th>
                                                        <td>
                                                            <work-order-priority
                                                                :priority="
                                                                    workOrder.priority
                                                                "
                                                            />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>Assignee:</th>
                                                        <td>
                                                            {{
                                                                workOrder.assignee ||
                                                                trans(
                                                                    'workorder.assignee.unassigned',
                                                                )
                                                            }}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </collapse>
                                    </li>
                                </ul>
                            </td>
                            <td v-else class="text-nowrap px-3 px-xl-5">
                                {{ trans('general.not_available') }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </td>
</template>
<script>
import AdditionalStatusInformation from '@/Components/AdditionalStatusInformation.vue';
import StatusBadge from '@/Components/StatusBadge.vue';
import StatusCheckDate from '@/Components/StatusCheckDate.vue';
import WorkOrderPriority from '@/Pages/WorkOrders/Modals/WorkOrderPriority.vue';
import WorkOrderTypeIcon from '@/Pages/WorkOrders/Modals/WorkOrderTypeIcon.vue';
import Collapse from '@/Components/Collapse.vue';

export default {
    name: 'daily-report-status-cell',
    components: {
        Collapse,
        AdditionalStatusInformation,
        StatusBadge,
        StatusCheckDate,
        WorkOrderPriority,
        WorkOrderTypeIcon,
    },
    props: {
        item: {},
    },
    data() {
        return {
            visibleWorkOrderDetails: [],
        };
    },
    methods: {
        toggleWorkOrderDetails(workOrder) {
            if (this.workOrderDetailsVisible(workOrder)) {
                this.visibleWorkOrderDetails =
                    this.visibleWorkOrderDetails.filter(
                        (id) => id !== workOrder.id,
                    );
            } else {
                this.visibleWorkOrderDetails.push(workOrder.id);
            }
        },
        workOrderDetailsVisible(workOrder) {
            return this.visibleWorkOrderDetails.includes(workOrder.id);
        },
    },
};
</script>
