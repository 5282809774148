<template>
    <dialog-modal
        :closeable="false"
        :show="show"
        type="danger"
        @close="$emit('close')"
    >
        <template #title
            >{{ trans('workorder.modal.confirm_delete.title') }}
        </template>

        <div>{{ trans('workorder.modal.confirm_delete.description') }}</div>
        <div class="text-large text-center my-3">
            {{ form.workOrder?.code }}
        </div>
        <div class="text-danger d-flex align-items-start" style="gap: 10px">
            <icon color="danger" icon="cil-warning" style="height: 22px" />
            <div>{{ trans('workorder.modal.confirm_delete.warning') }}</div>
        </div>

        <template #footer>
            <jet-button color="secondary" @click.stop="closeModal"
                >{{ trans('general.action.cancel') }}
            </jet-button>
            <jet-button
                :is-processing="form.processing"
                color="danger"
                @click.stop="deleteWorkOrder"
            >
                {{ trans('general.action.delete') }}
            </jet-button>
        </template>
    </dialog-modal>
</template>

<script>
import JetButton from '@/Jetstream/Button.vue';
import DialogModal from '@/Jetstream/DialogModal.vue';
import { eventBus, events } from '@/eventBus.js';

export default {
    components: {
        DialogModal,
        JetButton,
    },
    data() {
        return {
            shouldRender: false,
            show: false,
            form: this.$inertia.form({ workOrder: null }),
            onDeleting: null,
            onDeleted: null,
        };
    },

    mounted() {
        eventBus.$on(events.openDeleteWorkOrderModal, (event) => {
            this.form.workOrder = event.workOrder;
            this.onDeleting = event.onDeleting || null;
            this.onDeleted = event.onDeleted || null;

            this.shouldRender = true;
            this.$nextTick(() => {
                this.show = true;
            });
        });
    },
    beforeUnmount() {
        eventBus.$off(events.openDeleteWorkOrderModal);
    },
    methods: {
        deleteWorkOrder() {
            if (!this.form.workOrder) {
                return;
            }

            this.onDeleting && this.onDeleting(this.form.workOrder);

            this.form
                .transform(() => {})
                .delete(route('workorders.destroy', this.form.workOrder), {
                    preserveState: false,
                    onFinish: () => {
                        this.closeModal();
                        this.onDeleted && this.onDeleted(this.form.workOrder);
                    },
                });
        },
        closeModal() {
            this.show = false;
            setTimeout(() => {
                this.shouldRender = false;
            }, 300);

            this.workOrder = null;
            this.onDeleting = null;
            this.onDeleted = null;
        },
    },
};
</script>
