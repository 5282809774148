<template>
    <span>
        <span @click="startConfirmingPassword">
            <slot />
        </span>

        <dialog-modal :show="confirmingPassword" @close="closeModal">
            <template #title>
                {{ title }}
            </template>

            {{ content }}

            <div class="mt-4">
                <form @submit.prevent="$emit('submitted')">
                    <jet-input
                        ref="password"
                        v-model="form.password"
                        :invalidFeedback="form.error"
                        :placeholder="trans('auth.general.password')"
                        autocomplete="current-password"
                        class="mt-1 block w-3/4"
                        type="password"
                        @keyup.enter="confirmPassword"
                    />
                </form>
            </div>

            <template #footer>
                <jet-button color="secondary" @click="closeModal">
                    {{ trans('general.action.cancel') }}
                </jet-button>

                <jet-button
                    :class="{ 'opacity-25': form.processing }"
                    :disabled="form.processing"
                    class="ml-2"
                    @click="confirmPassword"
                >
                    {{ button }}
                </jet-button>
            </template>
        </dialog-modal>
    </span>
</template>

<script>
import JetButton from '@/Jetstream/Button.vue';
import JetInput from '@/Jetstream/Input.vue';
import JetInputError from '@/Jetstream/InputError.vue';
import DialogModal from '@/Jetstream/DialogModal.vue';
import { $http } from '@/bootstrap.js';

export default {
    name: 'JetConfirmsPassword',

    emits: ['confirmed'],

    props: {
        title: {
            default: 'Confirm Password',
        },
        content: {
            default:
                'For your security, please confirm your password to continue.',
        },
        button: {
            default: 'Confirm',
        },
    },

    components: {
        DialogModal,
        JetButton,
        JetInput,
        JetInputError,
    },

    data() {
        return {
            confirmingPassword: false,
            form: {
                password: '',
                error: '',
            },
        };
    },

    methods: {
        startConfirmingPassword() {
            $http.get(route('password.confirmation')).then((response) => {
                if (response.data.confirmed) {
                    this.$emit('confirmed');
                } else {
                    this.confirmingPassword = true;

                    setTimeout(() => this.$refs.password.focus(), 250);
                }
            });
        },

        confirmPassword() {
            this.form.processing = true;

            $http
                .post(route('password.confirm'), {
                    password: this.form.password,
                })
                .then(() => {
                    this.form.processing = false;
                    this.closeModal();
                    this.$nextTick(() => this.$emit('confirmed'));
                })
                .catch((error) => {
                    this.form.processing = false;
                    this.form.error = error.response.data.errors.password[0];
                    this.$refs.password.focus();
                });
        },

        closeModal() {
            this.confirmingPassword = false;
            this.form.password = '';
            this.form.error = '';
        },
    },
};
</script>
