<template>
    <dialog-modal
        v-if="shouldRender"
        :is-processing="isProcessing"
        :primary-button-label="trans('general.action.save_and_exit')"
        :secondary-button-label="trans('general.action.exit_without_saving')"
        :show="showModal"
        type="light"
        @close="exitWithoutSaving"
        @submit="saveAndExit"
    >
        <template #title
            >{{ trans('general.unsaved_changes.modal.title') }}
        </template>
        {{ trans('general.unsaved_changes.modal.description') }}
    </dialog-modal>
</template>
<script setup>
import DialogModal from '@/Jetstream/DialogModal.vue';
import { eventBus, events } from '@/eventBus.js';
import { nextTick, onBeforeUnmount, onMounted, ref } from 'vue';

const emit = defineEmits(['close']);
const shouldRender = ref(false);
const showModal = ref(false);
const isProcessing = ref(false);
const saveAndExitCallback = ref(null);
const exitWithoutSavingCallback = ref(null);

const closeModal = async () => {
    return new Promise((resolve) => {
        showModal.value = false;

        setTimeout(() => {
            resolve();
            shouldRender.value = false;
        }, 150);

        emit('close');
    });
};

const exitWithoutSaving = async () => {
    await closeModal();

    if (exitWithoutSavingCallback.value) {
        exitWithoutSavingCallback.value();
    }
};

const saveAndExit = () => {
    if (saveAndExitCallback.value) {
        saveAndExitCallback.value(closeModal, isProcessing);
    }
};

onMounted(() => {
    eventBus.$on([events.openUnsavedChangesModal], (data) => {
        shouldRender.value = true;
        saveAndExitCallback.value = data.onSaveAndExitPressed;
        exitWithoutSavingCallback.value = data.onExitWithoutSavingPressed;

        nextTick(() => {
            showModal.value = true;
        });
    });
});

onBeforeUnmount(() => {
    eventBus.$off([events.openUnsavedChangesModal]);
    saveAndExitCallback.value = null;
    exitWithoutSavingCallback.value = null;
});
</script>
