<template>
    <div>
        <div
            :class="{ 'is-invalid': !!invalidFeedback }"
            class="p-3 filepond-upload-container"
        >
            <icon
                class="filepond-upload-icon"
                icon="cil-cloud-upload"
                style="height: 25px !important"
            />
            <file-pond
                ref="pond"
                :allow-multiple="true"
                :allow-remove="true"
                :allow-replace="false"
                :allow-revert="true"
                :labelIdle="label"
                :label_button-process-item="
                    trans('general.file_upload.label_button_process_item')
                "
                :label_button_abort_item_load="
                    trans('general.file_upload.label.button_abort_item_load')
                "
                :label_button_abort_item_processing="
                    trans(
                        'general.file_upload.label.button_abort_item_processing',
                    )
                "
                :label_button_remove_item="
                    trans('general.file_upload.label.button_remove_item')
                "
                :label_button_retry_item_load="
                    trans('general.file_upload.label.button_retry_item_load')
                "
                :label_button_retry_item_processing="
                    trans(
                        'general.file_upload.label.button_retry_item_processing',
                    )
                "
                :label_button_undo_item_processing="
                    trans(
                        'general.file_upload.label.button_undo_item_processing',
                    )
                "
                :label_file_load_error="
                    trans('general.file_upload.label.file_load_error')
                "
                :label_file_loading="
                    trans('general.file_upload.label.file_loading')
                "
                :label_file_processing="
                    trans('general.file_upload.label.file_processing')
                "
                :label_file_processing_aborted="
                    trans('general.file_upload.label.file_processing_aborted')
                "
                :label_file_processing_complete="
                    trans('general.file_upload.label.file_processing_complete')
                "
                :label_file_processing_error="labelFileProcessingError"
                :label_file_processing_revert_error="
                    trans(
                        'general.file_upload.label.file_processing_revert_error',
                    )
                "
                :label_file_remove_error="
                    trans('general.file_upload.label.file_remove_error')
                "
                :label_file_size_not_available="
                    trans('general.file_upload.label.file_size_not_available')
                "
                :label_file_waiting_for_size="
                    trans('general.file_upload.label.file_waiting_for_size')
                "
                :label_invalid_field="
                    trans('general.file_upload.label.invalid_field')
                "
                :label_tap_to_cancel="
                    trans('general.file_upload.label.tap_to_cancel')
                "
                :label_tap_to_retry="
                    trans('general.file_upload.label.tap_to_retry')
                "
                :label_tap_to_undo="
                    trans('general.file_upload.label.tap_to_undo')
                "
                :server="serverConfig"
                accepted-file-types="image/jpeg, image/png, application/pdf"
                credits=""
                iconRemove='<svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
  <path d="M9.99992 6.33282L5.99992 10.3328M5.99992 6.33282L9.99992 10.3328M14.6666 8.33282C14.6666 12.0147 11.6818 14.9995 7.99992 14.9995C4.31802 14.9995 1.33325 12.0147 1.33325 8.33282C1.33325 4.65092 4.31802 1.66615 7.99992 1.66615C11.6818 1.66615 14.6666 4.65092 14.6666 8.33282Z" stroke="#3E56C6" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
</svg>'
                name="upload"
                @addfilestart="onAddFileStart"
                @processfiles="onFilesProcessed"
                @removefile="onRemoveFile"
            />
        </div>
        <div
            v-if="invalidFeedback"
            class="invalid-feedback"
            v-text="invalidFeedback"
        />
    </div>
</template>
<script>
import vueFilePond from 'vue-filepond';

import 'filepond/dist/filepond.min.css';

import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import { fileHash } from '@/filehash.js';
import { $http } from '@/bootstrap.js';
import prettyBytes from 'pretty-bytes';
import { isTablet } from '@/Utils/UseMedia'; // Create component

// Create component
const FilePond = vueFilePond(FilePondPluginFileValidateType);

export default {
    components: {
        FilePond,
    },

    props: {
        modelValue: {},
        invalidFeedback: {
            type: String,
            default: '',
        },
    },

    emits: [
        'update:model-value',
        'upload-started',
        'upload-completed',
        'upload-failed',
    ],

    setup() {
        return {
            isTablet,
        };
    },

    data: function () {
        return {
            serverConfig: {
                url: this.route('attachments.store'),
                headers: {
                    'X-XSRF-TOKEN': this.getCookie('XSRF-TOKEN'),
                },
                process: async (
                    fieldName,
                    file,
                    metadata,
                    load,
                    error,
                    progress,
                    abort,
                ) => {
                    try {
                        const response = await $http.post(
                            this.route('attachments.store'),
                            {
                                content_type: file.type,
                                size: file.size,
                                file_name: file.name,
                                checksum: await fileHash(file),
                            },
                        );

                        let formData = new FormData();
                        Object.entries(response.data.fields).forEach(function ([
                            key,
                            value,
                        ]) {
                            formData.append(key, value);
                        });

                        formData.append('file', file);

                        await $http.post(response.data.url, formData, {
                            onUploadProgress: (e) => {
                                progress(
                                    e.event.lengthComputable,
                                    e.loaded,
                                    e.total,
                                );
                            },
                        });
                        load(response.data.id);
                    } catch (e) {
                        const message =
                            e.response?.data?.message || e.message || e;
                        error(message);
                        this.$emit('upload-failed');
                    }
                },
            },
        };
    },

    computed: {
        label() {
            const platform = isTablet.value ? 'mobile' : 'desktop';

            return [
                '<div class="text-primary">',
                this.trans('general.file_upload.label.idle.' + platform),
                '</div>',
                '<small style="font-size: 0.8em !important;">',
                this.trans('general.file_upload.label.max_file_size', {
                    size: prettyBytes(
                        this.$page.props.settings.maxUploadFileSize,
                        { binary: true, locale: 'en' },
                    ).replace('i', ''),
                }),
                '</small>',
            ].join('');
        },
    },

    methods: {
        getCookie: function (cname) {
            let name = cname + '=';
            let cookieArray = document.cookie.split(';');

            for (let i = 0; i < cookieArray.length; i++) {
                let cookie = cookieArray[i];

                while (cookie.charAt(0) === ' ') {
                    cookie = cookie.substring(1);
                }

                if (cookie.indexOf(name) === 0) {
                    return decodeURIComponent(
                        cookie.substring(name.length, cookie.length),
                    );
                }
            }
            return '';
        },
        onAddFileStart() {
            this.$emit('upload-started');
        },
        onFilesProcessed() {
            const ids = this.$refs.pond.getFiles().map((file) => {
                return file.serverId;
            });

            this.$emit('update:model-value', ids);
            this.$emit('upload-completed', ids);
        },
        onRemoveFile() {
            const ids = this.$refs.pond.getFiles().map((file) => {
                return file.serverId;
            });

            this.$emit('update:model-value', ids);
        },
        labelFileProcessingError(error) {
            return error.body;
        },
    },
};
</script>
