<template>
    <span
        class="d-inline-flex align-items-top gap-1 text-truncate"
        style="line-height: 22px; max-width: 100%"
    >
        <icon
            v-if="showIcon"
            class="text-primary"
            name="cil-envelope-closed"
            style="position: relative; top: 1px"
        />
        <a
            :href="link"
            class="d-inline-block overflow-hidden"
            style="max-width: 100%"
            target="_blank"
        >
            <small v-if="size === 'sm'" class="text-truncate">{{
                email
            }}</small>
            <span
                v-else
                class="text-truncate d-inline-block"
                style="max-width: 100%"
                >{{ email }}</span
            >
        </a>
    </span>
</template>
<script>
export default {
    name: 'EmailLink',
    props: {
        email: String,
        showIcon: {
            type: Boolean,
            default: false,
        },
        size: {
            type: String,
        },
    },
    computed: {
        link() {
            return 'mailto:' + this.email;
        },
    },
};
</script>
