<template>
    <collapsable-filter-bar
        :active="activeFilters.isDirty"
        :is-processing="isProcessing"
        @clear="clearFilters"
    >
        <template #visible-filter="{ isOpen }">
            <search-filter
                v-model="activeFilters.search"
                :is-processing="isProcessing"
                :placeholder="trans('workorder.overview.filter.search')"
                :show-spinner="!isOpen"
            />
        </template>

        <location-filter :form="activeFilters" />

        <multi-select-dropdown
            v-if="types.length > 1"
            v-model="activeFilters.types"
            :label="trans('workorder.overview.filter.type')"
            :options="types"
            :show-select-all-button="false"
        >
            <template v-slot:label="{ option }">
                <div class="d-flex justify-content-between align-items-center">
                    <span class="mr-3">{{ option.label }}</span
                    ><small class="text-muted">{{ option.value }}</small>
                </div>
            </template>
        </multi-select-dropdown>
        <multi-select-dropdown
            v-model="activeFilters.usages"
            :label="trans('workorder.overview.filter.usage')"
            :options="usages"
            :show-select-all-button="false"
        />
        <multi-select-dropdown
            v-model="activeFilters.operabilities"
            :label="trans('workorder.overview.filter.operability')"
            :options="operabilities"
            :show-select-all-button="false"
            class="operability-filter"
        >
            <template v-slot:label="{ option }">
                <div
                    class="d-flex justify-content-between align-items-center"
                    style="gap: 5px"
                >
                    <span class="mr-3">{{ option.label }}</span>
                    <em :class="option.value" class="operability-indicator" />
                </div>
            </template>
        </multi-select-dropdown>
        <multi-select-dropdown
            v-model="activeFilters.workorders"
            :label="trans('workorder.overview.filter.workorder')"
            :options="workOrderTypes"
            :show-select-all-button="false"
        />
        <multi-select-dropdown
            v-model="activeFilters.priorities"
            :label="trans('workorder.overview.filter.priority')"
            :options="priorities"
            :show-select-all-button="false"
        >
            <template v-slot:label="{ option, show }">
                <div
                    class="d-flex gap-2 align-items-center justify-content-between"
                >
                    <span>{{ option.label }}</span>
                    <high v-if="option.value === 'high'" />
                    <medium v-if="option.value === 'medium'" />
                    <low v-if="option.value === 'low'" />
                </div>
            </template>
        </multi-select-dropdown>
        <multi-select-dropdown
            v-model="activeFilters.assignees"
            :label="trans('workorder.overview.filter.assignee')"
            :options="assignees"
            :show-searchbar="true"
            :show-select-all-button="false"
            :styles="{ width: '325px' }"
            class="assignee-filter"
        >
            <template v-slot:label="{ option, show }">
                <truncated-label :show="show" :text="option.label" />
            </template>
        </multi-select-dropdown>
    </collapsable-filter-bar>
</template>
<script>
import MultiSelectDropdown from '@/Components/MultiSelectDropdown.vue';
import TruncatedLabel from '@/Pages/WorkOrders/TruncatedLabel.vue';
import CollapsableFilterBar from '@/Components/CollapsableFilterBar.vue';
import SearchFilter from '@/Pages/WorkOrders/SearchFilter.vue';
import LocationFilter from '@/Pages/Dashboard/Components/LocationFilter.vue';
import High from '@/../icons/High.vue';
import Medium from '@/../icons/Medium.vue';
import Low from '@/../icons/Low.vue';

export default {
    components: {
        Low,
        Medium,
        High,
        LocationFilter,
        SearchFilter,
        CollapsableFilterBar,
        TruncatedLabel,
        MultiSelectDropdown,
    },
    emits: ['update:filter'],
    props: {
        isProcessing: Boolean,
    },
    data() {
        return {
            collapseFilters: true,
            activeFilters: this.$inertia.form({
                search: '',
                locations: [],
                specificLocations: [],
                types: [],
                usages: [],
                operabilities: [],
                workorders: [],
                priorities: [],
                assignees: [],
            }),
            size: {
                width: document.body.clientWidth,
            },
        };
    },
    computed: {
        types() {
            return this.$page.props.types?.data || [];
        },
        operabilities() {
            return this.$page.props.operabilities?.data || [];
        },
        usages() {
            return this.$page.props.usages?.data ?? [];
        },
        workOrderTypes() {
            return this.$page.props.workOrderTypes?.data ?? [];
        },
        assignees() {
            return (
                this.$page.props.assignees?.map((assignee) => {
                    return {
                        group: assignee.group,
                        data: assignee.data.map((data) => ({
                            label: data.name,
                            value: data.id,
                        })),
                    };
                }) ?? []
            );
        },
        priorities() {
            return this.$page.props.priorities?.data ?? [];
        },
    },
    created() {
        window.addEventListener('resize', this.updateSize);
    },

    destroyed() {
        window.removeEventListener('resize', this.updateSize);
    },

    mounted() {
        this.resetFilters();

        this.readValuesFromUrl();

        this.$watch(
            (vm) => [
                vm.activeFilters.search,
                vm.activeFilters.locations,
                vm.activeFilters.specificLocations,
                vm.activeFilters.types,
                vm.activeFilters.usages,
                vm.activeFilters.operabilities,
                vm.activeFilters.workorders,
                vm.activeFilters.priorities,
                vm.activeFilters.assignees,
            ],
            () => {
                this.$emit('update:filter', this.activeFilters.data());
            },
            {
                immediate: false,
                deep: true,
            },
        );
    },
    methods: {
        resetFilters() {
            this.activeFilters.reset();
        },
        readValuesFromUrl() {
            const {
                search,
                types,
                operabilities,
                workorders,
                usages,
                locations,
                specificLocations,
                assignees,
                priorities,
            } = this.route().params;

            this.searchTerm = search || '';

            this.activeFilters.search = search || '';
            this.activeFilters.types = types || [];
            this.activeFilters.usages = usages || [];
            this.activeFilters.locations = locations || [];
            this.activeFilters.specificLocations = specificLocations || [];
            this.activeFilters.operabilities = operabilities || [];
            this.activeFilters.workorders = workorders || [];
            this.activeFilters.priorities = priorities || [];
            this.activeFilters.assignees = assignees || [];
        },
        updateSize() {
            this.size.width = document.body.clientWidth;

            if (!this.isSmallScreen) {
                this.collapseFilters = false;
            }
        },

        clearFilters() {
            this.resetFilters();
        },
        getValues(items) {
            return items.map((el) => el.value);
        },
        getAssigneeValues(groups) {
            return groups?.map((group) => group.data.map((el) => el.value));
        },
    },
};
</script>
<style scoped>
.operability-indicator {
    width: 0.75rem;
    height: 0.75rem;
    display: inline-block;
    border-radius: 100%;
    border: 1px solid;
}

.operability-indicator.operable {
    border-color: #8e8e93;
}

.operability-indicator.inoperable {
    border-color: #8e8e93;
}

.operability-filter :deep(.c-multi-select-option) {
    padding-right: 0.625rem !important;
}

.assignee-filter :deep(.c-multi-select-options) {
    left: auto;
    right: 0;
}
</style>
