<template>
    <div>
        <popover v-if="additionalInformation">
            <template #toggler="{ on }">
                <a
                    class="d-inline-block popover-no-header clickable"
                    v-on="on"
                    @click.stop=""
                    >{{ reason }}
                </a>
            </template>
            <b>{{
                trans(
                    'assets.modal.operability_change.additional_information.label',
                )
            }}</b
            ><br />
            {{ additionalInformation }}
        </popover>
        <div v-else>
            {{ reason }}
        </div>
    </div>
</template>
<script>
import Popover from '@/Components/Popover.vue';
import { trans } from '../mixins/i18n.js';

export default {
    name: 'AdditionalStatusInformation',
    methods: { trans },
    components: { Popover },
    props: {
        reason: {},
        additionalInformation: {},
    },
};
</script>
