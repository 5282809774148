<template>
    <div>
        <card>
            <template #header>
                <custom-card-header
                    :title="trans('activities.overview.header')"
                />
            </template>
            <data-table
                :columns="fields"
                :items="activities"
                :no-items-view="noItemsView"
                :query-params="queryParams"
                :selection-enabled="selectionEnabled"
                @row-clicked="showDetails"
            >
                <template #date="{ item }">
                    <td class="align-top">
                        <div class="text-muted">
                            <div class="text-nowrap">
                                {{
                                    $filters.fullDate(item.date, currentLocale)
                                }}
                            </div>
                            <small>
                                <icon icon="cil-clock" />
                                {{
                                    $filters.time(item.date, currentLocale)
                                }}</small
                            >
                        </div>
                    </td>
                </template>

                <template #causer="{ item }">
                    <td class="align-top">
                        <user-information
                            v-if="item.causer"
                            :info="item.causer"
                        />
                        <span v-else>-</span>
                    </td>
                </template>

                <template #event="{ item }">
                    <td class="align-top">
                        <activity :item="item" />
                    </td>
                </template>
            </data-table>
        </card>
    </div>
</template>
<script>
import CustomCardHeader from '@/Components/CustomCardHeader.vue';
import UserInformation from '@/Components/UserInformation.vue';
import Activity from '@/Components/ActivityLog/Activity.vue';
import SortIcon from '@/Pages/Assets/SortIcon.vue';
import DataTable from '@/Components/DataTable.vue';
import Card from '@/Components/Card.vue';

export default {
    props: {
        activities: {
            type: [Object],
        },
        context: {
            type: String,
            required: true,
        },
        fields: {
            type: Array,
            default: () => ['date', 'causer', 'event'],
        },
        queryParams: Object,
    },
    components: {
        Card,

        DataTable,
        SortIcon,
        Activity,
        CustomCardHeader,
        UserInformation,
    },
    data() {
        return {
            noItemsView: {
                noItems: this.trans('activities.overview.results.empty'),
            },
        };
    },
    computed: {
        selectionEnabled() {
            return this.hasAnyPermission('activity.export')
                ? 'asset-activities'
                : false;
        },
    },
    methods: {
        showDetails(activity) {
            if (activity.url) {
                this.$inertia.visit(activity.url);
            }
        },
    },
};
</script>
