<template>
    <dialog-modal
        v-if="
            shouldRender &&
            hasAnyPermission(['maintainer.create', 'maintainer.update'])
        "
        :closeable="false"
        :is-processing="form.processing"
        :primary-button-label="
            !maintainer
                ? trans('general.action.add')
                : trans('general.action.save')
        "
        :show="show"
        :submit-disabled="!form.isDirty"
        @close="close"
        @submit="submitForm"
    >
        <template #title>{{ title }}</template>

        <form :key="componentKey" @submit.prevent="submitForm">
            <jet-input
                v-model="form.name"
                :autofocus="true"
                :horizontal="{ label: 'col-sm-4', input: 'col-sm-8' }"
                :invalidFeedback="form.errors.name"
                :label="trans('maintainers.form.name.label')"
                :placeholder="trans('maintainers.form.name.placeholder')"
                :required="true"
            />
            <row>
                <column sm="4"
                    >{{ trans('maintainers.form.contact_details.label') }}
                </column>
                <column sm="8">
                    <jet-input
                        v-model="form.email"
                        :horizontal="false"
                        :invalidFeedback="form.errors.email"
                        :label="trans('stakeholders.form.email.label')"
                        :placeholder="
                            trans('stakeholders.form.email.placeholder')
                        "
                        :required="true"
                    />
                    <jet-input
                        v-model="form.phone"
                        :horizontal="false"
                        :invalidFeedback="form.errors.phone"
                        :label="trans('stakeholders.form.phone.label')"
                        :placeholder="
                            trans('stakeholders.form.phone.placeholder')
                        "
                    />
                    <jet-input
                        v-model="form.description"
                        :horizontal="false"
                        :invalidFeedback="form.errors.description"
                        :label="trans('stakeholders.form.description.label')"
                        :placeholder="
                            trans('stakeholders.form.description.placeholder')
                        "
                    />
                </column>
            </row>
            <row>
                <column sm="4"
                    ><label>{{
                        trans('maintainers.sla.working_hours.title')
                    }}</label></column
                >
                <column sm="8">
                    <div>
                        <working-hours-selector v-model="form.workingHours" />
                    </div>
                </column>
            </row>
        </form>

        <required-fields />
    </dialog-modal>
</template>

<script>
import JetInput from '@/Jetstream/Input.vue';
import JetButton from '@/Jetstream/Button.vue';
import JetSelect from '@/Jetstream/Select.vue';
import DialogModal from '@/Jetstream/DialogModal.vue';
import FileUpload from '@/Components/FileUpload.vue';
import { eventBus, events } from '@/eventBus.js';
import ServiceLevelAgreementForm from '@/Pages/Stakeholders/Maintainers/ServiceLevelAgreementForm.vue';
import RequiredFields from '@/Components/RequiredFields.vue';
import { $http } from '@/bootstrap.js';
import WorkingHoursSelector from '@/Pages/Stakeholders/Maintainers/WorkingHoursSelector.vue';

export default {
    name: 'UpdateOrCreateMaintainerFormModal',
    emits: ['created', 'updated'],
    components: {
        WorkingHoursSelector,
        RequiredFields,
        ServiceLevelAgreementForm,
        FileUpload,
        DialogModal,
        JetButton,
        JetInput,
        JetSelect,
    },
    data() {
        return {
            shouldRender: false,
            show: false,
            maintainer: null,
            componentKey: 0,
            form: this.$inertia.form({
                name: '',
                email: '',
                phone: '',
                description: '',
                workingHours: '24/7',
            }),
        };
    },
    mounted() {
        eventBus.$on(
            [events.openCreateMaintainerModal, events.openEditMaintainerModal],
            (data) => {
                this.maintainer = data?.maintainer || null;
                this.reset();

                this.showModal();

                if (this.maintainer) {
                    this.loadMaintainerInfo();
                }
            },
        );
    },
    beforeUnmount() {
        eventBus.$off([
            events.openCreateMaintainerModal,
            events.openEditMaintainerModal,
        ]);
    },
    computed: {
        title() {
            return !!this.maintainer
                ? this.trans('maintainers.edit.title')
                : this.trans('maintainers.create.title');
        },
    },
    methods: {
        loadMaintainerInfo() {
            $http
                .get(this.route('maintainers.edit', this.maintainer))
                .then((response) => {
                    this.maintainer = response.data.maintainer;
                    this.form = this.$inertia.form({
                        name: this.maintainer?.name || '',
                        email: this.maintainer?.email || '',
                        phone: this.maintainer?.phone || '',
                        description: this.maintainer?.description || '',
                        workingHours: this.maintainer?.workingHours || '24/7',
                    });

                    this.componentKey += 1;
                });
        },
        submitForm() {
            let route = this.maintainer
                ? this.route('maintainers.update', {
                      maintainer: this.maintainer,
                  })
                : this.route('maintainers.store');

            this.form
                .transform((data) => {
                    if (this.maintainer) {
                        data._method = 'PUT';
                    }
                    return data;
                })
                .post(route, {
                    preserveScroll: true,
                    onSuccess: () => {
                        this.close();

                        this.$emit(
                            this.maintainer ? 'updated' : 'created',
                            this.maintainer,
                        );
                    },
                });
        },
        close() {
            this.show = false;
            setTimeout(() => {
                this.shouldRender = false;
            }, 300);
        },

        showModal() {
            this.shouldRender = true;
            this.$nextTick(() => {
                this.show = true;
            });
        },
        reset() {
            this.form = this.$inertia.form({
                name: '',
                email: '',
                phone: '',
                description: '',
                workingHours: '24/7',
            });
        },
    },
};
</script>
