<template>
    <div class="d-flex align-items-center" style="gap: 4px">
        <simple-select
            v-model="modelValue.value"
            :options="durationOptions"
            :resettable="true"
            label-classes="d-flex justify-content-center align-items-center px-3"
            style="min-width: 50px"
        />
        <simple-select
            v-model="modelValue.unit"
            :options="unitOptions"
            style="min-width: 110px"
            @update:model-value="unitUpdated"
        />
    </div>
</template>
<script>
import JetSelect from '@/Jetstream/Select.vue';
import SimpleSelect from '@/Pages/Stakeholders/Maintainers/SimpleSelect.vue';

export default {
    name: 'DurationSelector',
    components: { SimpleSelect, JetSelect },
    props: {
        modelValue: {
            type: Object,
            required: true,
        },
    },
    computed: {
        durationOptions() {
            return (this[this.modelValue?.unit] || this.hours).map((value) => ({
                label: value,
                value: value,
            }));
        },
        unitOptions() {
            return [
                {
                    label: this.trans('general.hours'),
                    value: 'hours',
                },
                {
                    label: this.trans('general.days'),
                    value: 'days',
                },
                {
                    label: this.trans('general.weeks'),
                    value: 'weeks',
                },
                {
                    label: this.trans('general.months'),
                    value: 'months',
                },
            ];
        },
        hours() {
            return Array.from({ length: 23 }, (_, i) => i + 1);
        },
        days() {
            return Array.from({ length: 6 }, (_, i) => i + 1);
        },
        weeks() {
            return Array.from({ length: 4 }, (_, i) => i + 1);
        },
        months() {
            return Array.from({ length: 12 }, (_, i) => i + 1);
        },
    },
    methods: {
        unitUpdated() {
            // Reset value if necessary
            let { unit, value } = this.modelValue;

            if (value > this[unit][this[unit].length - 1]) {
                value = null;
            }

            this.$emit('update:modelValue', { unit, value });
        },
    },
};
</script>
