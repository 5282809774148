<template>
    <card :card-classes="cardClasses" header-classes="bg-blue-light">
        <template #header>
            <h5 class="mb-0" v-text="title" />
            <div v-if="hasAnyPermission('configuration.update')" class="d-flex">
                <jet-button
                    v-if="hasAnyPermission('configuration.update')"
                    v-c-tooltip="trans('general.action.edit')"
                    class="btn btn-primary btn-icon"
                    @click="openModal"
                >
                    <icon icon="cil-pencil" />
                </jet-button>
            </div>
        </template>
        <dl>
            <div class="my-1 row">
                <dt class="col-md-7 text-uppercase">
                    {{ labelCadence }}
                </dt>
                <dd class="col-md-5 text-muted">
                    {{ valueCadence }}
                </dd>
            </div>
            <div class="my-1 row">
                <dt class="col-md-7 text-uppercase">
                    {{ labelNextDueDate }}
                </dt>
                <dd class="col-md-5 text-muted">
                    <div v-if="configuration?.nextDueDate">
                        {{
                            $filters.dateTimeMedium(
                                configuration.nextDueDate,
                                currentLocale,
                            )
                        }}
                    </div>
                    <div v-else>-</div>
                </dd>
            </div>
            <div
                v-if="configuration && configuration.performedBy"
                class="my-1 row"
            >
                <dt class="col-md-7 text-uppercase">
                    {{ labelPerformedBy }}
                </dt>
                <dd class="col-md-5 text-muted">
                    {{ configuration.performedBy.name }}
                </dd>
            </div>
            <div class="my-1 row">
                <dt class="col-md-7 text-uppercase">
                    {{ labelAlert }}
                </dt>
                <dd class="col-md-5 text-muted">
                    <div v-if="issues && issues.length">
                        <issue-badge
                            v-for="issue in issues"
                            :key="issue.id"
                            :issue="issue"
                        />
                    </div>
                    <span v-else>{{ valueNone }}</span>
                </dd>
            </div>
        </dl>
        <dialog-modal
            v-if="shouldRenderModal"
            :is-processing="form.processing"
            :primary-button-label="trans('general.action.apply')"
            :scrollable="false"
            :show="showModal"
            @close="closeModal"
            @submit="handleSubmit"
        >
            <template #title>{{ modalTitle }}</template>

            <cadence-config-form
                v-model="form.configuration"
                :errors="form.errors"
                :next-cadence-date-message-translation-key-never="`${translationKey}.configuration.form.cadence.next_interval_date.never`"
                :next-cadence-date-message-translation-key-specified="`${translationKey}.configuration.form.cadence.next_interval_date.specified`"
                :next-due-date-route="nextDueDateUrl"
                :update-due-date="showModal"
            />
        </dialog-modal>
    </card>
</template>

<script>
import JetButton from '@/Jetstream/Button.vue';
import IssueBadge from '@/Components/IssueBadge.vue';
import JetSelect from '@/Jetstream/Select.vue';
import DialogModal from '@/Jetstream/DialogModal.vue';
import JetInput from '@/Jetstream/Input.vue';
import CadenceConfigForm from '@/Components/CadenceConfigForm.vue';
import { DateTime } from 'luxon';
import Card from '@/Components/Card.vue';

export default {
    components: {
        Card,
        CadenceConfigForm,
        JetInput,
        DialogModal,
        JetSelect,
        IssueBadge,
        JetButton,
    },
    props: [
        'asset',
        'translationKey',
        'storeUrl',
        'nextDueDateUrl',
        'configuration',
        'type',
        'issues',
        'cardClasses',
    ],
    data() {
        return {
            showModal: false,
            shouldRenderModal: false,
            form: this.$inertia.form({
                configuration: this.configuration,
            }),
        };
    },
    computed: {
        valueNone() {
            return this.trans('general.none');
        },
        valueCadence() {
            if (!this.configuration) {
                return this.trans('qa.configuration.detail.cadence.never');
            }

            let { unit, value } = this.configuration;
            return this.trans(`qa.configuration.detail.cadence.${unit}`, {
                interval: value?.toString(),
            });
        },
        labelAlert() {
            return this.trans(
                `${this.translationKey}.configuration.details.alert.label`,
            );
        },
        labelCadence() {
            return this.trans(
                `${this.translationKey}.configuration.details.cadence.label`,
            );
        },
        labelNextDueDate() {
            return this.trans(
                `${this.translationKey}.configuration.details.next_due_date.label`,
            );
        },
        labelPerformedBy() {
            return this.trans(
                `${this.translationKey}.configuration.details.performed_by.label`,
            );
        },
        title() {
            return this.trans(
                `${this.translationKey}.configuration.details.title`,
            );
        },
        modalTitle() {
            return this.trans(
                `${this.translationKey}.configuration.modal.title`,
            );
        },
    },
    methods: {
        handleSubmit() {
            this.form.transform(this.transformData).post(this.storeUrl, {
                onSuccess: () => {
                    this.showModal = false;
                },
            });
        },
        openModal() {
            this.shouldRenderModal = true;
            this.$nextTick(() => {
                this.showModal = true;
            });
        },
        closeModal() {
            this.showModal = false;
            setTimeout(() => {
                this.shouldRenderModal = false;
            }, 300);
        },
        transformData(data) {
            let { startDate } = data.configuration;

            if (startDate instanceof DateTime) {
                startDate = startDate
                    .set({ hours: 0, minutes: 0, seconds: 0 })
                    .toFormat('yyyy-MM-dd');
            }

            return { ...data.configuration, startDate, type: this.type };
        },
    },
};
</script>

<style scoped>
:deep(.badge) {
    overflow: hidden !important;
    text-overflow: ellipsis;
    max-width: 100%;
}
</style>
