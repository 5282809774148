<template>
    <div class="mb-2">
        {{ trans('locations.users.assigned', { count: totalUserCount }) }}
    </div>
    <div v-for="group in groups">
        <h4 class="border-bottom text pb-1">
            {{ trans(`general.role.${group.role}.plural`) }}
            ({{ group.total }})
        </h4>
        <p v-if="group.total > 0">
            <span v-for="(user, index) in group.data">
                <Link :href="route('users.show', user.id)">{{
                    [user.firstName, user.lastName]
                        .filter((item) => item)
                        .join(' ')
                }}</Link
                ><span v-if="index < group.data.length - 1">,&nbsp;</span>
            </span>
            <span v-if="group.total - group.data.length > 0"
                >,&nbsp;<a href="#" @click.prevent="emits('showAll')"
                    >(+{{ group.total - group.data.length }})</a
                ></span
            >
        </p>
        <p v-else class="text-center">{{ trans('locations.users.empty') }}</p>
    </div>
</template>
<script setup>
import { computed } from 'vue';

const props = defineProps({
    groups: {
        type: Array,
        default: () => [],
    },
});

const emits = defineEmits(['showAll']);

const totalUserCount = computed(() =>
    (props.groups || []).reduce((a, b) => a + b?.total || 0, 0),
);
</script>
