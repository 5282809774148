<template>
    <c-dropdown
        :auto-close="autoClose"
        :color="color"
        :toggler-text="togglerText"
        :variant="variant"
        :visible="visible"
        @hide="emits('update:show', false)"
        @show="emits('update:show', true)"
    >
        <template v-if="$slots.toggler">
            <c-dropdown-toggle
                :as="as"
                :caret="caret"
                :class="togglerClasses"
                :custom="customToggler"
                :disabled="disabled"
                class="position-relative"
            >
                <slot name="toggler" />
            </c-dropdown-toggle>
        </template>
        <template v-else>
            <c-dropdown-toggle :color="color" :disabled="disabled">
                <spinner v-if="isProcessing" color="white" />
                <span v-else>{{
                    togglerText || trans('general.form.select.placeholder')
                }}</span>
            </c-dropdown-toggle>
        </template>
        <c-dropdown-menu>
            <slot />
        </c-dropdown-menu>
    </c-dropdown>
</template>

<script setup>
import {
    CDropdown,
    CDropdownMenu,
    CDropdownToggle,
} from '@coreui/vue/dist/esm/components/dropdown/index.js';
import { computed } from 'vue';

const emits = defineEmits(['update:show']);

const props = defineProps({
    as: { type: String, default: 'button' },
    caret: {
        type: Boolean,
        default: true,
    },
    variant: {
        type: String,
    },
    togglerText: {
        type: String,
    },
    togglerClasses: {
        type: [String, Array, Object],
    },
    color: {
        type: String,
        default: 'primary',
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    autoClose: {
        type: [Boolean, String],
        default: true,
    },
    visible: {
        type: Boolean,
        default: false,
    },
    isProcessing: {
        type: Boolean,
        default: false,
    },
});

const customToggler = computed(() => props.as !== 'button');
</script>
