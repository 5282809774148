<template>
    <div>
        <jet-radio-group
            v-model="workingHourMode"
            :class="{ 'mb-0': error }"
            :options="workingHourModes"
            @update:model-value="updateWorkingHours"
        />

        <div v-if="error" class="invalid-feedback d-block mb-3">
            {{ error }}
        </div>

        <div
            v-for="(day, index) in weekdays"
            v-if="workingHourMode !== '24/7'"
            :key="day.value"
            class="mb-1"
        >
            <div
                class="d-flex align-items-top justify-content-between is-invalid"
            >
                <jet-checkbox
                    :label="day.label"
                    :model-value="isWeekdayEnabled(day)"
                    @update:model-value="toggleWeekday(day, $event)"
                />
                <div>
                    <div class="d-flex align-items-center" style="gap: 5px">
                        <simple-select
                            :disabled="!isWeekdayEnabled(day)"
                            :is-invalid="
                                hasErrors(`workingHours.${index}.from`)
                            "
                            :model-value="dailyStartTime(day)"
                            :options="getTimeOptions(false)"
                            style="min-width: 105px"
                            @update:model-value="setStartTime(day, $event)"
                        />
                        <span>-</span>
                        <simple-select
                            :disabled="!isWeekdayEnabled(day)"
                            :is-invalid="hasErrors(`workingHours.${index}.to`)"
                            :min="parseTimeString(dailyStartTime(day)) + 29"
                            :model-value="dailyEndTime(day)"
                            :options="getTimeOptions(true)"
                            style="min-width: 105px"
                            @update:model-value="setEndTime(day, $event)"
                        />
                    </div>
                </div>
            </div>
            <div v-if="getError(index)" class="invalid-feedback">
                {{ getError(index) }}
            </div>
        </div>
    </div>
</template>
<script>
import JetCheckbox from '@/Jetstream/Checkbox.vue';
import SimpleSelect from '@/Pages/Stakeholders/Maintainers/SimpleSelect.vue';
import JetRadioGroup from '@/Jetstream/RadioGroup.vue';
import { getWeekdays } from '@/Pages/Stakeholders/Maintainers/weekdays.js';

export default {
    name: 'WorkingHoursSelector',
    components: { JetRadioGroup, SimpleSelect, JetCheckbox },
    props: ['modelValue', 'error'],
    data() {
        return {
            workingHourMode: '24/7',
            workingHourModes: [
                {
                    label: this.trans('maintainers.sla.working_hours.24_7'),
                    value: '24/7',
                },
                {
                    label: this.trans('maintainers.sla.working_hours.custom'),
                    value: 'custom',
                },
            ],
        };
    },
    computed: {
        weekdays() {
            return getWeekdays(this.currentLocale);
        },
        isCustom() {
            return this.workingHourMode === 'custom';
        },
    },
    mounted() {
        this.workingHourMode = this.modelValue === '24/7' ? '24/7' : 'custom';
    },
    methods: {
        isWeekdayEnabled(day) {
            if (!this.isCustom) {
                return true;
            }

            return this.modelValue
                .map((option) => option.day)
                .includes(day.value);
        },
        dailyStartTime(day) {
            if (!this.isCustom) {
                return '00:00';
            }

            return (
                this.modelValue.find((option) => option.day === day.value)
                    ?.from || '09:00'
            );
        },
        dailyEndTime(day) {
            if (!this.isCustom) {
                return '23:59';
            }

            return (
                this.modelValue.find((option) => option.day === day.value)
                    ?.to || '17:00'
            );
        },
        updateWorkingHours(value) {
            value = value === 'custom' ? [] : value;
            this.$emit('update:modelValue', value);
        },
        toggleWeekday(day, selected) {
            if (!this.isCustom) {
                return;
            }

            let value;

            if (!selected) {
                value = this.modelValue.filter(
                    (weekday) => weekday.day !== day.value,
                );
            } else {
                value = [
                    ...this.modelValue,
                    {
                        day: day.value,
                        from: '09:00',
                        to: '17:00',
                    },
                ];
            }
            this.$emit('update:modelValue', value);
        },
        setStartTime(day, time) {
            const value = this.modelValue.map((option) => {
                if (option.day === day.value) {
                    let startTime = this.parseTimeString(time);
                    let endTime = this.parseTimeString(option.to);

                    // Add 29/30 minutes to start time if needed
                    let minDuration = startTime === 23 * 60 + 30 ? 29 : 30;

                    endTime =
                        startTime >= endTime
                            ? this.convertTimeToString(startTime + minDuration)
                                  .h24
                            : option.to;

                    return {
                        ...option,
                        from: time,
                        to: endTime,
                    };
                }
                return option;
            });
            this.$emit('update:modelValue', value);
        },
        setEndTime(day, time) {
            const value = this.modelValue.map((option) => {
                if (option.day === day.value) {
                    return {
                        ...option,
                        to: time,
                    };
                }
                return option;
            });
            this.$emit('update:modelValue', value);
        },
        getTimeOptions(includingMidnight = false) {
            const options = [];

            for (let hour = 0; hour <= 23; hour++) {
                for (let minute of [0, 30]) {
                    let numericValue = hour * 60 + minute;
                    const timeString = this.convertTimeToString(numericValue);
                    options.push({
                        value: timeString.h24,
                        label: timeString.h12,
                        numericValue,
                    });
                }
            }

            if (includingMidnight) {
                options.push({
                    value: '23:59',
                    label: '11:59 PM',
                    numericValue: 1439, // 23 hours and 59 minutes
                });
            }

            return options;
        },
        convertTimeToString(time) {
            const hour24 = Math.floor(time / 60);
            const hour12 = hour24 > 12 ? hour24 - 12 : hour24;
            const minutes = time % 60;
            const hour24String = hour24.toString().padStart(2, '0');
            const hour12String = hour12.toString().padStart(2, '0');
            const minuteString = minutes.toString().padStart(2, '0');
            let merdiem = hour24 >= 12 ? 'PM' : 'AM';

            return {
                h24: `${hour24String}:${minuteString}`,
                h12: `${hour12String}:${minuteString} ${merdiem}`,
            };
        },
        parseTimeString(time) {
            let parts = time.split(':');
            return parseInt(parts[0]) * 60 + parseInt(parts[1]);
        },

        hasErrors(key) {
            return this.$page.props.errors && !!this.$page.props.errors[key];
        },
        getError(index) {
            const errors = this.$page.props.errors;

            if (!errors) {
                return null;
            }

            return (
                errors[`workingHours.${index}.from`] ||
                errors[`workingHours.${index}.to`]
            );
        },
    },
};
</script>
