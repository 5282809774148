<template>
    <div>
        <div
            :class="{ clickable: collapsable }"
            class="d-flex align-items-center justify-content-between text-primary"
            @click.stop="toggleCollapse"
        >
            <div class="d-flex align-items-center" style="gap: 5px">
                <span>{{ stakeholder.name }}</span>
                <exclamation-mark v-if="showExpirationWarning" type="filled" />
            </div>
            <em :class="{ down: !open, up: open }" class="icon-caret" />
        </div>
        <collapse :show="expanded" style="font-size: 14px; line-height: 20px">
            <row :class="{ 'mb-2': !horizontal }">
                <column :md="horizontal ? 6 : 12"
                    ><label class="text-truncate d-block mb-0">{{
                        trans('stakeholders.form.email.label')
                    }}</label></column
                >
                <column>
                    <div>
                        <email-link
                            v-if="stakeholder.email"
                            :email="stakeholder.email"
                            :show-icon="true"
                        />
                        <span v-else>{{ trans('general.not_available') }}</span>
                    </div>
                </column>
            </row>
            <row
                v-if="stakeholder.hasOwnProperty('phone')"
                :class="{ 'mb-2': !horizontal }"
            >
                <column :md="horizontal ? 6 : 12"
                    ><label class="text-truncate d-block mb-0">{{
                        trans('stakeholders.form.phone.label')
                    }}</label></column
                >
                <column>
                    <phone-link :number="stakeholder.phone" />
                </column>
            </row>
            <row :class="{ 'mb-2': !horizontal }">
                <column :md="horizontal ? 6 : 12"
                    ><label class="text-truncate d-block mb-0">{{
                        trans('stakeholders.form.description.label')
                    }}</label></column
                >
                <column>
                    <div v-if="stakeholder.description">
                        {{ stakeholder.description }}
                    </div>
                    <div v-else>{{ trans('general.not_available') }}</div>
                </column>
            </row>
            <row :class="{ 'mb-2': !horizontal }">
                <column>
                    <slot />
                </column>
            </row>
        </collapse>
    </div>
</template>
<script>
import EmailLink from '@/Components/EmailLink.vue';
import PhoneLink from '@/Components/PhoneLink.vue';
import Collapse from '@/Components/Collapse.vue';
import ExclamationMark from '@/../icons/ExclamationMark.vue';
import Row from '@/Components/Row.vue';
import { trans } from '@/mixins/i18n.js';

export default {
    name: 'StakeholderBaseInformation',
    components: { Row, ExclamationMark, Collapse, PhoneLink, EmailLink },
    props: {
        stakeholder: {},
        collapsable: {},
        horizontal: Boolean,
        showExpirationWarning: Boolean,
    },
    data() {
        return {
            open: !this.collapsable,
        };
    },
    computed: {
        expanded() {
            if (!this.collapsable) {
                return true;
            }

            return this.open;
        },
    },
    methods: {
        trans,
        toggleCollapse() {
            if (!this.collapsable) {
                return;
            }
            this.open = !this.open;
        },
    },
};
</script>
