<template>
    <div>
        <div class="d-flex justify-content-center">
            <div class="d-flex align-items-center" style="gap: 5px">
                <em
                    :class="{
                        down: !showDetails,
                        up: showDetails,
                    }"
                    class="icon-caret"
                />
                <a
                    :class="{ disabled: isProcessing }"
                    class="btn-link d-inline-block"
                    href="javascript:void(0)"
                    @click.stop="toggleDetails()"
                >
                    <span
                        :style="{
                            visibility: isProcessing ? 'hidden' : 'visible',
                        }"
                    >
                        {{
                            trans(
                                'dashboard.asset_management.status.details.link',
                            )
                        }}
                    </span>
                    <span
                        v-if="isProcessing"
                        class="position-absolute d-flex justify-content-center align-items-center"
                        style="top: 0; right: 0; bottom: 0; left: 0"
                    >
                        <spinner />
                    </span>
                </a>
            </div>
        </div>
        <collapse :show="showDetails">
            <header
                class="d-sm-flex justify-content-between align-items-center bg-white py-2 px-3 border-top border-color-primary"
                style="min-height: 50px"
            >
                <div
                    v-if="statusByFilter === 'location' && hasMultipleLocations"
                    class="text-truncate mb-2 mb-sm-0"
                >
                    {{
                        trans(
                            'dashboard.asset_management.status.details.group_by_location.title',
                        )
                    }}
                </div>
                <div v-else class="text-truncate mb-2 mb-sm-0">
                    {{
                        trans(
                            'dashboard.asset_management.status.details.group_by_type.title',
                        )
                    }}
                </div>
                <toggle-button
                    v-if="hasMultipleLocations"
                    v-model="statusByFilter"
                    :options="[
                        {
                            label: trans(
                                'dashboard.asset_management.status.details.toggle.location',
                            ),
                            value: 'location',
                        },
                        {
                            label: trans(
                                'dashboard.asset_management.status.details.toggle.asset_type',
                            ),
                            value: 'type',
                        },
                    ]"
                />
            </header>
            <section
                style="
                    border-bottom-left-radius: 10px;
                    border-bottom-right-radius: 10px;
                    overflow: hidden;
                "
            >
                <div
                    v-for="(row, rowIndex) in data"
                    :key="row.name"
                    :class="{
                        'bg-gray-dark': rowIndex % 2 === 0,
                        'bg-white': rowIndex % 2 !== 0,
                    }"
                    class="px-3"
                >
                    <div
                        :class="{ clickable: hasMultipleLocations }"
                        @click.stop="toggleLocation(row)"
                    >
                        <row class="py-2">
                            <column :sm="true" xs="12">{{ row.name }}</column>
                            <column>
                                <operability-indicator
                                    size="sm"
                                    type="operable"
                                >
                                    <div>
                                        {{
                                            row.operableCount || '-'
                                        }}&nbsp;/&nbsp;<span class="text-muted"
                                            >{{
                                                getOperableCountPercentage(
                                                    row,
                                                ) || '-'
                                            }}
                                            %</span
                                        >
                                    </div>
                                </operability-indicator>
                            </column>
                            <column>
                                <div
                                    class="d-flex justify-content-between align-items-center"
                                >
                                    <operability-indicator
                                        size="sm"
                                        type="inoperable"
                                    >
                                        <div>
                                            {{
                                                row.inoperableCount || '-'
                                            }}&nbsp;/&nbsp;
                                            <span class="text-muted"
                                                >{{
                                                    getInoperableCountPercentage(
                                                        row,
                                                    ) || '-'
                                                }}
                                                %</span
                                            >
                                        </div>
                                    </operability-indicator>
                                    <em
                                        v-if="hasMultipleLocations"
                                        :class="{
                                            up: areLocationDetailsVisible(row),
                                            down: !areLocationDetailsVisible(
                                                row,
                                            ),
                                        }"
                                        class="icon-caret"
                                    />
                                </div>
                            </column>
                        </row>
                        <collapse
                            v-if="row.childRows.length > 0"
                            :show="areLocationDetailsVisible(row)"
                        >
                            <div v-for="(childRow, typeIndex) in row.childRows">
                                <div class="border-top" />
                                <row class="py-2">
                                    <column :sm="true" xs="12"
                                        >{{ childRow.name }}
                                    </column>
                                    <column>
                                        {{ childRow.operableCount || '-' }}
                                    </column>
                                    <column>
                                        {{ childRow.inoperableCount || '-' }}
                                    </column>
                                </row>
                            </div>
                        </collapse>
                    </div>
                </div>
            </section>
        </collapse>
    </div>
</template>
<script>
import OperabilityIndicator from '@/Components/OperabilityIndicator.vue';
import JetButton from '@/Jetstream/Button.vue';
import ToggleButton from '@/Components/ToggleButton.vue';

import Collapse from '@/Components/Collapse.vue';

export default {
    name: 'AssetStatusDetails',
    components: {
        Collapse,

        ToggleButton,
        JetButton,
        OperabilityIndicator,
    },
    data() {
        return {
            openLocationDetails: [],
            showDetails: false,
            isProcessing: false,
            statusByFilter: 'location',
        };
    },
    computed: {
        hasMultipleLocations() {
            return (
                this.$page.props.metrics?.groupedAssetStatusByLocation.length >
                1
            );
        },
        data() {
            let details =
                this.$page.props.metrics?.groupedAssetStatusByLocation;

            if (details?.length === 1) {
                return details[0].types.map((type) => ({
                    name: type.type.value,
                    operableCount: type.operableCount,
                    inoperableCount: type.inoperableCount,
                    childRows: [],
                }));
            }

            // Adjust data based on presentation style
            return this.statusByFilter === 'location'
                ? (
                      this.$page.props.metrics?.groupedAssetStatusByLocation ||
                      []
                  ).map((location) => ({
                      name: `${location.name} (${location.iataCode})`,
                      operableCount: location.totalOperableCount,
                      inoperableCount: location.totalInoperableCount,
                      childRows: location.types.map((type) => ({
                          name: type.type.value,
                          operableCount: type.operableCount,
                          inoperableCount: type.inoperableCount,
                      })),
                  })) || []
                : this.showDetailsGroupedByType();
        },
    },
    watch: {
        data: {
            handler: function (newValue) {
                if (newValue?.length === 1) {
                    this.openLocationDetails = newValue[0].name;
                }
            },
            immediate: true,
        },
        statusByFilter: {
            handler: function (newValue) {
                this.openLocationDetails = [];
            },
            immediate: true,
        },
    },
    methods: {
        toggleLocation(location) {
            // Should always be opened if there is only one location
            if (this.data.length === 1) {
                return;
            }

            if (this.openLocationDetails.includes(location.name)) {
                this.openLocationDetails = this.openLocationDetails.filter(
                    (name) => name !== location.name,
                );
            } else {
                this.openLocationDetails.push(location.name);
            }
        },
        areLocationDetailsVisible(location) {
            return this.openLocationDetails.includes(location.name);
        },
        getOperableCountPercentage(row) {
            let total = row.operableCount + row.inoperableCount;

            return Math.round((row.operableCount / total) * 100);
        },
        getInoperableCountPercentage(row) {
            return 100 - this.getOperableCountPercentage(row);
        },
        toggleDetails() {
            if (this.data.length > 0) {
                this.showDetails = !this.showDetails;
            } else {
                this.loadDetails();
            }
        },
        loadDetails() {
            if (this.isProcessing) {
                return;
            }

            this.isProcessing = true;

            this.$inertia.get(
                this.route('dashboard.index'),
                this.route().params,
                {
                    preserveState: true,
                    only: ['groupedAssetStatusByLocation'],
                    onSuccess: () => {
                        this.showDetails = true;
                    },
                    onFinish: () => {
                        this.isProcessing = false;
                    },
                },
            );
        },
        showDetailsGroupedByType() {
            const result = [];
            const typeLocationMap = {};
            let data = this.$page.props.groupedAssetStatusByLocation || [];

            data.forEach((location) => {
                location.types.forEach((type) => {
                    const typeValue = type.type.value;

                    if (!typeLocationMap[typeValue]) {
                        typeLocationMap[typeValue] = [];
                    }

                    typeLocationMap[typeValue].push({
                        name: `${location.name} (${location.iataCode})`,
                        inoperableCount: type.inoperableCount,
                        operableCount: type.operableCount,
                    });
                });
            });

            Object.keys(typeLocationMap).forEach((typeValue) => {
                typeLocationMap[typeValue].sort(
                    (a, b) => b.inoperableCount - a.inoperableCount,
                );

                let sumOperable = typeLocationMap[typeValue].reduce(
                    (a, b) => a + b.operableCount,
                    0,
                );
                let sumInoperable = typeLocationMap[typeValue].reduce(
                    (a, b) => a + b.inoperableCount,
                    0,
                );

                result.push({
                    name: typeValue,
                    operableCount: sumOperable,
                    inoperableCount: sumInoperable,
                    childRows: typeLocationMap[typeValue],
                });
            });

            result.sort((a, b) => {
                const maxInoperableA = Math.max(
                    ...a.childRows.map((loc) => loc.inoperableCount),
                );
                const maxInoperableB = Math.max(
                    ...b.childRows.map((loc) => loc.inoperableCount),
                );
                return maxInoperableB - maxInoperableA;
            });

            return result;
        },
    },
};
</script>
