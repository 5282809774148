<template>
    <collapsable-filter-bar
        :active="form.isDirty"
        :is-processing="form.processing"
        @clear="clear"
    >
        <template #visible-filter="{ isOpen }">
            <search-filter
                :is-processing="form.processing"
                :model-value="form.search"
                :placeholder="trans('activities.overview.filter.placeholder')"
                :show-spinner="!isOpen"
                @update:modelValue="updateValue('search', $event)"
            />
        </template>

        <location-filter :form="form" @change="updateValue" />

        <date-range-filter
            :label="trans('activities.overview.cell.date')"
            :model-value="{ start: form.start, end: form.end }"
            @update:modelValue="updateValue('date', $event)"
        />
        <user-filter
            :model-value="form.users"
            @update:modelValue="updateValue('users', $event)"
        />
        <multi-select-dropdown
            v-if="types.length > 1"
            :label="trans('assets.overview.filter.type')"
            :model-value="form.types"
            :options="types"
            :show-select-all-button="false"
            @update:modelValue="updateValue('types', $event)"
        >
            <template v-slot:label="{ option }">
                <div
                    class="d-flex justify-content-between align-items-center"
                    style="gap: 1rem"
                >
                    <span>{{ option.label }}</span
                    ><small class="text-muted">{{ option.value }}</small>
                </div>
            </template>
        </multi-select-dropdown>
    </collapsable-filter-bar>
</template>
<script>
import MultiSelectDropdown from '@/Components/MultiSelectDropdown.vue';
import CollapsableFilterBar from '@/Components/CollapsableFilterBar.vue';
import LocationFilter from '@/Pages/Dashboard/Components/LocationFilter.vue';
import SearchFilter from '@/Pages/WorkOrders/SearchFilter.vue';
import DateRangeFilter from '@/Components/DateRangeFilter.vue';
import UserFilter from '@/Components/UserFilter.vue';

export default {
    components: {
        UserFilter,
        SearchFilter,
        DateRangeFilter,
        LocationFilter,
        CollapsableFilterBar,
        MultiSelectDropdown,
    },
    props: {
        queryParams: Object,
    },
    emits: ['change'],
    computed: {
        types() {
            return this.$page.props.types?.data || [];
        },
        form() {
            return this.queryParams.form;
        },
    },
    created() {
        window.addEventListener('keyup', this.keyboardListener);
    },

    destroyed() {
        window.removeEventListener('keyup', this.keyboardListener);
    },
    methods: {
        updateValue(key, value) {
            if (key === 'date') {
                this.form.start = value.start;
                this.form.end = value.end;
            } else if (Object.hasOwn(this.form, key)) {
                this.form[key] = value;
            }

            this.queryParams.change();
            this.$emit('change', key);
        },
        clear() {
            this.queryParams.clear();
            this.$emit('change');
        },
    },
};
</script>
