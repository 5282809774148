<template>
    <dialog-modal
        v-if="shouldRender"
        :is-processing="form.processing"
        :primary-button-label="trans('general.action.yes')"
        :show="show"
        :submit-disabled="
            !form.isDirty ||
            (form.operability === 'inoperable' &&
                !form.additionalInformation?.trim())
        "
        @close="closeModal"
        @submit="handleSubmit"
    >
        <template #title>
            <div>
                {{ trans('assets.modal.operability_change.title') }}
            </div>
        </template>

        <div
            v-html="
                trans(
                    `workorder.modal.changes.asses.status.operable.description`,
                    {
                        operability: operabilityLabel,
                        reason: reasonLabel,
                    },
                )
            "
        />
        <form @submit.prevent="handleSubmit">
            <row class="mt-3">
                <column>
                    <jet-text-area
                        v-model="form.additionalInformation"
                        :horizontal="false"
                        :invalidFeedback="form.errors.additionalInformation"
                        :label="
                            trans(
                                'assets.modal.operability_change.additional_information.label',
                            )
                        "
                        :label-classes="{ 'font-weight-bold': true }"
                        :maxlength="80"
                        :placeholder="
                            trans(
                                'assets.modal.operability_change.additional_information.placeholder',
                            )
                        "
                        :required="form.operability === 'inoperable'"
                        :rows="4"
                        name="additional_information"
                    />
                </column>
            </row>
        </form>
        <required-fields />
    </dialog-modal>
</template>
<script>
import DialogModal from '@/Jetstream/DialogModal.vue';
import JetButton from '@/Jetstream/Button.vue';
import JetTextArea from '@/Jetstream/Textarea.vue';
import { eventBus, events } from '@/eventBus';
import { $http } from '@/bootstrap.js';
import { trans } from '@/mixins/i18n.js';
import RequiredFields from '@/Components/RequiredFields.vue';

export default {
    name: 'AssetStatusChangePromptModal',
    components: { RequiredFields, DialogModal, JetButton, JetTextArea },
    data() {
        return {
            show: false,
            shouldRender: false,
            workOrderId: null,
            status: null,
            assetId: null,
            form: this.$inertia.form({
                operability: 'inoperable',
                additionalInformation: '',
                reason: 'corrective_maintenance_required',
            }),
        };
    },
    computed: {
        operabilityLabel() {
            const text = this.trans(
                `assets.modal.operability_change.label.${this.form.operability}`,
            );
            const cssClass =
                this.form.operability === 'inoperable'
                    ? 'bg-danger'
                    : this.form.operability === 'operable'
                      ? 'bg-success'
                      : 'bg-secondary';

            return `<span><em
            class="operability-indicator ${cssClass} ${this.form.operability}"></em>&nbsp;<b>${text}</b></span>`;
        },
        reasonLabel() {
            return this.trans(
                `assets.modal.operability_change.reason.${this.form.reason}`,
            );
        },
    },
    mounted() {
        eventBus.$on(
            events.workOrderStatusUpdated,
            ({ workOrderId, status }) => {
                this.resetForm();
                this.workOrderId = workOrderId;
                this.status = status;

                this.checkWorkOrderStatus();
            },
        );

        eventBus.$on(events.openAssetStatusChangePrompt, (data) => {
            this.resetForm();
            this.assetId = data.assetId;
            this.form.operability = 'inoperable';
            this.form.reason = 'corrective_maintenance';
            this.openModal();
        });
    },
    beforeUnmount() {
        eventBus.$off(events.workOrderStatusUpdated);
        eventBus.$off(events.openAssetStatusChangePrompt);
    },
    methods: {
        trans,
        resetForm() {
            this.form = this.$inertia.form({
                operability: 'inoperable',
                additionalInformation: '',
                reason: 'corrective_maintenance_required',
            });
        },

        handleSubmit() {
            this.form
                .transform(this.transformData)
                .post(this.route('assets.update-operability', this.assetId), {
                    preserveState: true,
                    onSuccess: () => {
                        eventBus.$emit(events.updateNotifications);

                        eventBus.$emit(events.assetOperabilityUpdated, {
                            asset: this.assetId,
                            ...this.form.data(),
                        });

                        this.closeModal();
                    },
                });
        },
        transformData(data) {
            data.additionalInformation = data.additionalInformation || null;

            switch (data.reason) {
                case 'corrective_maintenance':
                    data.reason = 'corrective_maintenance_required';
                    break;
                case 'preventative_maintenance':
                    data.reason = 'preventative_maintenance_in_progress';
                    break;
                default:
                    delete data.reason;
            }

            return data;
        },
        openModal() {
            this.shouldRender = true;
            this.$nextTick(() => (this.show = true));
        },
        closeModal() {
            this.show = false;
            setTimeout(() => {
                this.shouldRender = false;
                this.resetForm();
                this.workOrderId = null;
                this.status = null;
                this.assetId = null;
            }, 300);
        },
        checkWorkOrderStatus() {
            $http
                .get(this.route('workorders.show', this.workOrderId))
                .then((response) => {
                    const workOrder = response.data.workOrder;
                    const asset = workOrder.asset;
                    const operability = asset.status.operability;

                    if (this.status === 'to_do') {
                        return;
                    }

                    switch (`${this.status}:${operability}`) {
                        case 'in_progress:operable':
                        case 'resolved:inoperable':
                        case 'closed:inoperable':
                        case 'done:inoperable':
                            this.form.operability =
                                operability === 'operable'
                                    ? 'inoperable'
                                    : 'operable';
                            this.assetId = asset.id;
                            this.form.reason = workOrder.type.value;
                            this.openModal();
                            break;
                    }
                });
        },
    },
};
</script>
<style scoped>
:deep(textarea) {
    resize: none;
}
</style>
