<template>
    <div>
        <div
            ref="sticky"
            :style="{ top }"
            aria-hidden="true"
            class="sticky-header-wrapper"
        >
            <slot />
        </div>
        <div ref="orig" class="sticky-header-orig">
            <slot />
        </div>
    </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref, useTemplateRef } from 'vue';

const top = ref('0px');
const originalElement = useTemplateRef('orig');
const stickyElement = useTemplateRef('sticky');
let pageHeader;
let appHeader;

const updateStickyHeaderPosition = () => {
    requestAnimationFrame(() => {
        const pageHeaderHeight = pageHeader?.clientHeight || 0;
        const navigationHeight = appHeader?.clientHeight || 0;
        const offsetTop = Math.max(pageHeaderHeight + navigationHeight - 1, 0);
        top.value = `${offsetTop}px`;
    });
};
const onScrollSticky = (event) => {
    let el =
        originalElement.value?.getElementsByClassName('table-responsive')?.[0];

    if (el) {
        el.scrollLeft = event.target.scrollLeft;
    }
};
const onScrollEl = (event) => {
    let el =
        stickyElement.value?.getElementsByClassName('table-responsive')?.[0];

    if (el) {
        el.scrollLeft = event.target.scrollLeft;
    }
};

onMounted(() => {
    pageHeader = document.getElementsByClassName('sticky-header').item(0);
    appHeader = document.getElementById('app-header');

    const el =
        originalElement.value?.getElementsByClassName('table-responsive')?.[0];
    const sticky =
        stickyElement.value?.getElementsByClassName('table-responsive')?.[0];

    el?.addEventListener('scroll', onScrollEl);
    sticky?.addEventListener('scroll', onScrollSticky);
    window.addEventListener('resize', updateStickyHeaderPosition);
    updateStickyHeaderPosition();
});

onUnmounted(() => {
    const el =
        originalElement.value?.getElementsByClassName('table-responsive')?.[0];
    const sticky =
        stickyElement.value?.getElementsByClassName('table-responsive')?.[0];

    el?.removeEventListener('scroll', onScrollSticky);
    sticky?.removeEventListener('scroll', onScrollEl);
    window.removeEventListener('resize', updateStickyHeaderPosition);
});
</script>

<style scoped>
.sticky-header-wrapper {
    position: sticky;
    top: 56px;
    border-top: 4px solid #f5f6f9;
    overflow: hidden;
    height: 44px;
    z-index: 1;
}

@media (max-width: 576px) {
    .sticky-header-wrapper {
        top: 56px !important;
    }
}

.card-body .sticky-header-wrapper {
    border-top-color: #fff;
}

.sticky-header-orig {
    margin-top: -41px;
}
</style>
