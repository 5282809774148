<template>
    <ul v-if="canRead" class="list-unstyled d-flex gap-3">
        <li
            v-for="attachment in visibleAttachments"
            :key="attachment.id"
            class="text-center"
            style="width: 70px"
        >
            <a :href="attachment.uri" target="_blank" @click.stop="">
                <div
                    class="d-inline-flex justify-content-center align-items-center bg-white attachment-cell-item"
                    style=""
                >
                    <image-file-type
                        v-if="attachment.mimeType.startsWith('image')"
                        style="width: 22px"
                    />
                    <pdf-file-type v-else style="width: 22px" />
                </div>
                <div
                    class="text-wrap text-break text-max-2-lines"
                    style="max-width: 100px"
                >
                    {{ attachment.fileName }}
                </div>
            </a>
            <div class="text-small">
                {{ $filters.prettyBytes(attachment.size) }}
            </div>
        </li>
        <li v-if="attachments.length > maxVisibleItems">
            <a href="javascript:void(0)" @click.stop="showAttachmentsOverlay">
                <div
                    class="attachment-cell-item text-nowrap bg-white d-inline-block p-1 clickable"
                >
                    + {{ hiddenAttachmentsCount }}
                    <icon name="cil-paperclip" />
                </div>
            </a>
        </li>
    </ul>
</template>
<script setup>
import ImageFileType from '../../icons/ImageFileType.vue';
import PdfFileType from '../../icons/PdfFileType.vue';
import { computed } from 'vue';
import { eventBus, events } from '@/eventBus.js';

const maxVisibleItems = 3;
const props = defineProps({
    attachments: Array,
    canRead: {
        type: Boolean,
        default: true,
    },
});

const visibleAttachments = computed(() => {
    return props.attachments.slice(0, maxVisibleItems);
});

const hiddenAttachmentsCount = computed(() => {
    return props.attachments.length - maxVisibleItems;
});

const showAttachmentsOverlay = (event) => {
    event.preventDefault();
    eventBus.$emit(events.openAttachmentListModal, {
        attachments: props.attachments,
    });
};
</script>
