<template>
    <div style="min-height: 44px">
        <pagination :paginator="items" :query-params="queryParams" />
    </div>
    <table class="table table-striped table-hover table-bordered border">
        <tbody>
            <tr v-for="n in 10" v-if="!items" :key="n" style="cursor: default">
                <td class="align-middle">
                    <skeleton-loader />
                </td>
            </tr>
            <tr v-if="items?.data.length === 0">
                <td>
                    <div class="text-center my-5">
                        <h2
                            class="d-flex align-items-center justify-content-center"
                            style="gap: 5px"
                        >
                            {{ trans('assets.overview.results.empty') }}
                            <icon
                                class="text-danger"
                                icon="cil-ban"
                                style="width: 30px; height: 30px"
                            />
                        </h2>
                    </div>
                </td>
            </tr>
            <tr v-for="item in items?.data" v-else :key="item.id">
                <td>
                    <div @click.stop="$emit('row-clicked', item)">
                        <div class="d-flex justify-content-between w-100">
                            <slot :item="item" name="header-row">
                                <status-badge
                                    v-if="item.status"
                                    :operability="item.status.operability"
                                    :usage="item.status.usageCategory"
                                />
                                <div
                                    v-if="item.status"
                                    class="d-flex"
                                    style="gap: 1rem"
                                    @click.stop
                                >
                                    <Link
                                        v-if="
                                            item.status?.workOrdersInProgress >
                                            0
                                        "
                                        :href="
                                            route(
                                                'assets.workorders.index',
                                                item.id,
                                            )
                                        "
                                        @click.stop=""
                                    >
                                        <work-order-badge
                                            v-c-tooltip="{
                                                content: trans(
                                                    'assets.overview.tooltip.work_orders_in_progress',
                                                    {
                                                        '%d': item.status
                                                            .workOrdersInProgress,
                                                    },
                                                ),
                                            }"
                                            :count="
                                                item.status.workOrdersInProgress
                                            "
                                        />
                                    </Link>
                                    <popover
                                        v-if="item.issues?.length > 0"
                                        placement="left"
                                    >
                                        <template #toggler="{ id, on }">
                                            <badge
                                                class="issue-count"
                                                color="danger"
                                                v-on="on"
                                                >{{ item.issues?.length }}
                                            </badge>
                                        </template>
                                        <div>
                                            <b>{{
                                                trans(
                                                    'assets.overview.cell.issues',
                                                )
                                            }}</b>
                                        </div>
                                        <div v-for="issue in item.issues">
                                            <issue-badge
                                                :issue="issue"
                                                class="mt-1"
                                            />
                                        </div>
                                    </popover>
                                </div>
                            </slot>
                        </div>
                        <table class="item-row-details-table mt-2">
                            <tbody>
                                <tr>
                                    <th class="align-top">
                                        <small class="text-muted">{{
                                            trans(
                                                'assets.overview.cell.designation.short',
                                            )
                                        }}</small>
                                    </th>
                                    <td>
                                        <div class="p-start">
                                            <div>
                                                <b>{{ item.designation }}</b>
                                            </div>
                                            {{
                                                trans(
                                                    'assets.overview.cell.serial_number',
                                                )
                                            }}:
                                            {{ item.serialNumber }}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th class="align-middle">
                                        <small class="text-muted">{{
                                            trans(
                                                'assets.overview.cell.type.short',
                                            )
                                        }}</small>
                                    </th>
                                    <td>
                                        <div class="p-start">
                                            {{ item.type }}
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th class="align-top">
                                        <small class="text-muted">{{
                                            trans(
                                                'assets.overview.cell.location.short',
                                            )
                                        }}</small>
                                    </th>
                                    <td>
                                        <div class="p-start">
                                            <div v-if="shouldShowAirport">
                                                {{ item.location.name }} ({{
                                                    item.location.iataCode
                                                }})
                                            </div>
                                            <div>
                                                {{
                                                    item.location
                                                        .specificLocation
                                                }}
                                            </div>
                                            <div v-if="item.location.area">
                                                {{ item.location.area }}
                                            </div>
                                            <div v-if="item.location.subArea">
                                                {{ item.location.subArea }}
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr v-if="shouldShowManufacturer">
                                    <th class="align-top">
                                        <small class="text-muted">{{
                                            trans(
                                                'assets.overview.cell.manufacturer.short',
                                            )
                                        }}</small>
                                    </th>
                                    <td>
                                        <div class="p-start">
                                            {{ item.manufacturer }}
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</template>
<script setup>
import { Link } from '@inertiajs/vue3';
import StatusBadge from '@/Components/StatusBadge.vue';
import WorkOrderBadge from '@/Components/WorkorderBadge.vue';
import Badge from '@/Components/Badge.vue';
import IssueBadge from '@/Components/IssueBadge.vue';
import { computed } from 'vue';
import Pagination from '@/Components/Pagination.vue';
import { trans } from '../mixins/i18n.js';
import SkeletonLoader from '@/Components/SkeletonLoader.vue';
import Popover from '@/Components/Popover.vue';

const props = defineProps({
    items: [Object, null],
    queryParams: Object,
    fields: Array,
});

const emits = defineEmits(['row-clicked']);

const shouldShowAirport = computed(() => {
    return props.fields.find((field) => field.key === 'location');
});

const shouldShowManufacturer = computed(() => {
    return props.fields.find((field) => field.key === 'manufacturer');
});
</script>
<style scoped>
.item-row-details-table {
    border-spacing: 0 0.25rem;
    border-collapse: separate;
}

.item-row-details-table th {
    font-weight: 400;
    line-height: 1.2rem;
    border: none;
}

.item-row-details-table td {
    line-height: 1.2rem;
}

.p-start {
    padding-left: 1.2rem;
}

[dir='rtl'] .p-start {
    padding-right: 1.2rem;
}

.issue-count {
    min-width: 20px;
    line-height: 20px;
    padding: 0;
    cursor: pointer;
}

.sticky-header {
    position: sticky;
    top: 55px;
    border-top: 16px solid #eaedef !important;
    z-index: 1;
}
</style>
