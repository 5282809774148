<template>
    <dialog-modal
        v-if="
            shouldRender &&
            hasFeature('test-record') &&
            hasAnyPermission('test.create')
        "
        :cancel-disabled="uploadInProgress"
        :is-processing="form.processing"
        :show="show"
        :submit-disabled="
            !isFormSubmittable || !form.resolution || uploadInProgress
        "
        @close="closeModal"
        @submit="submitForm"
    >
        <template #title>
            {{ trans('test_record.modal.header') }}
        </template>
        <template #subtitle>
            {{ trans('test_record.modal.description') }}
        </template>

        <alert v-if="qrCodeScanned === false" color="warning"
            >{{ trans('test_records.qr_code_not_scanned.warning') }}
        </alert>
        <form @submit.prevent="submitForm">
            <row class="py-4">
                <column class="d-flex justify-content-center" style="gap: 1rem">
                    <div
                        :style="{
                            opacity: form.resolution === 'fail' ? 0.3 : 1,
                        }"
                    >
                        <button
                            :class="{
                                'btn--selected': form.resolution === 'success',
                            }"
                            class="btn btn-icon btn-icon-lg btn-success"
                            @click.prevent="reportSuccess()"
                        >
                            <icon icon="cil-check" size="xl" />
                        </button>
                        <div>
                            <h4 class="m-0 text-center">
                                {{
                                    trans(
                                        'test_record.form.success.button.label',
                                    )
                                }}
                            </h4>
                        </div>
                    </div>
                    <div
                        :style="{
                            opacity: form.resolution === 'success' ? 0.3 : 1,
                        }"
                    >
                        <button
                            :class="{
                                'btn--selected': form.resolution === 'fail',
                            }"
                            class="btn btn-icon btn-icon-lg btn-danger"
                            @click.prevent="reportError()"
                        >
                            <icon
                                icon="cil-medical-cross"
                                style="transform: rotate(45deg)"
                            />
                        </button>
                        <div>
                            <h4 class="m-0 text-center">
                                {{
                                    trans('test_record.form.fail.button.label')
                                }}
                            </h4>
                        </div>
                    </div>
                </column>
            </row>
            <row v-if="form.errors.resolution">
                <column>
                    <div v-for="(message, key) in form.errors" :key="key">
                        <small class="text-danger">{{ message }}</small>
                    </div>
                </column>
            </row>
            <row class="mt-3">
                <column>
                    <jet-textarea
                        v-model="form.description"
                        :horizontal="false"
                        :invalidFeedback="form.errors.description"
                        :isValid="!form.errors.description"
                        :label="trans('test_record.form.report.label')"
                        :placeholder="
                            trans('test_record.form.report.placeholder')
                        "
                        :required="form.resolution === 'fail'"
                        :rows="5"
                        name="test_report"
                    />
                    <div class="mb-4 w-100 d-flex justify-content-start">
                        <small
                            ><em class="text-danger">*</em>
                            {{ trans('general.required_fields') }}</small
                        >
                    </div>
                </column>
            </row>
            <row
                v-if="hasAnyPermission(['test.attachment.create'])"
                class="mt-3"
            >
                <column>
                    <h5>
                        {{ trans('test_record.form.fileupload.label') }}
                    </h5>
                    <file-upload
                        :key="componentKey"
                        v-model="form.attachments"
                        @upload-started="uploadInProgress = true"
                        @upload-completed="uploadInProgress = false"
                        @upload-failed="uploadInProgress = false"
                    />
                </column>
            </row>
        </form>
    </dialog-modal>
</template>
<script>
import DialogModal from '@/Jetstream/DialogModal.vue';
import JetButton from '@/Jetstream/Button.vue';
import JetTextarea from '@/Jetstream/Textarea.vue';
import FileUpload from '@/Components/FileUpload.vue';
import { eventBus, events } from '@/eventBus.js';
import Alert from '@/Components/Alert.vue';
import { $http } from '@/bootstrap.js';

export default {
    props: ['asset'],

    components: {
        Alert,
        DialogModal,
        JetButton,
        JetTextarea,
        FileUpload,
    },

    data() {
        return {
            show: false,
            shouldRender: false,
            componentKey: 0,
            uploadInProgress: false,
            qrCodeScanned: undefined,
            form: this.$inertia.form({
                resolution: undefined,
                description: '',
                attachments: [],
            }),
        };
    },

    mounted() {
        eventBus.$on([events.openTestRecordsModal], (data) => {
            this.qrCodeScanned = undefined;
            this.uploadInProgress = false;

            this.form = this.$inertia.form({
                resolution: undefined,
                description: '',
                attachments: [],
            });

            this.shouldRender = true;
            this.componentKey += 1;

            // wait for the check to finish to avoid UI updates
            this.requestQrCodeScannedStatus().finally(() => {
                this.$nextTick(() => (this.show = true));
            });
        });
    },

    beforeUnmount() {
        eventBus.$off([events.openTestRecordsModal]);
    },

    methods: {
        submitForm() {
            if (!this.isFormSubmittable) {
                return;
            }

            this.form.post(this.route('assets.tests.store', this.asset.id), {
                preserveScroll: true,
                onSuccess: () => {
                    this.closeModal();
                    eventBus.$emit(events.updateNotifications);

                    this.$emit('created', {
                        assetId: this.asset.id,
                        ...this.form.data(),
                    });
                },
            });
        },
        onError() {
            this.$emit('error');
        },
        reportSuccess() {
            this.form.resolution = 'success';
        },
        reportError() {
            this.form.resolution = 'fail';
        },
        closeModal() {
            this.show = false;

            setTimeout(() => {
                this.form.reset();
                this.$emit('close');
            }, 300);
        },
        requestQrCodeScannedStatus() {
            return $http
                .get(this.route('assets.tests.create', this.asset.id))
                .then(
                    (resp) =>
                        (this.qrCodeScanned =
                            resp.data.qr_code_scanned || false),
                );
        },
    },
};
</script>
<style lang="scss" scoped>
.btn-round {
    border-radius: 50%;
    width: 75px;
    height: 75px;
    box-shadow: none;
    border: 4px solid #2eb85c;
    background: #fff;
    color: #2eb85c;
    outline: none;
    opacity: 0.25;

    .c-icon {
        width: 3rem !important;
        height: 3rem !important;
    }

    &.btn-round--red {
        border-color: #e55353;
        color: #e55353;
    }

    &.btn--selected {
        opacity: 1;
    }
}
</style>
