<template>
    <div>
        <table class="table table-sm table-unstyled">
            <tbody>
                <tr>
                    <th>{{ trans('general.report.label') }}:</th>
                    <td class="text-nowrap">
                        <span v-if="report.type === 'daily'">{{
                            trans('cadence.unit.day.option')
                        }}</span>
                        <span v-if="report.type === 'weekly'">{{
                            trans('cadence.unit.week.option')
                        }}</span>
                    </td>
                </tr>
                <tr v-if="report.type === 'daily'">
                    <th class="text-nowrap">
                        {{ trans('general.report.date.label') }}:
                    </th>
                    <td class="text-nowrap">
                        {{
                            $filters.fullDate(
                                report.reportedAt || report.endDate,
                                currentLocale,
                            )
                        }}
                    </td>
                </tr>
                <tr v-if="report.type === 'daily'">
                    <th class="text-nowrap">
                        {{ trans('general.report.time.label') }}::
                    </th>
                    <td class="text-nowrap">
                        <popover style="width: 200px">
                            <template #toggler="{ on }">
                                <a href="javascript:void(0)" v-on="on"
                                    ><span v-if="report.reportedAt">{{
                                        $filters.time(
                                            report.reportedAt,
                                            currentLocale,
                                        )
                                    }}</span
                                    ><span v-else>{{
                                        trans('general.not_available')
                                    }}</span></a
                                >
                            </template>
                            {{
                                trans(
                                    'inoperable_assets.overview.report_time.description',
                                )
                            }}
                        </popover>
                    </td>
                </tr>
                <tr v-if="report.type === 'daily'">
                    <th class="text-nowrap">
                        {{ trans('inoperable_assets.overview.count') }}:
                    </th>
                    <td class="text-nowrap">
                        <span v-if="report.inoperableAssets?.data.length > 0">
                            {{ report.inoperableAssets.meta.total || 0 }}
                            of {{ totalAssetsCount }}
                        </span>
                        <span v-else>{{ trans('general.not_available') }}</span>
                    </td>
                </tr>
                <tr v-if="report.type === 'weekly'">
                    <th class="text-nowrap">
                        {{ trans('general.timeframe.label') }}:
                    </th>
                    <td class="text-nowrap">
                        {{ $filters.fullDate(report.startDate, currentLocale) }}
                        -
                        {{ $filters.fullDate(report.endDate, currentLocale) }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import Popover from '@/Components/Popover.vue';
import { trans } from '../../mixins/i18n.js';

export default {
    name: 'report-meta-info',
    methods: { trans },
    components: { Popover },
    props: {
        report: {},
        totalAssetsCount: {},
    },
};
</script>
<style scoped>
.table-unstyled td {
    padding: 0 3rem !important;
}
</style>
