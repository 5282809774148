<template>
    <jet-form-section @submitted="updateLogo">
        <template #title>
            {{ trans('settings.logo.header') }}
        </template>

        <template #description>
            {{ trans('settings.logo.description') }}
        </template>

        <template #form>
            <div class="form-group form-row">
                <div class="col">
                    <input
                        ref="big_logo"
                        class="d-none"
                        type="file"
                        @change="updateBigLogoPreview"
                    />

                    <div class="col">
                        <div v-if="bigLogoPreview" class="mt-2">
                            <img
                                :src="bigLogoPreview"
                                alt="Big Logo Preview"
                                style="height: 80px"
                            />
                        </div>
                        <div v-else class="mt-2">
                            <img
                                :src="settings.big_logo_url"
                                alt="Big Logo"
                                style="height: 80px"
                            />
                        </div>
                        <div>
                            <small>{{
                                trans('settings.logo.big.description')
                            }}</small>
                        </div>

                        <jet-button
                            class="mt-2 mr-2"
                            color="secondary"
                            type="button"
                            @click.prevent="selectNewBigLogo"
                        >
                            {{ trans('settings.logo.action.select_logo') }}
                        </jet-button>

                        <jet-button
                            v-if="
                                settings.big_logo_url !== '/images/big_logo.jpg'
                            "
                            class="mt-2"
                            color="danger"
                            type="button"
                            @click.prevent="resetBigLogo"
                        >
                            {{ trans('settings.logo.action.reset_logo') }}
                        </jet-button>
                        <jet-input-error
                            :message="$page.props.errors.big_logo_file"
                            class="mt-2"
                        />
                    </div>
                </div>
            </div>
        </template>

        <template #actions>
            <jet-action-message :on="form.recentlySuccessful" class="mr-3">
                {{ trans('general.message.saved') }}
            </jet-action-message>

            <jet-button :disabled="!isFormSubmittable" color="primary"
                >{{ trans('general.action.save') }}
            </jet-button>
        </template>
    </jet-form-section>
</template>

<script>
import JetButton from '@/Jetstream/Button.vue';
import JetFormSection from '@/Jetstream/FormSection.vue';
import JetInput from '@/Jetstream/Input.vue';
import JetInputError from '@/Jetstream/InputError.vue';
import JetLabel from '@/Jetstream/Label.vue';
import JetActionMessage from '@/Jetstream/ActionMessage.vue';

export default {
    components: {
        JetActionMessage,
        JetButton,
        JetFormSection,
        JetInput,
        JetInputError,
        JetLabel,
    },

    data() {
        return {
            form: this.$inertia.form(
                {
                    _method: 'PUT',
                    big_logo_file: null,
                },
                { errorBag: 'updateLogo' },
            ),

            bigLogoPreview: null,
        };
    },

    computed: {
        settings() {
            return this.$page.props.settings;
        },
    },

    mounted() {
        this.form = this.$inertia.form({
            _method: 'PUT',
            big_logo_file: null,
        });
    },

    methods: {
        updateLogo() {
            this.form
                .transform((data) => {
                    if (!data.big_logo_file) {
                        delete data.big_logo_file;
                    }

                    return data;
                })
                .post(route('settings.update'), {
                    preserveScroll: true,
                    onSuccess: () => this.clear(),
                });
        },

        selectNewBigLogo() {
            this.$refs.big_logo.click();
        },

        updateBigLogoPreview() {
            const logo = this.$refs.big_logo.files[0];

            if (!logo) {
                return;
            }

            this.form.big_logo_file = this.$refs.big_logo.files[0];

            const reader = new FileReader();
            reader.onload = (e) => {
                this.bigLogoPreview = e.target.result;
            };

            reader.readAsDataURL(logo);
        },

        resetBigLogo() {
            this.$inertia.put(
                route('settings.update'),
                { logo_big_url: '' },
                {
                    preserveScroll: true,
                    onSuccess: () => this.clear(),
                },
            );
        },

        clear() {
            this.bigLogoPreview = null;

            if (this.$refs.big_logo?.value) {
                this.$refs.big_logo.value = null;
            }

            this.form.reset();
        },
    },
};
</script>
