<template>
    <span :key="state">
        <arrow-down
            v-c-tooltip="tooltip"
            :class="`sort-order-${state}`"
            class="sort-order flex-shrink-0 flex-grow-0"
        />
    </span>
</template>
<script>
import ArrowDown from '../../../icons/ArrowDown.vue';

export default {
    name: 'sort-icon',
    components: { ArrowDown },
    props: ['state'],
    computed: {
        tooltip() {
            let key = this.state || 'none';
            return this.trans(`general.sortorder.${key}`);
        },
    },
};
</script>
