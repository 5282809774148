<template>
    <dialog-modal
        v-if="
            shouldRender &&
            hasAnyPermission(['operator.create', 'operator.update'])
        "
        :closeable="false"
        :is-processing="form.processing"
        :primary-button-label="
            !operator
                ? trans('general.action.add')
                : trans('general.action.save')
        "
        :show="show"
        :submit-disabled="!form.isDirty"
        @close="close"
        @submit="submitForm"
    >
        <template #title>{{ title }}</template>

        <form @submit.prevent="submitForm">
            <jet-input
                v-model="form.name"
                :autofocus="true"
                :horizontal="{ label: 'col-sm-4', input: 'col-sm-8' }"
                :invalidFeedback="form.errors.name"
                :label="trans('operators.form.name.label')"
                :placeholder="trans('operators.form.name.placeholder')"
                :required="true"
            />
            <row>
                <column sm="4"
                    >{{ trans('operators.form.contact_details.label') }}
                </column>
                <column sm="8">
                    <jet-input
                        v-model="form.email"
                        :horizontal="false"
                        :invalidFeedback="form.errors.email"
                        :label="trans('stakeholders.form.email.label')"
                        :placeholder="
                            trans('stakeholders.form.email.placeholder')
                        "
                        :required="true"
                    />
                    <jet-input
                        v-model="form.description"
                        :horizontal="false"
                        :invalidFeedback="form.errors.description"
                        :label="trans('stakeholders.form.description.label')"
                        :placeholder="
                            trans('stakeholders.form.description.placeholder')
                        "
                        class="mb-0"
                    />
                </column>
            </row>
        </form>
        <required-fields />
    </dialog-modal>
</template>

<script>
import JetInput from '@/Jetstream/Input.vue';
import JetButton from '@/Jetstream/Button.vue';
import JetSelect from '@/Jetstream/Select.vue';
import DialogModal from '@/Jetstream/DialogModal.vue';
import FileUpload from '@/Components/FileUpload.vue';
import { eventBus, events } from '@/eventBus.js';
import RequiredFields from '@/Components/RequiredFields.vue';
import { $http } from '@/bootstrap.js';

export default {
    name: 'UpdateOrCreateOperatorFormModal',
    emits: ['created', 'updated'],
    components: {
        RequiredFields,
        FileUpload,
        DialogModal,
        JetButton,
        JetInput,
        JetSelect,
    },
    data() {
        return {
            shouldRender: false,
            show: false,
            operator: null,
            form: this.$inertia.form({
                name: '',
                email: '',
                description: '',
            }),
        };
    },
    mounted() {
        eventBus.$on(
            [events.openCreateOperatorModal, events.openEditOperatorModal],
            (data) => {
                this.operator = data?.operator || null;
                this.reset();

                if (this.operator) {
                    this.loadOperatorInformation();
                }

                this.shouldRender = true;
                this.$nextTick(() => {
                    this.show = true;
                });
            },
        );
    },
    beforeUnmount() {
        eventBus.$off([
            events.openCreateOperatorModal,
            events.openEditOperatorModal,
        ]);
    },
    computed: {
        title() {
            return !!this.operator
                ? this.trans('operators.edit.title')
                : this.trans('operators.create.title');
        },
    },
    methods: {
        submitForm() {
            let route = this.operator
                ? this.route('operators.update', {
                      operator: this.operator,
                  })
                : this.route('operators.store');

            this.form
                .transform((data) => {
                    if (this.operator) {
                        data._method = 'PUT';
                    }
                    return data;
                })
                .post(route, {
                    preserveScroll: true,
                    onSuccess: () => {
                        this.close();

                        this.$emit(
                            this.operator ? 'updated' : 'created',
                            this.operator,
                        );
                    },
                });
        },
        close() {
            this.show = false;
            setTimeout(() => {
                this.shouldRender = false;
            }, 300);
        },
        reset() {
            this.form = this.$inertia.form({
                name: '',
                email: '',
                description: '',
            });
        },
        loadOperatorInformation() {
            $http
                .get(this.route('operators.edit', this.operator))
                .then((response) => {
                    this.operator = response.data.operator;
                    this.form = this.$inertia.form({
                        name: this.operator?.name || '',
                        email: this.operator?.email || '',
                        description: this.operator?.description || '',
                    });
                });
        },
    },
};
</script>
