<template>
    <card>
        <template #header>
            <custom-card-header
                :href="$route('assets.configurations.create', asset.id)"
                :show-action="hasAnyPermission('configuration.create')"
                :title="trans('assets.configurations.overview.header')"
                action-icon="cil-plus"
                icon="cil-layers"
            />
        </template>
        <div v-if="!configurations.data || configurations.data.length == 0">
            <h3>
                {{ trans('assets.configurations.overview.empty.headline') }}
            </h3>
            <p>
                {{ trans('assets.configurations.overview.empty.description') }}
            </p>
        </div>
        <div v-else>
            <card
                v-for="(config, index) in configurations.data"
                :key="config.id"
                :class="{ selected: isSelected(config) }"
                :show="true"
                card-classes="mb-2"
                header-classes="bg-white"
            >
                <template #header>
                    <div
                        class="w-100 d-flex justify-content-between align-items-center clickable"
                        style="gap: 10px"
                        @click="selectConfig(config)"
                    >
                        <div class="min-w-0 flex-shrink-1">
                            <h5 class="mb-0">
                                {{
                                    trans(
                                        'assets.configurations.overview.header.configuration',
                                    )
                                }}
                                {{ config.version }}
                            </h5>
                            <small class="d-block text-muted text-truncate">{{
                                activationPeriod(config, index)
                            }}</small>
                        </div>
                        <div
                            v-if="hasAnyPermission('configuration.update')"
                            class="d-flex flex-shrink-0"
                            style="gap: 5px"
                        >
                            <Link
                                v-if="hasAnyPermission('configuration.update')"
                                :href="
                                    route('assets.configurations.edit', [
                                        asset.id,
                                        config.id,
                                    ])
                                "
                                class="btn btn-primary btn-icon"
                            >
                                <icon icon="cil-pencil" />
                            </Link>
                            <jet-button
                                v-if="hasAnyPermission('configuration.delete')"
                                :is-processing="isDeleting(config)"
                                class="btn-icon"
                                color="danger"
                                @click.stop="deleteConfiguration(config)"
                            >
                                <icon icon="cil-trash" />
                            </jet-button>
                            <div
                                v-if="configurations.data.length > 1"
                                class="d-flex align-items-center justify-content-end flex-shrink-0"
                                style="min-width: 23px"
                            >
                                <em
                                    :class="{
                                        down: !isSelected(config),
                                        up: isSelected(config),
                                    }"
                                    class="icon-caret"
                                    style="border-width: 8px"
                                />
                            </div>
                        </div>
                    </div>
                </template>
                <collapse :show="isSelected(config)">
                    <dl>
                        <div class="my-1 row">
                            <dt class="col-md-5 text-uppercase">
                                {{
                                    trans(
                                        'assets.configurations.overview.label.hardware_version',
                                    )
                                }}
                            </dt>
                            <dd class="col-md-7 text-muted">
                                <span v-if="!!config.hardware_version">{{
                                    config.hardware_version.name
                                }}</span>
                                <span v-else>{{
                                    trans('general.not_available')
                                }}</span>
                            </dd>
                        </div>
                        <div class="my-1 row">
                            <dt class="col-md-5 text-uppercase">
                                {{
                                    trans(
                                        'assets.configurations.overview.label.software_version',
                                    )
                                }}
                            </dt>
                            <dd class="col-md-7 text-muted">
                                <span v-if="!!config.software_version">{{
                                    config.software_version.name
                                }}</span>
                                <span v-else>{{
                                    trans('general.not_available')
                                }}</span>
                            </dd>
                        </div>
                        <div class="my-1 row">
                            <dt class="col-md-5 text-uppercase">
                                {{
                                    trans(
                                        'assets.configurations.overview.label.conops',
                                    )
                                }}
                            </dt>
                            <dd class="col-md-7 text-muted">
                                <span v-if="!!config.conops">{{
                                    config.conops
                                }}</span>
                                <span v-else>{{
                                    trans('general.not_available')
                                }}</span>
                            </dd>
                        </div>
                        <div class="my-1 row">
                            <dt class="col-md-5 text-uppercase">
                                {{
                                    trans(
                                        'assets.configurations.overview.label.standards_obtained',
                                    )
                                }}
                            </dt>
                            <dd class="col-md-7 text-muted">
                                <span v-if="!!config.standards">{{
                                    config.standards.join(', ')
                                }}</span>
                                <span v-else>{{
                                    trans('general.not_available')
                                }}</span>
                            </dd>
                        </div>
                        <div class="my-1 row">
                            <dt class="col-md-5 text-uppercase">
                                {{
                                    trans(
                                        'assets.configurations.overview.label.detection_algorithm',
                                    )
                                }}
                            </dt>
                            <dd class="col-md-7 text-muted">
                                <div
                                    v-if="
                                        config.detection_algorithms.length > 0
                                    "
                                >
                                    <div
                                        v-for="algorithm in config.detection_algorithms"
                                        :key="algorithm.id"
                                    >
                                        {{ algorithm.name }}
                                    </div>
                                </div>
                                <div v-else>
                                    {{ trans('general.not_available') }}
                                </div>
                            </dd>
                        </div>
                        <div class="my-1 row">
                            <dt class="col-md-5 text-uppercase">
                                {{
                                    trans(
                                        'assets.configurations.overview.label.auxiliary_hardware',
                                    )
                                }}
                            </dt>
                            <dd class="col-md-7 text-muted">
                                <div
                                    v-if="config.auxiliary_hardware.length > 0"
                                >
                                    <div
                                        v-for="hardware in config.auxiliary_hardware"
                                        :key="hardware.id"
                                    >
                                        {{ hardware.name }}
                                        <small
                                            v-if="hardware.serial_number"
                                            class="text-muted"
                                            >({{
                                                hardware.serial_number
                                            }})</small
                                        >
                                    </div>
                                </div>
                                <div v-else>
                                    {{ trans('general.not_available') }}
                                </div>
                            </dd>
                        </div>
                        <div class="my-1 row">
                            <dt class="col-md-5 text-uppercase">
                                {{
                                    trans(
                                        'assets.configurations.overview.label.qualifications',
                                    )
                                }}
                            </dt>
                            <dd class="col-md-7 text-muted">
                                <span v-if="!!config.qualifications"
                                    >({{ config.qualifications.length }})</span
                                >
                                <span v-else>{{
                                    trans('general.not_available')
                                }}</span>
                            </dd>
                        </div>
                    </dl>
                    <alert
                        v-for="qualification in config.qualifications"
                        :key="qualification"
                        class="mb-2"
                        color="success"
                    >
                        <div
                            class="d-flex justify-content-between align-items-center"
                        >
                            {{ qualification }}
                            <icon icon="cil-check-circle" />
                        </div>
                    </alert>
                    <div
                        v-if="
                            hasAnyPermission('configuration.activate') &&
                            index !== 0
                        "
                        class="d-flex justify-content-between mt-3 py-2"
                    >
                        <div>
                            <jet-button
                                :is-processing="isActivating"
                                color="success"
                                @click.stop="activateConfiguration(config)"
                            >
                                {{ trans('general.action.activate') }}
                            </jet-button>
                        </div>
                    </div>
                </collapse>
            </card>
        </div>
    </card>
</template>
<script>
import CustomCardHeader from '@/Components/CustomCardHeader.vue';
import JetButton from '@/Jetstream/Button.vue';
import Card from '@/Components/Card.vue';
import Collapse from '@/Components/Collapse.vue';
import Icon from '@/Components/Icon.vue';
import Alert from '@/Components/Alert.vue';
import ArrowDown from '../../icons/ArrowDown.vue';

export default {
    props: ['asset', 'configurations', 'qualifications'],

    data() {
        return {
            selectedConfig: null,
            isActivating: false,
            deletingConfigIds: [],
        };
    },

    components: {
        ArrowDown,
        Alert,
        Icon,
        Collapse,
        Card,
        CustomCardHeader,
        JetButton,
    },

    methods: {
        isSelected(config) {
            return this.selectedConfig === config.id;
        },

        activationPeriod(config, index) {
            const date = this.$filters.dateTimeMedium(
                config.activated_at,
                this.currentLocale,
            );

            if (index === 0) {
                return (
                    this.trans(
                        'assets.configurations.overview.label.active_since',
                    ) +
                    ': ' +
                    date
                );
            }

            let prevDate = this.configurations.data[index - 1];

            if (prevDate) {
                prevDate = this.$filters.dateTimeMedium(
                    prevDate.activated_at,
                    this.currentLocale,
                );

                return (
                    this.trans('assets.configurations.overview.label.active') +
                    ': ' +
                    date +
                    '-' +
                    prevDate
                );
            }
        },

        getQualificationColor(config, qualification) {
            if (
                config.qualifications &&
                config.qualifications.includes(qualification)
            ) {
                return 'success';
            }

            return 'danger';
        },

        getQualificationUrl(qualification) {
            return 'https://letmegooglethat.com/';
        },

        deleteConfiguration(configuration) {
            this.deletingConfigIds.push(configuration.id);
            this.$inertia.delete(
                route('assets.configurations.destroy', [
                    this.asset.id,
                    configuration.id,
                ]),
                {
                    onFinish: () => {
                        this.deletingConfigIds = this.deletingConfigIds.filter(
                            (id) => id !== configuration.id,
                        );
                    },
                },
            );
        },

        activateConfiguration(configuration) {
            this.isActivating = true;
            this.$inertia.post(
                route(
                    'assets.configurations.activate',
                    [this.asset.id, configuration.id],
                    {
                        onFinish: () => {
                            this.isActivating = false;
                        },
                    },
                ),
            );
        },

        isDeleting(config) {
            return this.deletingConfigIds.includes(config.id);
        },

        selectConfig(config) {
            this.selectedConfig = config.id;
        },
    },

    mounted() {
        if (this.configurations && this.configurations.data.length) {
            this.selectedConfig = this.configurations.data[0].id;
        }
    },
};
</script>
<style scoped>
.cursor-pointer {
    cursor: pointer;
}

:deep(.card-body .card-header:first-child) {
    border-radius: 1rem;
    border: none;
}
</style>
