<template>
    <auth-layout>
        <container fluid>
            <row>
                <column>
                    <alert
                        v-if="status"
                        color="success"
                        style="position: relative; top: 100px"
                        v-html="status"
                    />
                </column>
            </row>
        </container>
        <container class="align-items-center min-vh-100">
            <row class="min-vh-100 justify-content-center align-items-center">
                <column lg="8" md="10">
                    <card class="p-4 m-0">
                        <c-form @submit.prevent="submit">
                            <h1>{{ trans('auth.forgot_password.header') }}</h1>
                            <p class="text-muted">
                                {{ trans('auth.forgot_password.description') }}
                            </p>
                            <jet-input
                                id="email"
                                v-model="form.email"
                                :horizontal="false"
                                :isValid="!hasErrors ? null : false"
                                :placeholder="trans('auth.general.email')"
                                autocomplete="username email"
                                autofocus
                                class="mb-0"
                                required
                            >
                                <template #prepend>
                                    <icon icon="cil-user" />
                                </template>
                            </jet-input>
                            <row class="mb-4">
                                <column>
                                    <jet-validation-errors />
                                </column>
                            </row>
                            <div
                                class="d-sm-flex justify-content-end align-items-center text-center"
                                style="flex-wrap: wrap-reverse"
                            >
                                <jet-button
                                    :disabled="!form.email"
                                    :is-processing="form.processing"
                                    class="px-4"
                                    color="primary"
                                    style="order: 2"
                                    type="submit"
                                    >{{
                                        trans(
                                            'auth.forgot_password.action.submit',
                                        )
                                    }}
                                </jet-button>
                                <Link
                                    :href="$route('login')"
                                    class="text-muted px-3 d-block text-center btn btn-link"
                                    style="order: 1; min-height: 28px"
                                >
                                    {{ trans('general.action.cancel') }}
                                </Link>
                            </div>
                        </c-form>
                    </card>
                </column>
            </row>
        </container>
    </auth-layout>
</template>

<script>
import JetButton from '@/Jetstream/Button.vue';
import JetValidationErrors from '@/Jetstream/ValidationErrors.vue';
import AuthLayout from '@/Layouts/AuthLayout.vue';
import JetInput from '@/Jetstream/Input.vue';
import Alert from '@/Components/Alert.vue';

import Container from '@/Components/Container.vue';
import Card from '@/Components/Card.vue';

export default {
    components: {
        Card,
        Container,

        Alert,
        JetInput,
        JetButton,
        JetValidationErrors,
        AuthLayout,
    },

    data() {
        return {
            form: this.$inertia.form({
                email: '',
            }),
        };
    },

    computed: {
        errors() {
            return this.$page.props.errors;
        },

        hasErrors() {
            return Object.keys(this.errors).length > 0;
        },

        status() {
            return this.$page.props.status;
        },
    },

    methods: {
        submit() {
            this.form.post(this.route('password.email'));
        },
    },
};
</script>
