<template>
    <jet-action-section>
        <template #title>
            {{ trans('profile.information.delete_profile.header') }}
        </template>

        <template #description>
            {{ trans('profile.information.delete_profile.description') }}
        </template>

        <template #content>
            <div class="max-w-xl text-sm text-gray-600">
                {{ trans('profile.information.delete_profile.content') }}
            </div>

            <div class="mt-5 d-flex justify-content-end">
                <jet-button color="danger" @click="confirmUserDeletion">
                    {{
                        trans(
                            'profile.information.delete_profile.action.delete',
                        )
                    }}
                </jet-button>
            </div>

            <!-- Delete Account Confirmation Modal -->
            <dialog-modal :show="confirmingUserDeletion" @close="closeModal">
                <template #title>
                    {{
                        trans(
                            'profile.information.delete_profile.modal.delete_account.title',
                        )
                    }}
                </template>

                {{
                    trans(
                        'profile.information.delete_profile.modal.delete_account.content',
                    )
                }}

                <div class="mt-4">
                    <jet-input
                        ref="password"
                        v-model="form.password"
                        :invalidFeedback="form.errors.password"
                        class="mt-1 block w-3/4"
                        placeholder="Password"
                        type="password"
                        @keyup.enter="deleteUser"
                    />
                </div>

                <template #footer>
                    <jet-button color="secondary" @click="closeModal">
                        {{ trans('general.action.cancel') }}
                    </jet-button>

                    <jet-button
                        :class="{ 'opacity-25': form.processing }"
                        :disabled="form.processing"
                        class="ml-2"
                        color="danger"
                        @click="deleteUser"
                    >
                        {{
                            trans(
                                'profile.information.delete_profile.action.delete',
                            )
                        }}
                    </jet-button>
                </template>
            </dialog-modal>
        </template>
    </jet-action-section>
</template>

<script>
import JetActionSection from '@/Jetstream/ActionSection.vue';
import JetInput from '@/Jetstream/Input.vue';
import JetInputError from '@/Jetstream/InputError.vue';
import JetButton from '@/Jetstream/Button.vue';
import DialogModal from '@/Jetstream/DialogModal.vue';

export default {
    components: {
        JetActionSection,
        JetInput,
        DialogModal,
        JetInputError,
        JetButton,
    },

    data() {
        return {
            confirmingUserDeletion: false,

            form: this.$inertia.form({
                password: '',
            }),
        };
    },

    methods: {
        confirmUserDeletion() {
            this.confirmingUserDeletion = true;

            setTimeout(() => this.$refs.password.focus(), 250);
        },

        deleteUser() {
            this.form.delete(route('current-user.destroy'), {
                preserveScroll: true,
                onSuccess: () => this.closeModal(),
                onError: () => this.$refs.password.focus(),
                onFinish: () => this.form.reset(),
            });
        },

        closeModal() {
            this.confirmingUserDeletion = false;

            this.form.reset();
        },
    },
};
</script>
