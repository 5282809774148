<template>
    <div class="d-flex justify-content-between align-items-center">
        <div>
            <div
                v-if="selectionEnabled && selectedItemsCount > 0"
                class="text-semi-bold"
            >
                {{
                    trans('general.selected_items.label', {
                        count: selectedItemsCount,
                    })
                }}
            </div>
        </div>
        <div style="min-height: 30px">
            <pagination :paginator="items" :query-params="queryParams" />
        </div>
    </div>

    <sticky-table-header>
        <div class="position-relative table-responsive">
            <table
                :class="{ 'table-fixed': items?.data.length === 0 }"
                class="table table-striped table-hover table-bordered border"
            >
                <thead>
                    <tr>
                        <th
                            v-if="!!selectionEnabled"
                            style="
                                vertical-align: middle;
                                width: 40px !important;
                                min-width: 40px !important;
                                padding: 0;
                            "
                        >
                            <div
                                v-if="items?.data.length"
                                class="d-flex align-items-start justify-content-center table-all-selector"
                                style="height: 100%; cursor: pointer"
                                @click.prevent="toggleAllSelection"
                            >
                                <input
                                    :checked="areAllItemsSelected"
                                    :indeterminate="isIndeterminate"
                                    class="form-check-input m-0"
                                    readonly
                                    type="checkbox"
                                />
                            </div>
                        </th>
                        <th
                            v-for="header in columns"
                            :style="[
                                header._style,
                                {
                                    cursor: isColumnSortable(header)
                                        ? 'pointer'
                                        : 'default',
                                },
                            ]"
                            class="position-relative text-nowrap"
                            @click="updateSorting(header)"
                        >
                            <div
                                class="d-flex justify-content-between align-items-center"
                                style="gap: 5px"
                            >
                                <span>{{ header.label }}</span>
                                <sort-icon
                                    v-if="isColumnSortable(header)"
                                    :state="getSortableColumnState(header)"
                                    style="position: relative; top: 2px"
                                />
                            </div>
                        </th>
                        <th
                            v-if="
                                actions && actions.length && items?.data.length
                            "
                            style="width: 0px"
                        />
                    </tr>
                </thead>
                <tbody>
                    <template v-if="!items">
                        <tr v-for="n in 10" :key="n" style="cursor: default">
                            <td v-if="selectionEnabled" />
                            <template v-for="(column, idx) in columns">
                                <td class="align-middle">
                                    <skeleton-loader />
                                </td>
                            </template>
                            <td v-if="actions && actions.length" />
                        </tr>
                    </template>
                    <template v-if="items && items.data.length === 0">
                        <tr>
                            <td :colspan="columnsLength">
                                <div class="text-center my-5">
                                    <h2
                                        class="d-flex align-items-center justify-content-center"
                                        style="gap: 5px"
                                    >
                                        {{ noItemsView.noItems }}
                                        <icon
                                            class="text-danger"
                                            icon="cil-ban"
                                            style="width: 30px; height: 30px"
                                        />
                                    </h2>
                                </div>
                            </td>
                        </tr>
                    </template>
                    <template v-else>
                        <tr
                            v-for="(row, index) in items?.data"
                            :key="index"
                            :style="{
                                cursor: rowClickable ? 'pointer' : 'default',
                            }"
                            @click="rowClickable && emit('row-clicked', row)"
                        >
                            <td
                                v-if="selectionEnabled"
                                class="table-row-selector align-middle text-center"
                                @click.stop="toggleItemSelection(row.id)"
                            >
                                <jet-checkbox
                                    :model-value="isSelectedItem(row.id)"
                                />
                            </td>
                            <template v-for="(column, idx) in columns">
                                <slot
                                    v-if="slots[column.key]"
                                    :key="idx"
                                    :item="row"
                                    :name="column.key"
                                    :style="column._style"
                                />
                                <td v-else class="align-middle">
                                    {{ row[column.key] }}
                                </td>
                            </template>
                            <td
                                v-if="actions && actions.length"
                                :class="actionClasses"
                            >
                                <div
                                    class="d-flex align-items-center justify-content-end"
                                    style="gap: 5px"
                                >
                                    <template
                                        v-for="action in actions"
                                        :key="action.icon"
                                    >
                                        <jet-button
                                            v-if="shouldRender(action, row)"
                                            :class="{ 'btn-icon': action.icon }"
                                            :color="action.type || 'secondary'"
                                            @click.stop="action.click(row)"
                                        >
                                            <icon
                                                v-if="action.icon"
                                                v-c-tooltip="
                                                    action.tooltip || ''
                                                "
                                                :icon="action.icon"
                                            />
                                            {{ action.label }}
                                        </jet-button>
                                    </template>
                                </div>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
    </sticky-table-header>
</template>
<script setup>
import StickyTableHeader from '@/Components/StickyTableHeader.vue';
import { computed, useSlots } from 'vue';
import SortIcon from '@/Pages/Assets/SortIcon.vue';

import JetButton from '@/Jetstream/Button.vue';
import JetCheckbox from '@/Jetstream/Checkbox.vue';
import { hasAnyPermission } from '@/mixins/hasAnyPermission.js';
import Pagination from '@/Components/Pagination.vue';
import useSelectedItems from '@/Utils/UseSelectedItems';
import { trans } from '../mixins/i18n.js';
import SkeletonLoader from '@/Components/SkeletonLoader.vue';

const props = defineProps({
    class: String,
    columns: Array,
    items: Object,
    queryParams: Object,
    noItemsView: Object,
    actions: {
        type: Array,
        default: () => [],
    },
    rowClickable: {
        type: Boolean,
        default: true,
    },
    actionClasses: {
        type: [String, Object, Array],
        default: 'align-middle',
    },
    selectionEnabled: [String, Boolean],
    stickyHeader: {
        type: Object,
    },
});
const emit = defineEmits(['row-clicked']);
const slots = useSlots();

const meta = computed(() => {
    return props.items?.meta || {};
});

const {
    selectedItemsCount,
    isSelectedItem,
    isIndeterminate,
    areAllItemsSelected,
    toggleAllSelection,
    toggleItemSelection,
} = useSelectedItems(props.selectionEnabled, meta);

const updateSorting = (column) => {
    // Column sorting needs to be switched off explicitly
    if (column.sorter === false) {
        return;
    }

    props.queryParams.form.sort.column = column.key;
    props.queryParams.form.sort.asc =
        column.key === props.queryParams.form.sort.column
            ? !props.queryParams.form.sort.asc
            : true;
    props.queryParams.change();
};

const columnsLength = computed(() => {
    let count = props.columns.length;
    if (props.actions && props.actions.length) {
        count += 1;
    }
    if (props.selectionEnabled) {
        count += 1;
    }
    return count;
});

const sortableColumns = computed(() =>
    props.columns.filter((column) => isColumnSortable(column)),
);

const isColumnSortable = (column) =>
    props.items?.data.length &&
    (column.sorter === undefined || !!column.sorter);

const getSortableColumnState = (column) => {
    if (isColumnSortable(column)) {
        if (props.queryParams?.form.sort.column === column.key) {
            return props.queryParams.form.sort.asc ? 'asc' : 'desc';
        }
    }
    return 'none';
};

function getData(data, key, fallbackValue) {
    if (!data || !data.length || data[key] === undefined) {
        return fallbackValue;
    }

    return data[key];
}

function shouldRender(action, item) {
    let shouldRender = true;

    if (action.permissions && !hasAnyPermission(action.permissions)) {
        shouldRender = false;
    }

    if (action.condition && item && !action.condition(item)) {
        shouldRender = false;
    }

    return shouldRender;
}
</script>
