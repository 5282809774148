<template>
    <app-layout>
        <page-header :title="trans('assets.details.edit_asset')">
            <p class="m-0">
                {{ trans('assets.details.asset_identification.description') }}
            </p>
        </page-header>
        <row>
            <column>
                <jet-form-section type="decent" @submitted="handleSubmit">
                    <template #form>
                        <div>
                            <location-form-fields
                                v-model="form.location_id"
                                :errors="form.errors.location_id"
                            />
                        </div>
                    </template>
                    <template #actions>
                        <jet-form-buttons
                            :cancel-route="route('assets.show', asset.id)"
                            :disabled="!form.isDirty"
                            :has-changes="form.isDirty"
                            :is-processing="form.processing"
                            :is-update-form="true"
                            @submit="handleSubmit"
                        />
                    </template>
                </jet-form-section>
            </column>
        </row>
    </app-layout>
</template>
<script setup>
import AppLayout from '@/Layouts/AppLayout.vue';
import JetFormSection from '@/Jetstream/FormSection.vue';
import { useForm, usePage } from '@inertiajs/vue3';
import { onMounted } from 'vue';
import LocationFormFields from '@/Pages/Assets/Edit/LocationFormFields.vue';
import JetFormButtons from '@/Jetstream/FormButtons.vue';
import PageHeader from '@/Components/PageHeader.vue';
import { route } from 'ziggy-js';
import { useUnsavedChangesWarning } from '@/Utils/UseUnsavedChangesWarning';

const $page = usePage();
const asset = $page.props.asset;

const form = useForm({
    location_id: asset.location.id || undefined,
});

const handleSubmit = (event) => {
    return new Promise((resolve) => {
        form.patch(
            route('assets.update', {
                asset,
                redirect: event?.redirectUrl?.toString(),
            }),
            {
                onSuccess: () => resolve(true),
                onError: () => resolve(false),
            },
        );
    });
};

onMounted(() => {
    useUnsavedChangesWarning({
        hasChanges: () => form.isDirty || form.processing,
        handleSubmit,
    });
});
</script>
