<template>
    <jet-form-section @submitted="updatePassword">
        <template #title>
            {{ trans('profile.information.update_password.header') }}
        </template>
        <template #form>
            <p class="mb-5">
                {{ trans('profile.information.update_password.description') }}
            </p>
            <div class="col-span-6 sm:col-span-4">
                <jet-input
                    id="current_password"
                    ref="current_password"
                    v-model="form.current_password"
                    :invalidFeedback="form.errors.current_password"
                    :label="
                        trans(
                            'profile.information.update_password.label.current_password',
                        )
                    "
                    autocomplete="current-password"
                    type="password"
                />
            </div>

            <div class="col-span-6 sm:col-span-4">
                <jet-input
                    id="password"
                    ref="password"
                    v-model="form.password"
                    :invalid-feedback="passwordError"
                    :label="
                        trans(
                            'profile.information.update_password.label.new_password',
                        )
                    "
                    autocomplete="new-password"
                    type="password"
                />
            </div>

            <div class="col-span-6 sm:col-span-4">
                <jet-input
                    id="password_confirmation"
                    v-model="form.password_confirmation"
                    :invalid-feedback="passwordConfirmationError"
                    :label="trans('auth.general.password_confirmation')"
                    autocomplete="new-password"
                    type="password"
                />
            </div>
            <password-validation-errors
                :errors="$page.props.errorBags.updatePassword?.password"
                :show-status="formSubmitted"
            />
        </template>

        <template #actions>
            <jet-action-message :on="form.recentlySuccessful" class="mr-3">
                {{ trans('general.message.saved') }}
            </jet-action-message>

            <jet-button
                :disabled="!form.isDirty"
                :is-processing="form.processing"
            >
                {{ trans('general.action.save') }}
            </jet-button>
        </template>
    </jet-form-section>
</template>

<script>
import JetActionMessage from '@/Jetstream/ActionMessage.vue';
import JetButton from '@/Jetstream/Button.vue';
import JetFormSection from '@/Jetstream/FormSection.vue';
import JetInput from '@/Jetstream/Input.vue';
import JetInputError from '@/Jetstream/InputError.vue';
import JetLabel from '@/Jetstream/Label.vue';
import PasswordValidationErrors from '@/Components/PasswordValidationErrors.vue';

export default {
    components: {
        PasswordValidationErrors,
        JetActionMessage,
        JetButton,
        JetFormSection,
        JetInput,
        JetInputError,
        JetLabel,
    },

    data() {
        return {
            formSubmitted: false,
            form: this.$inertia.form({
                current_password: '',
                password: '',
                password_confirmation: '',
            }),
        };
    },

    computed: {
        passwordRuleValidationMessages() {
            return (
                Object.values(this.$page.props.passwordValidationMessages) || []
            );
        },
        passwordError() {
            // Exclude password rule validation errors as they are
            // presented below the form element
            if (
                this.passwordRuleValidationMessages.includes(
                    this.form.errors.password,
                )
            ) {
                return ' '; // Space required to trigger invalid state
            }

            return this.form.errors.password;
        },
        passwordConfirmationError() {
            if (this.passwordError === ' ') {
                return ' ';
            }

            return this.form.errors.password_confirmation;
        },
    },

    methods: {
        updatePassword() {
            this.form.put(route('user-password.update'), {
                errorBag: 'updatePassword',
                preserveScroll: true,
                onSuccess: () => this.form.reset(),
                onError: () => {
                    if (this.form.errors.password) {
                        this.form.reset('password', 'password_confirmation');
                        this.$refs.password.focus();
                    }

                    if (this.form.errors.current_password) {
                        this.form.reset('current_password');
                        this.$refs.current_password.focus();
                    }
                },
                onFinish: () => (this.formSubmitted = true),
            });
        },
    },
};
</script>
