<template>
    <dropdown
        :key="isMobile"
        v-c-tooltip="{
            content: trans('navigation.notifications'),
            placement: 'bottom',
            trigger: isMobile ? ['none'] : ['hover'],
        }"
        :auto-close="'outside'"
        :caret="false"
        class="notification-center"
        variant="nav-item"
    >
        <template #toggler>
            <icon icon="cil-bell" style="width: 22px; height: 20px" />
            <badge
                v-if="notifications?.data.length"
                class="notification-count"
                v-text="counter"
            />
        </template>
        <dropdown-header
            class="d-flex justify-content-between align-items-center"
            style="flex-wrap: wrap"
        >
            <strong>{{
                trans('notification_center.summary', {
                    count: notifications?.meta.total,
                })
            }}</strong
            >&nbsp;
            <a
                v-if="notifications?.data.length && !isLoading"
                :href="$route('notifications.mark_as_read')"
                @click.stop.prevent="markAllAsRead"
                >{{ trans('notification_center.action.mark_all_as_read') }}</a
            >
            <spinner v-if="isLoading" />
        </dropdown-header>
        <div
            v-if="notifications?.data.length"
            class="notification-item-container"
        >
            <notification
                v-for="notification in notifications.data"
                :key="notification.id"
                :notification="notification"
                class="notification-item"
                @read="removeReadNotification"
            />
            <div ref="intersectionObserver" style="min-height: 1px" />
        </div>
    </dropdown>
</template>
<script setup>
import { eventBus, events } from '@/eventBus';
import Notification from '@/Components/NotificationCenter/Notification.vue';
import { throttle } from 'lodash';
import { $http } from '@/bootstrap.js';
import Dropdown from '@/Components/Dropdown.vue';

import Badge from '@/Components/RoundedBadge.vue';
import DropdownHeader from '@/Components/DropdownHeader.vue';
import { isMobile } from '@/Utils/UseMedia';
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { route } from 'ziggy-js';
import { useIntersectionObserver } from '@vueuse/core';
import { trans } from '@/mixins/i18n.js';

const notifications = ref(null);
const show = ref(false);
const isLoading = ref(false);
const intersectionObserver = ref(null);

const counter = computed(() => {
    if (!notifications.value) {
        return 0;
    }

    return notifications.value.meta.total > 9
        ? '9+'
        : notifications.value.meta.total;
});

onMounted(() => {
    eventBus.$on(events.updateNotifications, loadUnreadNotifications);
    // Load unread notifications periodically
    eventBus.$on(events.clockUpdated, () => {
        throttleLoadUnreadNotifications();
    });
});

const addNotification = (notification) => {
    const notificationExists = !!notifications.value.find(
        (n) => n.id === notification.id,
    );

    if (!notificationExists) {
        notifications.value.unshift(notification);
    }
};

const removeReadNotification = (notification) => {
    notifications.value.data = notifications.value.data.filter(
        (n) => n.id !== notification.id,
    );

    notifications.value.meta.total -= 1;
};
const throttleLoadUnreadNotifications = throttle(function () {
    loadUnreadNotifications();
}, 60000);

const loadUnreadNotifications = () => {
    if (
        notifications.value?.meta.current_page >=
        notifications.value?.meta.last_page
    ) {
        return;
    }

    isLoading.value = true;

    $http
        .get(route('notifications.index'), {
            params: {
                page: notifications.value?.meta.current_page + 1 || null,
            },
        })
        .then((resp) => {
            if (notifications.value) {
                notifications.value.meta = resp.data.meta;
                notifications.value.links = resp.data.links;
                notifications.value.data = [
                    ...notifications.value.data,
                    ...resp.data.data,
                ];
            } else {
                notifications.value = resp.data;
            }

            if (resp.data.length === 0) {
                show.value = false;
            }
        })
        .catch(() => (notifications.value.data = []))
        .finally(() => (isLoading.value = false));
};

const markAllAsRead = () => {
    isLoading.value = true;

    $http
        .post(route('notifications.mark_as_read'))
        .then(() => {
            notifications.value.data = [];
        })
        .finally(() => {
            isLoading.value = false;
            setTimeout(() => {
                loadUnreadNotifications();
            }, 15000);
        });
};

const { stop } = useIntersectionObserver(
    intersectionObserver,
    ([entry], observerElement) => {
        if (entry.isIntersecting) {
            loadUnreadNotifications();
        }
    },
);

onUnmounted(() => {
    eventBus.$off([events.updateNotifications, events.clockUpdated]);
    removeEventListener('focus', loadUnreadNotifications);
    stop();
});
</script>
<style scoped>
@media (max-width: 576px) {
    :deep(.dropdown-menu) {
        position: fixed !important;
        left: 50% !important;
        top: 45px !important;
        transform: translate(-50%) !important;
    }
}
</style>
