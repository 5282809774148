<template>
    <app-layout>
        <page-header :title="trans('assets.details.edit_asset')">
            <p class="m-0">
                {{ trans('assets.details.asset_identification.description') }}
            </p>
        </page-header>
        <row>
            <column>
                <jet-form-section type="decent" @submitted="handleSubmit">
                    <template #form>
                        <asset-information-form-section :form="form" />
                    </template>
                    <template #actions>
                        <jet-form-buttons
                            :cancel-route="route('assets.show', asset.id)"
                            :disabled="!form.isDirty"
                            :has-changes="form.isDirty"
                            :is-processing="form.processing"
                            :is-update-form="true"
                            @submit="handleSubmit"
                        />
                    </template>
                </jet-form-section>
            </column>
        </row>
    </app-layout>
</template>
<script>
import AppLayout from '@/Layouts/AppLayout.vue';
import JetFormSection from '@/Jetstream/FormSection.vue';
import JetFormButtons from '@/Jetstream/FormButtons.vue';
import PageHeader from '@/Components/PageHeader.vue';
import { useUnsavedChangesWarning } from '@/Utils/UseUnsavedChangesWarning';
import AssetInformationFormSection from '@/Pages/Assets/Edit/AssetInformationFormSection.vue';

export default {
    components: {
        AssetInformationFormSection,
        PageHeader,
        AppLayout,
        JetFormSection,
        JetFormButtons,
    },

    data() {
        return {
            models: [],
            isLoadingModels: false,
            form: this.$inertia.form({
                petitecIdentifier: '',
                manufacturer: '',
                serialNumber: '',
                customerTrackingNumber: '',
                owner: '',
                operator: '',
                maintainer: '',
                locationId: '',
                modelId: '',
                screeningApplication: '',
                purchasedAt: '',
                deployedAt: '',
                critical: 'no',
                warrantyStartDate: '',
                warrantyEndDate: '',
            }),
        };
    },

    computed: {
        asset() {
            return this.$page.props.asset;
        },
    },

    mounted() {
        this.form = this.$inertia.form({
            petitecIdentifier: this.asset.petitec_identifier || '',
            serialNumber: this.asset.serial_number || '',
            customerTrackingNumber: this.asset.customer_tracking_number || '',
            owner: this.asset.owner?.id || '',
            operator: this.asset.operator?.id || '',
            manufacturer: this.asset.manufacturer?.id || '',
            maintainer: this.asset.maintainer?.id || '',
            locationId: this.asset.location?.id || '',
            modelId: this.asset.model?.id || '',
            screeningApplication: this.asset.screening_application?.value || '',
            purchasedAt: this.parseApiDate(this.asset.purchased_at),
            deployedAt: this.parseApiDate(this.asset.deployed_at),
            critical: this.asset.critical ? 'yes' : 'no',
            warrantyStartDate: this.parseApiDate(
                this.asset.warranty?.start_date,
            ),
            warrantyEndDate: this.parseApiDate(this.asset.warranty?.end_date),
        });

        this.selectedManufacturerId = this.asset.manufacturer.id;

        useUnsavedChangesWarning({
            hasChanges: () => this.form.isDirty || this.form.processing,
            handleSubmit: this.handleSubmit,
        });
    },

    methods: {
        handleSubmit(event) {
            return new Promise((resolve) => {
                this.form.transform(this.transformData).put(
                    this.route('assets.update', {
                        asset: this.asset,
                        redirect: event?.redirectUrl?.toString(),
                    }),
                    {
                        onSuccess: () => resolve(true),
                        onError: () => resolve(false),
                    },
                );
            });
        },
        transformData(data) {
            data = Object.assign({}, data);

            return {
                ...data,
                deployedAt: this.formatAsApiDate(data.deployedAt),
                purchasedAt: this.formatAsApiDate(data.purchasedAt),
                warrantyStartDate: this.formatAsApiDate(data.warrantyStartDate),
                warrantyEndDate: this.formatAsApiDate(data.warrantyEndDate),
                critical: data.critical === 'yes',
            };
        },
    },
};
</script>
