<template>
    <dialog-modal
        v-if="shouldRender"
        :close-button="true"
        :show="show"
        :show-footer="false"
        @close="closeModal"
    >
        <template #title>{{ trans('general.attachments.label') }}</template>
        <file-list :attachments="attachments" />
    </dialog-modal>
</template>
<script setup>
import DialogModal from '@/Jetstream/DialogModal.vue';
import FileList from '@/Components/FileList.vue';
import { eventBus, events } from '@/eventBus.js';
import { nextTick, onMounted, onUnmounted, ref } from 'vue';

const attachments = ref([]);
const shouldRender = ref(false);
const show = ref(false);

onMounted(() => {
    eventBus.$on([events.openAttachmentListModal], (data) => {
        shouldRender.value = true;

        attachments.value = data.attachments;

        nextTick(() => {
            show.value = true;
        });
    });
});

onUnmounted(() => {
    eventBus.$off([events.openAttachmentListModal]);
});

const closeModal = () => {
    show.value = false;
    setTimeout(() => {
        shouldRender.value = false;
    }, 300);
};
</script>
