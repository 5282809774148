<template>
    <div
        :class="{
            active: !!modelValue,
            focused: isSearchFocused,
        }"
        class="input-group filter-search flex-grow-1 flex-sm-grow-0"
        title="[Ctrl + k]"
    >
        <input
            ref="search"
            v-model="value"
            :disabled="isProcessing"
            :placeholder="placeholder"
            class="form-control"
            style="background: #fff !important"
            type="text"
            @blur="blurSearchFilter"
            @click="focusSearchFilter"
            @keydown.enter="search"
        />
        <a
            v-if="!!value"
            class="btn-clear d-flex align-items-center justify-content-center"
            href="#"
            @click.stop="clear"
        >
            <icon name="cil-x-circle" />
        </a>
        <div class="input-group-text bg-primary border-0">
            <spinner v-if="showSpinner && isProcessing" class="text-white" />
            <search v-else fill="#fff" />
        </div>
    </div>
</template>
<script>
import Search from '@/../icons/Search.vue';

export default {
    name: 'search-filter',
    components: { Search },
    emits: ['update:modelValue'],
    props: {
        modelValue: String,
        showSpinner: {
            type: Boolean,
            default: false,
        },
        isProcessing: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            isSearchFocused: false,
            value: '',
        };
    },
    watch: {
        modelValue: {
            handler: function (newValue) {
                this.value = newValue;
            },
            immediate: true,
        },
    },
    created() {
        window.addEventListener('keydown', this.keyboardListener);
    },

    destroyed() {
        window.removeEventListener('keydown', this.keyboardListener);
    },
    methods: {
        focusSearchFilter() {
            this.$refs.search?.focus();
            this.$refs.search?.select();
            this.isSearchFocused = true;
        },
        blurSearchFilter() {
            if (!this.isSearchFocused) {
                return;
            }

            this.isSearchFocused = false;
            if (this.$refs.search === document.activeElement) {
                document.activeElement.blur();
            }
        },
        keyboardListener(event) {
            if (this.isSearchFocused && event.key.toLowerCase() === 'escape') {
                this.blurSearchFilter();
                return;
            }

            if ((event.ctrlKey || event.metaKey) && event.key === 'k') {
                this.focusSearchFilter();
            }
        },
        search() {
            let val = !!this.value ? String(this.value).trim() : undefined;
            this.$emit('update:modelValue', val);
        },
        clear() {
            this.value = '';
            this.search();
        },
    },
};
</script>
