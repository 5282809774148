<template>
    <dialog-modal
        :is-processing="form.processing"
        :primary-button-label="trans('general.action.create')"
        :show="show"
        :submit-disabled="!form.isDirty"
        @close="$emit('cancel')"
        @submit="handleSubmit"
    >
        <template #title>{{ title }}</template>

        <form @submit.prevent="handleSubmit">
            <row>
                <column>
                    <slot :form="form" :label="label" :show="show" name="form">
                        <div class="d-flex w-100">
                            <jet-input
                                v-model="form.name"
                                :autofocus="show"
                                :horizontal="{
                                    label: 'col-sm-4',
                                    input: 'col-sm-8',
                                }"
                                :invalidFeedback="form.errors.name"
                                :label="label"
                                :placeholder="label"
                                :required="true"
                                label-classes="required"
                                style="flex-grow: 1"
                            />
                        </div>
                    </slot>
                </column>
            </row>
        </form>
    </dialog-modal>
</template>
<script>
import DialogModal from '@/Jetstream/DialogModal.vue';
import JetInput from '@/Jetstream/Input.vue';
import JetButton from '@/Jetstream/Button.vue';
import { $http } from '@/bootstrap.js';
import Row from '@/Components/Row.vue';

export default {
    components: { Row, DialogModal, JetButton, JetInput },
    props: {
        show: {
            type: Boolean,
            default: true,
        },
        createRoute: String,
        title: String,
        label: String,
        formFields: Object,
    },
    data() {
        return {
            form: this.$inertia.form({
                name: null,
            }),
        };
    },

    watch: {
        show() {
            this.form.reset();
            this.form.clearErrors();
        },
    },

    mounted() {
        if (this.formFields) {
            this.form = this.$inertia.form(this.formFields);
        }
    },

    methods: {
        handleSubmit(event) {
            event.preventDefault();

            this.form.processing = true;

            $http
                .post(this.createRoute, this.form.data())
                .then((response) => {
                    this.$emit('success', response.data);
                })
                .catch((error) => {
                    this.form.clearErrors();
                    let errors = {};

                    Object.keys(error.response.data.errors).map((key) => {
                        errors[key] = error.response.data.errors[key][0];
                    });

                    this.form.errors = errors;
                })
                .finally(() => {
                    this.form.processing = false;
                });
        },
    },
};
</script>
