<template>
    <div :class="{ 'c-dark-theme': false }" class="c-app">
        <the-sidebar />
        <div class="wrapper d-flex flex-column min-vh-100">
            <the-header />
            <div class="c-body">
                <main class="c-main">
                    <container class="mb-3" fluid>
                        <slot />
                    </container>
                </main>
            </div>
            <div
                v-if="$page.props.auth.isImpersonating"
                class="impersonate-banner bg-warning d-flex justify-content-between px-5 py-3 bg-yellow"
            >
                {{
                    trans('general.user.impersonation.description', {
                        user: user.name,
                    })
                }}
                <a href="#" @click.stop="leaveImpersonation()">{{
                    trans('general.user.impersonation.action.leave')
                }}</a>
            </div>
            <the-footer />
        </div>
    </div>
</template>

<script>
import TheSidebar from '@/Containers/TheSidebar.vue';
import TheHeader from '@/Containers/TheHeader.vue';
import TheFooter from '@/Containers/TheFooter.vue';
import Container from '@/Components/Container.vue';

export default {
    name: 'TheContainer',

    components: {
        Container,
        TheSidebar,
        TheHeader,
        TheFooter,
    },

    methods: {
        leaveImpersonation() {
            this.$inertia.get(route('users.leave-impersonate'));
        },
    },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.impersonate-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
}
</style>
