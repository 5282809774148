<template>
    <div class="d-flex overflow-hidden">
        <div
            class="d-flex justify-content-center align-items-center flex-shrink-0"
            style="width: 40px"
        >
            <em :class="operability" class="operability-indicator" />
        </div>
        <div class="overflow-hidden">
            <div>
                <b class="text-truncate d-block">{{ title }}</b>
            </div>
            <small class="text-truncate d-block text-muted">{{
                description
            }}</small>
        </div>
    </div>
</template>
<script>
export default {
    name: 'asset.operability.changed',
    props: {
        notification: {},
    },
    computed: {
        title() {
            return this.notification.payload.asset;
        },
        operability() {
            return this.notification.payload.operability;
        },
        description() {
            return this.trans(`notification.${this.notification.type}`, {
                operability: this.operability,
            });
        },
    },
};
</script>
