<template>
    <row>
        <column>
            <jet-select
                v-model="form.topLevelLocationId"
                :disabled="form.processing"
                :enable-search="true"
                :horizontal="false"
                :invalid-feedback="topLevelLocationError"
                :label="trans('assets.details.physical_location.label')"
                :options="topLevelLocations || []"
                :required="true"
                name="locationId"
                @update:model-value="handleTopLevelLocationUpdate($event)"
            >
                <template v-slot:item-label="{ option }">
                    <div
                        class="d-flex justify-content-between align-items-center gap-3"
                    >
                        <span>{{ option.label }}</span
                        ><small class="text-muted">{{ option.iataCode }}</small>
                    </div>
                </template>
            </jet-select>
        </column>
    </row>

    <row>
        <column>
            <jet-select
                v-model="form.specificLocationId"
                :disabled="form.processing || specificLocations.length === 0"
                :enable-search="true"
                :horizontal="false"
                :invalid-feedback="specificLocationError"
                :is-loading="form.processing"
                :label="trans('assets.details.specific_location.label')"
                :options="specificLocations || []"
                :required="true"
                name="specificLocationId"
                @update:model-value="handleSpecificLocationUpdate($event)"
            >
                <template v-slot:item-label="{ option }">
                    <div
                        class="d-flex justify-content-between align-items-center gap-3"
                    >
                        <span>{{ option.label }}</span>
                    </div>
                </template>
            </jet-select>
        </column>
    </row>

    <row>
        <column>
            <jet-select
                v-model="form.areaId"
                :disabled="form.processing || areaLocations.length === 0"
                :enable-search="true"
                :horizontal="false"
                :invalid-feedback="areaLocationError"
                :is-loading="form.processing"
                :label="trans('assets.details.area_location.label')"
                :options="areaLocations || []"
                name="areaId"
                @update:model-value="handleAreaLocationUpdate($event)"
            >
                <template v-slot:item-label="{ option }">
                    <div
                        class="d-flex justify-content-between align-items-center gap-3"
                    >
                        <span>{{ option.label }}</span>
                    </div>
                </template>
            </jet-select>
        </column>
    </row>

    <row>
        <column>
            <jet-select
                v-model="form.subAreaId"
                :disabled="form.processing || subAreaLocations.length === 0"
                :enable-search="true"
                :horizontal="false"
                :invalid-feedback="subAreaLocationError"
                :is-loading="form.processing"
                :label="trans('assets.details.sub_area_location.label')"
                :options="subAreaLocations || []"
                name="subAreaId"
                @update:model-value="handleSubAreaLocationUpdate($event)"
            >
                <template v-slot:item-label="{ option }">
                    <div
                        class="d-flex justify-content-between align-items-center gap-3"
                    >
                        <span>{{ option.label }}</span>
                    </div>
                </template>
            </jet-select>
        </column>
    </row>
</template>
<script setup>
import JetSelect from '@/Jetstream/Select.vue';
import { computed, ref } from 'vue';
import { useForm, usePage } from '@inertiajs/vue3';
import { $http } from '@/bootstrap.js';

const props = defineProps({
    modelValue: String,
    errors: [Object, String],
});

const emit = defineEmits(['update:modelValue']);
const $page = usePage();

const form = useForm({
    topLevelLocationId: $page.props.locationTree.ancestorsAndSelfLocationIds[0],
    specificLocationId: $page.props.locationTree.ancestorsAndSelfLocationIds[1],
    areaId: $page.props.locationTree.ancestorsAndSelfLocationIds[2],
    subAreaId: $page.props.locationTree.ancestorsAndSelfLocationIds[3],
});

const createOptions = (locations) => {
    if (!locations) {
        return [];
    }

    return locations.map((loc) => ({
        label: loc.name,
        value: loc.id,
        searchableContent: loc.name,
    }));
};

const topLevelLocations = computed(() => {
    return (
        $page.props.locationTree.topLevelLocations?.data.map((loc) => ({
            label: loc.name,
            value: loc.id,
            iataCode: loc.iataCode,
            searchableContent: `${loc.iataCode}||${loc.name}`,
        })) || []
    );
});

const specificLocations = ref(
    createOptions($page.props.locationTree.specificLocations?.data),
);
const areaLocations = ref(
    createOptions($page.props.locationTree.areaLocations?.data),
);
const subAreaLocations = ref(
    createOptions($page.props.locationTree.subAreaLocations?.data),
);

const loadLocationInformation = (id) => {
    if (!id) {
        return;
    }

    form.processing = true;

    return $http
        .get(route('locations.show', id))
        .then((resp) => resp.data)
        .finally((resp) => {
            form.processing = false;
        });
};

// ERRORS
const topLevelLocationError = computed(() =>
    !form.topLevelLocationId ? props.errors : undefined,
);

const specificLocationError = computed(() =>
    form.topLevelLocationId && !form.specificLocationId
        ? props.errors
        : undefined,
);

const areaLocationError = computed(() =>
    areaLocations.value.find((loc) => loc.value === form.areaId)
        ? props.errors
        : undefined,
);

const subAreaLocationError = computed(() =>
    subAreaLocations.value.find((loc) => loc.value === form.subAreaId)
        ? props.errors
        : undefined,
);

const updateLocations = (id, targetArray, formField, nextHandler) => {
    return loadLocationInformation(id)?.then((data) => {
        // Populate targetArray with location data or default to an empty array
        targetArray.value = createOptions(data.children);

        // Automatically select the location in case there is only one option
        if (
            targetArray.value.length === 1 &&
            formField === 'specificLocationId'
        ) {
            form[formField] = targetArray.value[0].value;

            if (nextHandler) {
                nextHandler(form[formField]);
            }
        }
    });
};

// Handle top-level location updates
const handleTopLevelLocationUpdate = (id) => {
    form.specificLocationId = null;
    form.areaId = null;
    form.subAreaId = null;

    specificLocations.value = [];
    areaLocations.value = [];
    subAreaLocations.value = [];

    emit('update:modelValue', id);

    updateLocations(
        id,
        specificLocations,
        'specificLocationId',
        handleSpecificLocationUpdate,
    );
};

// Handle specific location updates
const handleSpecificLocationUpdate = (id) => {
    form.areaId = null;
    form.subAreaId = null;

    areaLocations.value = [];
    subAreaLocations.value = [];

    updateLocations(
        id,
        areaLocations,
        'areaId',
        handleAreaLocationUpdate,
    )?.then(() => emit('update:modelValue', id));
};

// Handle area location updates
const handleAreaLocationUpdate = (id) => {
    form.subAreaId = null;

    subAreaLocations.value = [];

    updateLocations(
        id,
        subAreaLocations,
        'subAreaId',
        handleSubAreaLocationUpdate,
    )?.then(() => emit('update:modelValue', id));
};

const handleSubAreaLocationUpdate = (id) => {
    emit('update:modelValue', id);
};
</script>
