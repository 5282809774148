import { router, useForm } from '@inertiajs/vue3';
import { removeNullValues } from '../mixins/removeNullValues';
import { onMounted } from 'vue';
import {
    transformQueryToSort,
    transformSortToQuery,
} from './transformSortToQuery';
import { useRoute } from 'ziggy-js';

export default function useQueryParams({
    paramsWithDefaults,
    route,
    options,
    additionalData = () => ({}),
    onLoadSuccess = ({}) => {},
}) {
    // Add page to all params
    paramsWithDefaults.page = 1;

    let currentPage = 1;

    const form = useForm(paramsWithDefaults);
    const currentRoute = useRoute();

    const clear = () => {
        form.reset();
        loadItems();
    };

    const transformData = (data: Object) => {
        data = { ...data };

        if (options.sortKey) {
            data[options.sortKey] = transformSortToQuery(data.sort);
            delete data.sort;
        } else {
            data.sort = transformSortToQuery(data.sort);
        }

        data.page = transformPageValue(data.page);
        return removeNullValues(data);
    };

    const transformPageValue = (page) => {
        page = Math.abs(parseInt(page) || 1);

        if (page === currentPage) {
            form.reset('page');
            currentPage = 1;
            page = 1;
        } else {
            currentPage = page || 1;
        }

        return page === 1 ? null : page;
    };

    const loadItems = () => {
        form.processing = true;

        const collectedData = Object.assign(
            {},
            transformData(form.data()),
            additionalData(),
        );

        // Can't use form directly as this would reset
        // defaults which leads to isDirty being false
        router.get(route, collectedData, {
            only: options?.only || [],
            preserveState: options?.preserveState || true,
            preserveScroll: options?.preserveScroll || false,
            onSuccess: (data) => onLoadSuccess(data),
            onFinish: () => {
                form.processing = false;
            },
        });
    };

    const readParamsFromUrl = () => {
        let params = currentRoute().params;

        Object.keys(params).forEach((key) => {
            if (
                !paramsWithDefaults.hasOwnProperty(key) &&
                key !== options.sortKey
            ) {
                return;
            }

            if (key === 'sort') {
                form.sort = transformQueryToSort(
                    params.sort,
                    paramsWithDefaults.sort,
                );
            } else if (key === options.sortKey) {
                form.sort = transformQueryToSort(
                    params[key],
                    paramsWithDefaults.sort,
                );
            } else if (key === 'page') {
                const page = Math.abs(parseInt(params[key]) || 1);
                currentPage = page;
                form[key] = page;
            } else {
                form[key] = params[key];
            }
        });
    };

    onMounted(() => {
        // Set initial params
        readParamsFromUrl();
    });

    return {
        form,
        defaults: paramsWithDefaults,
        change: loadItems,
        urlParams: () => transformData(form.data()),
        clear,
    };
}
