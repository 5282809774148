<template>
    <div v-if="sla">
        <row :class="{ 'mb-2': !horizontal }">
            <div :class="labelClasses">
                {{ trans('maintainers.sla.contracting_authority.title') }}
            </div>
            <div :class="inputClasses">
                {{ sla.contractingAuthority?.name || '-' }}
            </div>
            <div :class="labelClasses">
                {{ trans('maintainers.sla.time_to_respond.title') }}:
            </div>
            <div :class="inputClasses">
                <div class="d-flex align-items-center" style="gap: 8px">
                    <high />
                    <span
                        v-if="sla.timeToRespond?.high.value"
                        class="text-gray"
                        >{{
                            getTimeDurationLabel(sla.timeToRespond?.high)
                        }}</span
                    >
                    <span v-else class="text-gray">{{
                        trans('general.not_available')
                    }}</span>
                </div>
                <div class="d-flex align-items-center" style="gap: 8px">
                    <medium />
                    <span
                        v-if="sla.timeToRespond?.medium.value"
                        class="text-gray"
                        >{{
                            getTimeDurationLabel(sla.timeToRespond?.medium)
                        }}</span
                    >
                    <span v-else class="text-gray">{{
                        trans('general.not_available')
                    }}</span>
                </div>
                <div class="d-flex align-items-center" style="gap: 8px">
                    <low />
                    <span
                        v-if="sla.timeToRespond?.low.value"
                        class="text-gray"
                        >{{
                            getTimeDurationLabel(sla.timeToRespond?.low)
                        }}</span
                    >
                    <span v-else class="text-gray">{{
                        trans('general.not_available')
                    }}</span>
                </div>
            </div>
        </row>
        <row :class="{ 'mb-2': !horizontal }">
            <div :class="labelClasses">
                {{ trans('maintainers.sla.time_to_resolve.title') }}:
            </div>
            <div :class="inputClasses">
                <div class="d-flex align-items-center" style="gap: 8px">
                    <high v-c-tooltip="{ content: 'High' }" />
                    <span
                        v-if="sla.timeToResolve?.high.value"
                        class="text-gray"
                        >{{
                            getTimeDurationLabel(sla.timeToResolve?.high)
                        }}</span
                    >
                    <span v-else class="text-gray">{{
                        trans('general.not_available')
                    }}</span>
                </div>
                <div class="d-flex align-items-center" style="gap: 8px">
                    <medium
                        v-c-tooltip="{
                            content: 'Medium',
                            popperOptions: { positionFixed: true },
                        }"
                    />
                    <span
                        v-if="sla.timeToResolve?.medium.value"
                        class="text-gray"
                        >{{
                            getTimeDurationLabel(sla.timeToResolve?.medium)
                        }}</span
                    >
                    <span v-else class="text-gray">{{
                        trans('general.not_available')
                    }}</span>
                </div>
                <div class="d-flex align-items-center" style="gap: 8px">
                    <low
                        v-c-tooltip="{
                            content: 'Low',
                            popperOptions: { positionFixed: true },
                        }"
                    />
                    <span
                        v-if="sla.timeToResolve?.low.value"
                        class="text-gray"
                        >{{
                            getTimeDurationLabel(sla.timeToResolve?.low)
                        }}</span
                    >
                    <span v-else class="text-gray">{{
                        trans('general.not_available')
                    }}</span>
                </div>
            </div>
        </row>
        <row :class="{ 'mb-2': !horizontal }">
            <div :class="labelClasses">
                {{ trans('maintainers.sla.working_hours.title') }}:
            </div>
            <div :class="inputClasses">
                <div v-if="workingHours?.length" class="text-gray">
                    <div v-if="typeof workingHours === 'string'">
                        {{ workingHours }}
                    </div>
                    <div v-else>
                        <div v-for="weekday in workingHours" :key="weekday.day">
                            {{ getWorkingHourLabel(weekday) }}
                        </div>
                    </div>
                </div>
                <div v-else class="text-gray">
                    {{ trans('general.not_available') }}
                </div>
            </div>
        </row>
        <row :class="{ 'mb-2': !horizontal }">
            <div :class="labelClasses">
                {{ trans('maintainers.sla.agreement_term.title') }}:
            </div>
            <div :class="inputClasses">
                <table>
                    <tbody>
                        <tr>
                            <td class="text-gray align-top" style="width: 50px">
                                {{
                                    trans(
                                        'maintainers.sla.agreement_term.from.title',
                                    )
                                }}
                            </td>
                            <td class="text-gray">
                                <span v-if="sla.startDate" class="text-nowrap">
                                    {{
                                        $filters.fullDate(
                                            sla.startDate,
                                            currentLocale,
                                        )
                                    }}
                                </span>
                                <span v-else>{{
                                    trans('general.not_available')
                                }}</span>
                            </td>
                        </tr>
                        <tr>
                            <td class="text-gray align-top">
                                {{
                                    trans(
                                        'maintainers.sla.agreement_term.to.title',
                                    )
                                }}
                            </td>
                            <td
                                :class="{
                                    'text-danger':
                                        sla.expired || sla.expiresSoon,
                                    'text-gray':
                                        !sla.expired && !sla.expiresSoon,
                                }"
                            >
                                <span v-if="sla.endDate" class="text-nowrap">
                                    {{
                                        $filters.fullDate(
                                            sla.endDate,
                                            currentLocale,
                                        )
                                    }}
                                </span>
                                <span v-else>{{
                                    trans('general.not_available')
                                }}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </row>
    </div>
</template>
<script>
import WorkOrderPriority from '@/Pages/WorkOrders/Modals/WorkOrderPriority.vue';
import Low from '@/../icons/Low.vue';
import High from '@/../icons/High.vue';
import Medium from '@/../icons/Medium.vue';
import { getWeekday } from '@/Pages/Stakeholders/Maintainers/weekdays.js';
import { trans } from '@/mixins/i18n';
import Row from '@/Components/Row.vue';

export default {
    name: 'ServiceLevelAgreementInfo',
    components: { Row, Medium, High, Low, WorkOrderPriority },
    props: {
        workingHours: [Array, String],
        sla: [Object, null],
        horizontal: {
            type: [Boolean, Object],
            default: false,
        },
    },
    computed: {
        labelClasses() {
            if (!this.horizontal) {
                return ['col-md-12'];
            }
            return [this.horizontal.label || 'col-md-6'];
        },
        inputClasses() {
            if (!this.horizontal) {
                return 'col-md-12';
            }
            return this.horizontal.input || 'col-md-6';
        },
    },
    methods: {
        trans,
        getWorkingHourLabel(weekday) {
            let dayOfWeek = getWeekday(weekday.day, this.currentLocale)?.label;
            return `${dayOfWeek}, ${weekday.from}-${weekday.to}`;
        },
        getTimeDurationLabel(duration) {
            let durationLabel = this.trans(`general.${duration.unit}`);
            return `${duration.value} ${durationLabel}`;
        },
    },
};
</script>
