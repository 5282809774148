<template>
    <div class="d-flex align-items-center gap-2">
        <icon icon="cil-calendar" style="position: relative; top: -2px" />
        {{
            trans('dashboard.header.updated_at', {
                datetime,
            })
        }}
    </div>
</template>
<script>
import { DateTime } from 'luxon';
import formatDate from '@/filters/date.js';
import { trans } from '@/mixins/i18n.js';

export default {
    name: 'updated-at',
    methods: { trans },
    props: {
        updatedAt: {},
    },
    computed: {
        datetime() {
            return this.updatedAt ? `${this.time} | ${this.date}` : '';
        },
        date() {
            return formatDate(
                this.updatedAt,
                { month: 'short', day: 'numeric' },
                this.currentLocale,
            );
        },
        time() {
            return formatDate(
                this.updatedAt,
                DateTime.TIME_24_SIMPLE,
                this.currentLocale,
            );
        },
    },
};
</script>
