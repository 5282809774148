<template>
    <div>
        <div v-if="attachments && attachments.length && canRead">
            <ul class="list-unstyled">
                <li
                    v-for="attachment in attachments"
                    :key="attachment.id"
                    :class="{ 'border-0': inline }"
                    class="d-flex mb-2 border-color-secondary border rounded"
                >
                    <div
                        v-if="!inline"
                        class="d-flex align-items-top text-primary p-2"
                    >
                        <image-file-type
                            v-if="attachment.mimeType.startsWith('image')"
                            :style="
                                isDesktop
                                    ? 'height:22px; width:22px'
                                    : 'height:16px; width:16px'
                            "
                        />
                        <pdf-file-type v-else />
                    </div>
                    <div
                        class="d-sm-flex justify-content-between ps-0 ps-sm-2 p-2"
                        style="flex-grow: 1"
                    >
                        <div
                            class="d-flex flex-column justify-content-center"
                            style="line-height: normal"
                        >
                            <div
                                class="d-flex align-items-start"
                                style="gap: 5px"
                            >
                                <a
                                    :href="
                                        attachment.uri ||
                                        route('attachments.show', attachment.id)
                                    "
                                    class="text-max-2-lines text-break"
                                    target="_blank"
                                    >{{ attachment.fileName }}
                                </a>
                                <icon
                                    v-if="!attachment.exists"
                                    v-c-tooltip="{
                                        content: trans(
                                            'general.attachment.not_found',
                                        ),
                                        popperOptions: {
                                            positionFixed: true,
                                        },
                                    }"
                                    icon="cil-warning"
                                />
                            </div>
                            <small class="text-nowrap" style="line-height: 1.5">
                                {{ trans('attachments.overview.size') }}:
                                {{
                                    $filters.prettyBytes(
                                        attachment.size,
                                        currentLocale,
                                    )
                                }}
                                &nbsp;&nbsp;&nbsp;{{
                                    $filters.dateTimeMedium(
                                        attachment.createdAt,
                                        currentLocale,
                                    )
                                }}
                            </small>
                        </div>
                        <div
                            v-if="!readonly"
                            class="actions d-flex justify-content-end align-items-center"
                        >
                            <div v-if="canRead">
                                <a
                                    v-c-tooltip="{
                                        content: trans(
                                            'general.action.download',
                                        ),
                                    }"
                                    :href="
                                        route(
                                            'attachments.download',
                                            attachment.id,
                                        )
                                    "
                                    class="btn btn-icon text-primary"
                                >
                                    <icon icon="cil-cloud-download" />
                                </a>
                            </div>
                            <div v-if="canEdit">
                                <jet-button
                                    v-c-tooltip="{
                                        content: trans('general.action.edit'),
                                    }"
                                    class="btn btn-icon btn-text text-primary"
                                    type="button"
                                    @click.prevent="editFilename(attachment)"
                                >
                                    <icon icon="cil-pencil" />
                                </jet-button>
                            </div>
                            <div v-if="canDelete">
                                <jet-button
                                    v-c-tooltip="{
                                        content: trans('general.action.delete'),
                                    }"
                                    :is-processing="
                                        isDeletingAttachment(attachment)
                                    "
                                    class="btn btn-icon btn-text"
                                    type="button"
                                    @click.prevent="
                                        deleteAttachment(attachment)
                                    "
                                >
                                    <icon icon="cil-trash" />
                                </jet-button>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <dialog-modal
            :closeable="false"
            :show="!!selectedAttachment"
            @close="closeModal"
        >
            <template #title>
                {{ trans('attachments.modal.update_file_name.header') }}
            </template>

            <div class="mt-2">
                <jet-input
                    ref="filename"
                    v-model="form.name"
                    :autofocus="true"
                    :horizontal="false"
                    :invalidFeedback="form.errors.name"
                    :label="trans('attachments.modal.update_file_name.label')"
                    :placeholder="
                        trans('attachments.modal.update_file_name.placeholder')
                    "
                    class="mt-1 block w-3/4"
                    type="text"
                    @keyup.enter.prevent="submitForm"
                >
                    <template #append>
                        <span v-if="selectedAttachment">
                            .{{ selectedAttachment.extension }}
                        </span>
                    </template>
                </jet-input>
            </div>

            <template #footer>
                <jet-button
                    :disabled="form.processing"
                    color="secondary"
                    type="button"
                    @click.prevent="closeModal"
                >
                    {{ trans('general.action.cancel') }}
                </jet-button>

                <jet-button
                    :disabled="!isFormSubmittable"
                    :is-processing="form.processing"
                    color="primary"
                    type="button"
                    @click.prevent="submitForm"
                >
                    {{ trans('general.action.save') }}
                </jet-button>
            </template>
        </dialog-modal>
    </div>
</template>
<script>
import JetButton from '@/Jetstream/Button.vue';
import JetInput from '@/Jetstream/Input.vue';
import DialogModal from '@/Jetstream/DialogModal.vue';
import {
    CListGroup,
    CListGroupItem,
} from '@coreui/vue-pro/dist/esm/components/list-group/index.js';
import ImageFileType from '../../icons/ImageFileType.vue';
import PdfFileType from '../../icons/PdfFileType.vue';
import { $http } from '@/bootstrap.js';
import { isDesktop } from '@/Utils/UseMedia';

export default {
    setup() {
        return { isDesktop };
    },
    components: {
        PdfFileType,
        ImageFileType,
        CListGroupItem,
        CListGroup,
        DialogModal,
        JetButton,
        JetInput,
    },

    emits: ['deleted', 'updated'],

    props: {
        readonly: {
            type: Boolean,
            default: false,
        },
        attachments: Array,
        inline: {
            type: Boolean,
            default: false,
        },
        canDelete: {
            type: Boolean,
            default: false,
        },
        canEdit: {
            type: Boolean,
            default: false,
        },
        canRead: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            selectedAttachment: null,
            isDeleting: [],
            form: this.$inertia.form({
                name: null,
            }),
        };
    },

    methods: {
        getFileTypeIcon(mimeType) {
            switch (mimeType) {
                case 'application/pdf':
                    return 'cil-file';
                case 'image/jpeg':
                case 'image/jpg':
                case 'image/png':
                    return;
            }
        },
        editFilename(attachment) {
            this.form = this.$inertia.form({ name: attachment.basename });
            this.selectedAttachment = attachment;
        },
        submitForm() {
            const id = this.selectedAttachment.id;

            if (this.form.processing) {
                return;
            }

            this.form.processing = true;

            $http
                .put(this.route('attachments.update', id), this.form.data(), {})
                .then((resp) => {
                    this.$emit('updated', resp.data);
                    this.closeModal();
                })
                .catch(() => {
                    this.$refs.filename.focus();
                })
                .finally(() => {
                    this.form.processing = false;
                });
        },
        deleteAttachment(attachment) {
            this.isDeleting = [...this.isDeleting, attachment.id];

            $http
                .delete(this.route('attachments.destroy', attachment.id))
                .then(() => {
                    this.$emit('deleted', attachment);
                })
                .finally(() => {
                    this.isDeleting = this.isDeleting.filter(
                        (id) => id !== attachment.id,
                    );
                });
        },
        closeModal() {
            this.selectedAttachment = null;
        },
        isDeletingAttachment(attachment) {
            return this.isDeleting.includes(attachment.id);
        },
    },
};
</script>
<style scoped>
.media {
    width: 100px;
    height: 100%;
}

:deep(.input-group-text) {
    min-width: 32px;
    width: auto;
}
</style>
