<template>
    <card ref="card">
        <template #header>
            <custom-card-header
                :href="$route('assets.stakeholders.edit', asset)"
                :show-action="hasAnyPermission('asset.stakeholder.update')"
                :title="trans('stakeholders.overview.title')"
                action-icon="cil-pencil"
            />
        </template>
        <div>
            <row>
                <column :md="columnWidthMd">
                    <dt class="text-uppercase">
                        {{ trans('asset.stakeholders.owners.label') }}
                    </dt>
                </column>
                <column>
                    <dd v-if="asset.owners?.length">
                        <stakeholder-base-information
                            v-for="owner in asset.owners"
                            :key="owner.id"
                            :collapsable="true"
                            :stakeholder="owner"
                        />
                    </dd>
                    <dd v-else>{{ trans('general.not_available') }}</dd>
                </column>
            </row>
            <row>
                <column :md="columnWidthMd">
                    <dt class="text-uppercase">
                        {{ trans('asset.stakeholders.operators.label') }}
                    </dt>
                </column>
                <column>
                    <dd v-if="asset.operators?.length">
                        <stakeholder-base-information
                            v-for="operator in asset.operators"
                            :key="operator.id"
                            :collapsable="true"
                            :stakeholder="operator"
                        />
                    </dd>
                    <dd v-else>{{ trans('general.not_available') }}</dd>
                </column>
            </row>
            <row>
                <column :md="columnWidthMd">
                    <dt class="text-uppercase">
                        {{ trans('asset.stakeholders.maintainers.label') }}
                    </dt>
                </column>
                <column>
                    <dd v-if="asset.maintainers?.length">
                        <stakeholder-base-information
                            v-for="maintainer in asset.maintainers"
                            :key="maintainer.id"
                            :collapsable="true"
                            :show-expiration-warning="
                                showExpirationWarning(maintainer)
                            "
                            :stakeholder="maintainer"
                        >
                            <service-level-agreement-info
                                v-for="(
                                    sla, index
                                ) in maintainer.serviceLevelAgreements"
                                :key="sla.id"
                                :class="{
                                    'border-bottom':
                                        maintainer.serviceLevelAgreements
                                            .length > 1 &&
                                        index <
                                            maintainer.serviceLevelAgreements
                                                .length -
                                                1,
                                }"
                                :horizontal="columnWidthMd === '4'"
                                :sla="sla"
                                :working-hours="maintainer.workingHours"
                                class="py-2"
                            />
                        </stakeholder-base-information>
                    </dd>
                    <dd v-else>{{ trans('general.not_available') }}</dd>
                </column>
            </row>
        </div>
    </card>
</template>
<script>
import CustomCardHeader from '@/Components/CustomCardHeader.vue';
import StakeholderBaseInformation from '@/Pages/Assets/Details/StakeholderBaseInformation.vue';
import ServiceLevelAgreementInfo from '@/Pages/Stakeholders/Maintainers/ServiceLevelAgreementInfo.vue';
import Card from '@/Components/Card.vue';
import { hasAnyPermission } from '@/mixins/hasAnyPermission.js';
import { trans } from '@/mixins/i18n.js';

export default {
    name: 'AssetStakeholdersCard',
    components: {
        Card,

        ServiceLevelAgreementInfo,
        StakeholderBaseInformation,
        CustomCardHeader,
    },
    data() {
        return { columnWidthMd: '4' };
    },
    computed: {
        asset() {
            return this.$page.props.asset;
        },
    },
    mounted() {
        const ro = new ResizeObserver((entries) => {
            window.requestAnimationFrame(() => {
                if (!Array.isArray(entries) || !entries.length) {
                    return;
                }

                this.onResize(entries);
            });
        });

        ro.observe(this.$refs.card.$el);
    },
    methods: {
        trans,
        hasAnyPermission,
        onResize(event) {
            let width = event[0].contentRect.width;
            this.columnWidthMd = !this.isSmallScreen ? '4' : '12';
        },
        showExpirationWarning(maintainer) {
            return maintainer.serviceLevelAgreements.some(
                (sla) => sla.expired || sla.expiresSoon || sla.missing,
            );
        },
    },
};
</script>
